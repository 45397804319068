/////////////////////////////////////////////////////////////////////
////////////////////////////// USER /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const AUTH_USER = 'auth_user';
export const REGISTER_USER = 'register_user';
export const CLEAR_REGISTER_USER = 'clear_register_user';
export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';
export const GET_USERS = 'get_users';
export const GET_USERS_BY_ID = 'get_users_by_id';
export const ADD_USER = 'add_user';
export const CLEAR_ADD_USER = 'clear_add_user';
export const UPDATE_USER = 'update_user';
export const CLEAR_UPDATE_USER = 'clear_update_user';
export const ADMIN_INSTALLATION = 'admin_installation';
/////////////////////////////////////////////////////////////////////
///////////////////////////// CLIENT ////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const AUTH_CLIENT = 'auth_client';
export const LOGIN_CLIENT = 'login_client';
export const VERIFY_PHONE = 'verify_phone';
export const CONFIRM_PHONE_NUMBER = 'confirm_phone_number';
export const LOGOUT_CLIENT = 'logout_client';
export const NAME_PIN = 'name_pin';
/////////////////////////////////////////////////////////////////////
//////////////////////////// WEBSITE ////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ADD_WEBSITE_ADDRESS = 'add_website_address';
export const CLEAR_WEBSITE_ADDRESS = 'clear_website_address';
export const ADD_WEBSITE = 'add_website';
export const CLEAR_WEBSITE = 'clear_website';
export const ADD_WEBSITE_LOGO = 'add_website_logo';
export const ADD_WEBSITE_LOGO_MOBILE = 'add_website_logo_mobile';
export const ADD_WEBSITE_LOGO_FOOTER = 'add_website_logo_footer';
export const GET_WEBSITE_ADDRESS = 'get_website_address';
export const GET_WEBSITE_ADDRESS_BY_ID = 'get_website_address_by_id';
export const GET_WEBSITE = 'get_website';
export const GET_WEBSITE_BY_ID = 'get_website_by_id';
export const UPDATE_WEBSITE = 'update_website';
export const CLEAR_UPDATE_WEBSITE = 'clear_update_website';
/////////////////////////////////////////////////////////////////////
//////////////////////////// ONGKIR /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_PROVINCE = 'get_province';
export const GET_CITY = 'get_city';
export const GET_SUBDISTRICT = 'get_subdistrict';
export const POST_COST = 'post_cost';
/////////////////////////////////////////////////////////////////////
//////////////////////////// LOADING ////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ISLOADING = 'isloading';
/////////////////////////////////////////////////////////////////////
////////////////////////////// STATUS ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const PORTOPEN = 'portopen';
export const STOCKSTATUS = 'stockstatus';
/////////////////////////////////////////////////////////////////////
//////////////////////////// CATEGORY ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_CATEGORY = 'get_category';
export const GET_CATEGORY_BY_ID = 'get_category_by_id';
export const ADD_CATEGORY = 'add_category';
export const CLEAR_CATEGORY = 'clear_category';
export const UPDATE_CATEGORY = 'update_category';
export const CLEAR_UPDATE_CATEGORY = 'clear_update_category';
/////////////////////////////////////////////////////////////////////
///////////////////////////// ECONVERTER ////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_ECONVERTER = 'get_econverter';
export const GET_ECONVERTER_BY_ID = 'get_econverter_by_id';
export const ADD_ECONVERTER = 'add_econverter';
export const UPDATE_ECONVERTER = 'update_econverter';
export const CLEAR_UPDATE_ECONVERTER = 'clear_update_econverter';
export const CLEAR_ECONVERTER = 'clear_econverter';
/////////////////////////////////////////////////////////////////////
/////////////////////////////// EUNIT ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_EUNIT = 'get_eunit';
export const GET_EUNIT_BY_ID = 'get_eunit_by_id';
export const ADD_EUNIT = 'add_eunit';
export const UPDATE_EUNIT = 'update_eunit';
export const CLEAR_UPDATE_EUNIT = 'clear_update_eunit';
export const CLEAR_EUNIT = 'clear_eunit';
/////////////////////////////////////////////////////////////////////
////////////////////////////// VENDOR ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_VENDOR = 'get_vendor';
export const GET_VENDOR_BY_ID = 'get_vendor_by_id';
export const ADD_VENDOR = 'add_vendor';
export const UPDATE_VENDOR = 'update_vendor';
export const CLEAR_UPDATE_VENDOR = 'clear_update_vendor';
export const CLEAR_VENDOR = 'clear_vendor';
/////////////////////////////////////////////////////////////////////
///////////////////////////// FORMULA ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_FORMULA = 'get_formula';
export const GET_FORMULA_BY_ID = 'get_formula_by_id';
export const ADD_FORMULA = 'add_formula';
export const UPDATE_FORMULA = 'update_formula';
export const CLEAR_UPDATE_FORMULA = 'clear_update_formula';
export const CLEAR_FORMULA = 'clear_formula';
export const UPDATE_SINGLE_FORMULA = 'update_single_formula';
export const CLEAR_SINGLE_FORMULA = 'clear_single_formula';
export const DELETE_SINGLE_FORMULA = 'delete_single_formula';
/////////////////////////////////////////////////////////////////////
////////////////////////////// GOODS ////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_GOODS = 'get_goods';
export const GET_GOODS_BY_ID = 'get_goods_by_id';
export const ADD_GOODS = 'add_goods';
export const UPDATE_GOODS = 'update_goods';
export const CLEAR_UPDATE_GOODS = 'clear_update_goods';
export const CLEAR_GOODS = 'clear_goods';
/////////////////////////////////////////////////////////////////////
///////////////////////// EMATERIALSTOCKS ///////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_EMATERIALSTOCKS = 'get_ematerialstocks';
export const GET_EMATERIALSTOCKS_BY_ID = 'get_ematerialstocks_by_id';
export const GET_EMATERIALSTOCKS_SINGLE_BY_ID = 'get_ematerialstocks_single_by_id';
export const ADD_EMATERIALSTOCKS = 'add_ematerialstocks';
export const UPDATE_EMATERIALSTOCKS = 'update_ematerialstocks';
export const CLEAR_UPDATE_EMATERIALSTOCKS = 'clear_update_ematerialstocks';
export const CLEAR_EMATERIALSTOCKS = 'clear_ematerialstocks';
export const UPDATE_SINGLE_EMATERIALSTOCKS = 'update_single_ematerialstocks';
export const CLEAR_SINGLE_EMATERIALSTOCKS = 'clear_single_ematerialstocks';
export const UPDATE_SINGLE_EMATERIALSTOCKS_SINGLE = 'update_single_ematerialstocks_single';
export const CLEAR_SINGLE_EMATERIALSTOCKS_SINGLE = 'clear_single_ematerialstocks_single';
/////////////////////////////////////////////////////////////////////
////////////////////////////// ESTOCKS //////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_ESTOCKS = 'get_estocks';
export const ADD_ESTOCKS = 'add_estocks';
export const UPDATE_ESTOCKS = 'update_estocks';
export const CLEAR_UPDATE_ESTOCKS = 'clear_update_estocks';
export const CLEAR_ESTOCKS = 'clear_estocks';
/////////////////////////////////////////////////////////////////////
//////////////////////////// PORTFOLIO //////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ADD_PORTFOLIO = 'add_portfolio';
export const CLEAR_PORTFOLIO = 'clear_portfolio';
export const GET_PORTFOLIO = 'get_portfolio';
export const GET_PORTFOLIO_BY_ID = 'get_portfolio_by_id';
export const UPDATE_PORTFOLIO = 'update_portfolio';
export const CLEAR_UPDATE_PORTFOLIO = 'clear_update_portfolio';
export const UPDATE_ADDITIONAL_PORTFOLIO = 'update_additional_portfolio';
export const CLEAR_UPDATE_ADDITIONAL_PORTFOLIO = 'clear_update_additional_portfolio';
export const UPDATE_SINGLE_ADDITIONAL_PORTFOLIO = 'update_single_additional_portfolio';
export const CLEAR_UPDATE_SINGLE_ADDITIONAL_PORTFOLIO = 'clear_update_single_additional_portfolio';
export const DELETE_SINGLE_ADDITIONAL_PORTFOLIO = 'delete_single_additional_portfolio';
/////////////////////////////////////////////////////////////////////
///////////////////////////// ECOMMERCE /////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ADD_PRODUCT = 'add_product';
export const CLEAR_PRODUCT = 'clear_product';
export const GET_PRODUCT = 'get_product';
export const GET_PRODUCTS = 'get_products';
export const GET_PRODUCT_BY_ID = 'get_product_by_id';
export const GET_PRODUCT_BY_ID_MOBILE = 'get_product_by_id_mobile';
export const UPDATE_PRODUCT = 'update_product';
export const UPDATE_ADDITIONAL_PRODUCT = 'update_additional_product';
export const UPDATE_PRICE_OPTIONAL = 'update_price_optional';
export const CLEAR_UPDATE_PRICE_OPTIONAL = 'clear_update_price_optional';
export const CLEAR_UPDATE_PRODUCT = 'clear_update_product';
export const CLEAR_UPDATE_ADDITIONAL_PRODUCT = 'clear_update_additional_product';
export const GET_PRODUCTCAT = 'get_productcat';
export const ADD_PRODUCTCAT = 'add_productcat';
export const CLEAR_PRODUCTCAT = 'clear_productcat';
export const UPDATE_PRODUCTCAT = 'update_productcat';
export const CLEAR_UPDATE_PRODUCTCAT = 'clear_update_productcat';
export const GET_PRODUCTBRAND = 'get_productbrand';
export const ADD_PRODUCTBRAND = 'add_productbrand';
export const CLEAR_PRODUCTBRAND = 'clear_productbrand';
export const UPDATE_PRODUCTBRAND = 'update_productbrand';
export const CLEAR_UPDATE_PRODUCTBRAND = 'clear_update_productbrand';
export const GET_PRODUCT_BRAND_AND_LIMIT = 'get_product_brand_and_limit';
export const ADD_EGALLERY = 'add_egallery';
export const CLEAR_EGALLERY = 'clear_egallery';
export const GET_EGALLERY = 'get_egallery';
export const GET_EGALLERY_BY_ID = 'get_egallery_by_id';
export const UPDATE_EGALLERY = 'update_egallery';
export const CLEAR_UPDATE_EGALLERY = 'clear_update_egallery';
export const ADD_ESLIDER = 'add_eslider';
export const CLEAR_ESLIDER = 'clear_eslider';
export const GET_ESLIDER = 'get_eslider';
export const GET_ESLIDER_BY_ID = 'get_eslider_by_id';
export const UPDATE_ESLIDER = 'update_eslider';
export const CLEAR_UPDATE_ESLIDER = 'clear_update_eslider';
/////////////////////////////////////////////////////////////////////
////////////////////////////// GALLERY //////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ADD_GALLERY = 'add_gallery';
export const CLEAR_GALLERY = 'clear_gallery';
export const GET_GALLERY = 'get_gallery';
export const GET_GALLERY_BY_ID = 'get_gallery_by_id';
export const UPDATE_GALLERY = 'update_gallery';
export const CLEAR_UPDATE_GALLERY = 'clear_update_gallery';
/////////////////////////////////////////////////////////////////////
////////////////////////////// SLIDER ///////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ADD_SLIDER = 'add_slider';
export const CLEAR_SLIDER = 'clear_slider';
export const GET_SLIDER = 'get_slider';
export const GET_SLIDER_BY_ID = 'get_slider_by_id';
export const UPDATE_SLIDER = 'update_slider';
export const CLEAR_UPDATE_SLIDER = 'clear_update_slider';
/////////////////////////////////////////////////////////////////////
//////////////////////////// APPLICATION ////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ADD_APPLICATION = 'add_application';
export const CLEAR_APPLICATION = 'clear_application';
export const GET_APPLICATION = 'get_application';
export const GET_APPLICATION_BY_ID = 'get_application_by_id';
export const ADD_APPLICATION_ICON = 'add_application_icon';
export const CLEAR_APPLICATION_ICON = 'clear_application_icon';
export const ADD_APPLICATION_IMAGES = 'add_application_images';
export const CLEAR_APPLICATION_IMAGES = 'clear_application_images';
export const ADD_APPLICATION_APK = 'add_application_apk';
export const CLEAR_APPLICATION_APK = 'clear_application_apk';
export const UPDATE_APPLICATION = 'update_application';
export const CLEAR_UPDATE_APPLICATION = 'clear_update_application';
export const UPDATE_APPLICATION_IMAGES = 'update_application_images';
export const CLEAR_UPDATE_APPLICATION_IMAGES = 'clear_update_application_images';
export const PUBLISH_APPLICATION = 'publish_application';
export const CLEAR_PUBLISH_APPLICATION = 'clear_publish_application';
export const UNPUBLISH_APPLICATION = 'unpublish_application';
export const CLEAR_UNPUBLISH_APPLICATION = 'clear_unpublish_application';
export const DOWNLOAD_APPLICATION = 'download_application';