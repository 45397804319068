import {
    ADD_ESLIDER,
    CLEAR_ESLIDER,
    GET_ESLIDER,
    GET_ESLIDER_BY_ID,
    UPDATE_ESLIDER,
    CLEAR_UPDATE_ESLIDER
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case ADD_ESLIDER:
            return {
                ...state,
                addESlider: action.payload
            }
        case CLEAR_ESLIDER:
            return {
                ...state,
                addESlider: action.payload
            }
        case GET_ESLIDER:
            return {
                ...state,
                getESlider: action.payload
            }
        case GET_ESLIDER_BY_ID:
            return {
                ...state,
                getESliderByid: action.payload
            }
        case UPDATE_ESLIDER:
            return {
                ...state,
                updateESlider: action.payload
            }
        case CLEAR_UPDATE_ESLIDER:
            return {
                ...state,
                updateESlider: action.payload
            }
        default:
            return state;
    }
}