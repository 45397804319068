import React from 'react';

import { NavLink } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faSearch, faEdit, faTimesCircle, faLockOpen, faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faSearch, faEdit, faTimesCircle, faLockOpen, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake, faImages)


const DashboardPanel = (props) => {
    const templateShow = (data, index) => {
        if (data.dashboard) {
            return (
                <div className="col-md-4 col-xs-12 dashboardlink" key={index}>
                    <NavLink
                        to={data.link}
                        activeClassName="active"
                        exact
                    >
                        <div className="bgpattern card">
                            <div className="cardBody">
                                <div className="ministate">
                                    <h6 className="ministatetitle">{data.name}</h6>
                                    <FontAwesomeIcon
                                        icon={data.faicons}
                                        className="icon agraicon"
                                    />
                                </div>
                                <h5 className="ministatesubtitle">
                                    {
                                        data.name === "Gallery" ?
                                            props.allgallery && props.allgallery.length > 0 ? props.allgallery.length : "0"
                                            : data.name === "Slider" ?
                                                props.allslider && props.allslider.length > 0 ? props.allslider.length : "0"
                                                : data.name === "EGallery" ?
                                                    props.allegallery && props.allegallery.length > 0 ? props.allegallery.length : "0"
                                                    : data.name === "ESlider" ?
                                                        props.alleslider && props.alleslider.length > 0 ? props.alleslider.length : "0"
                                                        : data.name === "Servers" ?
                                                            props.allservers && props.allservers.length > 0 ? props.allservers.length : "0"
                                                            : null
                                    }
                                    {
                                        data.name === "Portfolio" ?
                                            <div>
                                                {
                                                    props.allcategory && props.allcategory.length > 0 ? <h6>category : {props.allcategory.length}</h6> : <h6>category : 0</h6>
                                                }
                                                {
                                                    props.allportfolio && props.allportfolio.length > 0 ? <h6>portfolio : {props.allportfolio.length}</h6> : <h6>portfolio : 0</h6>
                                                }
                                            </div>
                                            : null
                                    }
                                    {
                                        data.name === "Product" ?
                                            <div>
                                                {
                                                    props.allproduct && props.allproduct.length > 0 ? <h6>category : {props.allproduct.length}</h6> : <h6>category : 0</h6>
                                                }
                                                {
                                                    props.allproduct && props.allproduct.length > 0 ? <h6>products : {props.allproduct.length}</h6> : <h6>products : 0</h6>
                                                }
                                            </div>
                                            : null
                                    }
                                    {
                                        data.name === "Stock" ?
                                            <div>
                                                {
                                                    props.allvendors && props.allvendors.length > 0 ? <h6>vendor : {props.allvendors.length}</h6> : <h6>vendor : 0</h6>
                                                }
                                                {/* {
                                                    props.allematerialstocks && props.allematerialstocks.length > 0 ? <h6>material stocks : {props.allematerialstocks.length}</h6> : <h6>material stocks : 0</h6>
                                                } */}
                                                {
                                                    props.allgoods && props.allgoods.length > 0 ? <h6>goods : {props.allematerialstocks.length}</h6> : <h6>goods : 0</h6>
                                                }
                                            </div>
                                            : null
                                    }
                                </h5>
                            </div>
                        </div>
                    </NavLink>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div id="dashboardpanelcss" className="col-md-12 p0">
            {
                props.loadingtable ?
                    <div className="lds-ripple"><div></div><div></div></div>
                    :

                    <div>
                        <div className="col-md-12 col-xs-12">
                            <h4
                                className="pagetitledashboard"
                            >
                                Media Area
                                </h4>
                        </div>

                        {
                            props.mediapath ?
                                props.mediapath.map((data, index) => (
                                    templateShow(data, index)
                                ))
                                : null
                        }

                    </div>
            }
            {
                props.loadingtable ?
                    <div className="lds-ripple"><div></div><div></div></div>
                    :
                    <div>
                        <div className="col-md-12 col-xs-12">
                            <h4
                                className="pagetitledashboard"
                            >
                                Ecommerce Area
                                </h4>
                        </div>
                        {
                            props.productpath ?
                                props.productpath.map((data, index) => (
                                    templateShow(data, index)
                                ))
                                : null
                        }
                    </div>
            }
        </div >
    );
};

export default DashboardPanel;