import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { propertylist } from './link';
import { scroller } from 'react-scroll';

const PropertySideDrawerSection = (props) => {
    const { clientprops, allwebsite } = useSelector(state => ({
        clientprops: state.client,
        allwebsite: state.website.getWebsite && state.website.getWebsite.websites
    }));
    const scrollToElement = (element) => {
        scroller.scrollTo(element, {
            duration: 1000,
            delay: 100,
            smooth: true
        });
        props.click();
    }

    const userLink = (item, i) => (
        <li
            key={i}
        >
            <button
                onClick={() => scrollToElement(item.linkTo)}
            >
                <div className="itemContent">
                    <div className="itemIcon">
                        {item.faicons}
                    </div>
                    <div className="itemInner">
                        <span className="title">
                            {item.name}
                        </span>
                    </div>
                </div>
            </button>
        </li>
    )

    const userLinkTo = (item, i) => (
        <li key={i}>
            <NavLink
                to={item.linkTo}
                activeClassName="active"
                exact
            >
                <div className="itemContent">
                    {/* <div className="itemIcon">
                        {item.faicons}
                    </div> */}
                    <div className="itemInner">
                        <span className="title">
                            {item.name}
                        </span>
                    </div>
                </div>
            </NavLink>
        </li>
    )

    const showLinks = (type) => {
        let list = [];
        type.forEach((item) => {
            if (item.public === true) {
                list.push(item)
            }
        })

        return list.map((item, i) => {
            return userLinkTo(item, i)
        })
    }

    const showLinkTo = (type) => {
        let linkto = [];
        if (clientprops && clientprops.clientData) {
            type.forEach((item) => {
                if (!clientprops.clientData.isAuth) {
                    if (item.public === true) {
                        linkto.push(item)
                    }
                } else {
                    if (item.public === false) {
                        linkto.push(item)
                    }
                }
            })
        }

        return linkto.map((item, i) => {
            return userLinkTo(item, i)
        })
    }

    const className = `sideDrawer ${props.show ? 'open' : ''}`;
    return (
        <nav id="frontendsidedrawer" className={className}>
            <div className="sideDrawerLogo">
                <img
                    className="logo_img"
                    src={
                        allwebsite && allwebsite[0].logofooter && allwebsite[0].logofooter.length > 0 ?
                            allwebsite && allwebsite[0].logofooter[0].url
                            : window.location.origin + "/assets/images/lumisoft_side_white.png"
                    }
                    alt=""
                    width="160"
                />
            </div>

            <ul>
                {showLinks(propertylist)}
                {/* {showLinkTo(pagelinkto)} */}
            </ul>
        </nav >
    );
};


export default PropertySideDrawerSection;