import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminLayout from '../layout/adminpage';
import PageHeader from '../../../widget/pageheader';
import DashboardPanel from './panel';
import AdminPanel from './adminpanel';
import path from '../../allroutes';

import { useWindowSize } from '../../../widget/windowsize';

////////////////////////////////////////////////////////////////////////////////ADMIN
import { getAdminUser } from '../../../../store/actions/user_action';
import { getApplication } from '../../../../store/actions/application_action';
////////////////////////////////////////////////////////////////////////////////ADMIN

////////////////////////////////////////////////////////////////////////////////WEBSITE

/////////////////////////////////////////////////////////////////PORTFOLIO
import { getCategories } from '../../../../store/actions/category_action';
import { getPortfolio } from '../../../../store/actions/portfolio_action';
/////////////////////////////////////////////////////////////////PORTFOLIO

import { getGallery } from '../../../../store/actions/gallery_action';
import { getSlider } from '../../../../store/actions/slider_action';

////////////////////////////////////////////////////////////////////////////////WEBSITE

////////////////////////////////////////////////////////////////////////////////ECOMMERCE

import { getEGallery } from '../../../../store/actions/egallery_action';
import { getESlider } from '../../../../store/actions/eslider_action';

/////////////////////////////////////////////////////////////////PRODUCT
import { getProduct } from '../../../../store/actions/product_action';
/////////////////////////////////////////////////////////////////PRODUCT

/////////////////////////////////////////////////////////////////STOCK
import { getVendors } from '../../../../store/actions/vendor_action';
import { getEmaterialstocks } from '../../../../store/actions/ematerialstocks_action';
import { getGoods } from '../../../../store/actions/goods_action';
/////////////////////////////////////////////////////////////////STOCK

////////////////////////////////////////////////////////////////////////////////ECOMMERCE


import { faHome } from '@fortawesome/free-solid-svg-icons';

const DashboardScreen = (props) => {
    const {
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        userprops,
        getapplication,
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        /////////////////////////////////////////////////////////////////PORTFOLIO
        getcategory,
        getportfolio,
        /////////////////////////////////////////////////////////////////PORTFOLIO
        getgallery,
        getslider,
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
        getegallery,
        geteslider,
        getproduct,
        getvendors,
        getematerialstocks,
        getgoods
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
    } = useSelector(state => ({
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        userprops: state.user,
        getapplication: state.application,
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        /////////////////////////////////////////////////////////////////PORTFOLIO
        getcategory: state.category,
        getportfolio: state.portfolio,
        /////////////////////////////////////////////////////////////////PORTFOLIO
        getgallery: state.gallery,
        getslider: state.slider,
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
        getegallery: state.egallery,
        geteslider: state.eslider,
        getproduct: state.product,
        getvendors: state.vendor,
        getematerialstocks: state.ematerialstocks,
        getgoods: state.goods
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
    }));
    const size = useWindowSize();
    const isMobile = size.width <= 767.98;
    const dispatch = useDispatch();
    const [loadingtable, loadingtableHandler] = useState(false);
    const [pagename] = useState('LUMISOFT ADMIN');
    const [dashboardrefresh] = useState(true);
    const [breadcrumb] = useState([
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            public: true,
            faicons: faHome
        },
        {
            name: 'My Dashboard',
            linkTo: '',
            public: true
        }
    ]);
    // const [dontblur, dontblurHandler] = useState(false);
    const [mediaroutepath] = useState(path.mediapath);
    const [productroutepath] = useState(path.productpath);
    const [adminroutepath] = useState(path.adminpath);

    // const [showuser] = useState({
    //     show: true,
    //     name: 'Users',
    //     link: '/admin/master/user',
    //     icon: faUsers
    // });
    // const [showapplication] = useState({
    //     show: true,
    //     name: 'Applications',
    //     link: '/admin/master/application',
    //     icon: faMobile
    // });
    // const [showgallery] = useState({
    //     show: true,
    //     name: 'Gallery',
    //     link: '/admin/gallery',
    //     icon: faImages
    // });
    // const [showportfolio] = useState({
    //     show: true,
    //     name: 'Portfolio',
    //     link: '/admin/portfolio',
    //     icon: faImages
    // });
    // const [showproduct] = useState({
    //     show: true,
    //     name: 'Product',
    //     link: '/admin/product',
    //     icon: faBoxes
    // });

    ////////////////////////////////////////////////////////////////////////////////ADMIN
    const [alluser, alluserHandler] = useState([]);
    const [allapplication, allapplicationHandler] = useState([]);
    ////////////////////////////////////////////////////////////////////////////////ADMIN
    ////////////////////////////////////////////////////////////////////////////////WEBSITE
    /////////////////////////////////////////////////////////////////PORTFOLIO
    const [allcategory, allcategoryHandler] = useState([]);
    const [allportfolio, allportfolioHandler] = useState([]);
    /////////////////////////////////////////////////////////////////PORTFOLIO
    const [allgallery, allgalleryHandler] = useState([]);
    const [allslider, allsliderHandler] = useState([]);
    ////////////////////////////////////////////////////////////////////////////////WEBSITE
    ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
    const [allegallery, allegalleryHandler] = useState([]);
    const [alleslider, allesliderHandler] = useState([]);
    const [allproduct, allproductHandler] = useState([]);
    const [allvendors, allvendorsHandler] = useState([]);
    const [allematerialstocks, allematerialstocksHandler] = useState([]);
    const [allgoods, allgoodsHandler] = useState([]);
    ////////////////////////////////////////////////////////////////////////////////ECOMMERCE

    useEffect(() => {
        loadingtableHandler(true);
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        dispatch(getAdminUser());
        dispatch(getApplication());
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        /////////////////////////////////////////////////////////////////PORTFOLIO
        dispatch(getCategories());
        dispatch(getPortfolio());
        /////////////////////////////////////////////////////////////////PORTFOLIO
        dispatch(getGallery());
        dispatch(getSlider());
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
        dispatch(getEGallery());
        dispatch(getESlider());
        dispatch(getProduct());
        // dispatch(getProduct(100, 'desc', "createdAt"));
        dispatch(getVendors());
        dispatch(getEmaterialstocks());
        dispatch(getGoods());
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
    }, [dispatch])
    useEffect(() => {
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        if (userprops && userprops.getUser && userprops.getUser.success) {
            let totaluser = userprops.getUser.users.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            alluserHandler(totaluser);
        }
        if (getapplication && getapplication.getApplication && getapplication.getApplication.success) {
            let totalapplication = getapplication.getApplication.applications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allgalleryHandler(totalapplication);
        }
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        /////////////////////////////////////////////////////////////////PORTFOLIO
        if (getcategory && getcategory.getCategory && getcategory.getCategory.success) {
            let totalcategory = getcategory.getCategory.categories.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allcategoryHandler(totalcategory);
        }
        if (getportfolio && getportfolio.getPortfolio && getportfolio.getPortfolio.success) {
            let totalportfolio = getportfolio.getPortfolio.portfolios.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allportfolioHandler(totalportfolio);
        }
        /////////////////////////////////////////////////////////////////PORTFOLIO
        if (getgallery && getgallery.getGallery && getgallery.getGallery.success) {
            let totalgallery = getgallery.getGallery.galleries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allgalleryHandler(totalgallery);
        }
        if (getslider && getslider.getSlider && getslider.getSlider.success) {
            let totalslider = getslider.getSlider.sliders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allsliderHandler(totalslider);
        }
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
        if (getegallery && getegallery.getEGallery && getegallery.getEGallery.success) {
            let totalegallery = getegallery.getEGallery.galleries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allegalleryHandler(totalegallery);
        }
        if (geteslider && geteslider.getESlider && geteslider.getESlider.success) {
            let totaleslider = geteslider.getESlider.sliders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allesliderHandler(totaleslider);
        }
        if (getproduct && getproduct.getProduct && getproduct.getProduct.success) {
            let totalproduct = getproduct.getProduct.product.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allproductHandler(totalproduct);
        }
        if (getvendors && getvendors.getVendor && getvendors.getVendor.success) {
            let totalvendors = getvendors.getVendor.vendors.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allvendorsHandler(totalvendors);
        }
        if (getematerialstocks && getematerialstocks.getEmaterialstocks && getematerialstocks.getEmaterialstocks.success) {
            let totalematerialstocks = getematerialstocks.getEmaterialstocks.ematerialstocks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allematerialstocksHandler(totalematerialstocks);
        }
        if (getgoods && getgoods.getGoods && getgoods.getGoods.success) {
            let totalgoods = getgoods.getGoods.goods.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allgoodsHandler(totalgoods);
        }
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
        loadingtableHandler(false);
    }, [
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        userprops,
        getapplication,
        ////////////////////////////////////////////////////////////////////////////////ADMIN
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        /////////////////////////////////////////////////////////////////PORTFOLIO
        getcategory,
        getportfolio,
        /////////////////////////////////////////////////////////////////PORTFOLIO
        getgallery,
        getslider,
        ////////////////////////////////////////////////////////////////////////////////WEBSITE
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
        getproduct,
        getegallery,
        geteslider,
        getvendors,
        getematerialstocks,
        getgoods
        ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
    ])

    const onRefreshHandler = async () => {
        try {
            loadingtableHandler(true);
            ////////////////////////////////////////////////////////////////////////////////ADMIN
            let alluser = await dispatch(getAdminUser());
            let totaluser = await alluser.payload.users.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let allapplication = await dispatch(getApplication());
            let totalapplication = await allapplication.payload.applications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            ////////////////////////////////////////////////////////////////////////////////ADMIN
            ////////////////////////////////////////////////////////////////////////////////WEBSITE
            /////////////////////////////////////////////////////////////////PORTFOLIO
            let allcategory = await dispatch(getCategories());
            let totalcategory = await allcategory.payload.categories.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let allportfolio = await dispatch(getPortfolio());
            let totalportfolio = await allportfolio.payload.portfolios.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            /////////////////////////////////////////////////////////////////PORTFOLIO
            let allgallery = await dispatch(getGallery());
            let totalgallery = await allgallery.payload.galleries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let allslider = await dispatch(getSlider());
            let totalslider = await allslider.payload.sliders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            ////////////////////////////////////////////////////////////////////////////////WEBSITE
            ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
            let allegallery = await dispatch(getEGallery());
            let totalegallery = await allegallery.payload.galleries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let alleslider = await dispatch(getESlider());
            let totaleslider = await alleslider.payload.sliders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let allproduct = await dispatch(getProduct(100, 'desc', "createdAt"));
            let totalproduct = await allproduct.payload.product.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let allvendors = await dispatch(getVendors());
            let totalvendors = await allvendors.payload.vendors.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let allematerialstocks = await dispatch(getEmaterialstocks());
            let totalematerialstocks = await allematerialstocks.payload.ematerialstocks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            let allgoods = await dispatch(getGoods());
            let totalgoods = await allgoods.payload.goods.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            ////////////////////////////////////////////////////////////////////////////////ECOMMERCE

            ////////////////////////////////////////////////////////////////////////////////ADMIN
            alluserHandler(totaluser);
            allapplicationHandler(totalapplication);
            ////////////////////////////////////////////////////////////////////////////////ADMIN
            ////////////////////////////////////////////////////////////////////////////////WEBSITE
            /////////////////////////////////////////////////////////////////PORTFOLIO
            allcategoryHandler(totalcategory);
            allportfolioHandler(totalportfolio);
            /////////////////////////////////////////////////////////////////PORTFOLIO
            allgalleryHandler(totalgallery);
            allsliderHandler(totalslider);
            ////////////////////////////////////////////////////////////////////////////////WEBSITE
            ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
            allegalleryHandler(totalegallery);
            allesliderHandler(totaleslider);
            allproductHandler(totalproduct);
            allvendorsHandler(totalvendors);
            allematerialstocksHandler(totalematerialstocks);
            allgoodsHandler(totalgoods);
            ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
            loadingtableHandler(false);
        } catch (error) {

        }
    }

    const clickHandler = (link) => {
        props.history.push(link);
    }

    return (
        <AdminLayout>
            <div className="masterDashboard">
                <div className="masterDashboardContainer">
                    <div className="containerfluid tableloading">
                        <div className="pagetitlebox">
                            <PageHeader
                                {...props}
                                dashboardrefresh={dashboardrefresh}
                                pagename={pagename}
                                breadcrumb={breadcrumb}
                                // dontblur={dontblur}
                                onRefreshHandler={onRefreshHandler}
                                clickHandler={clickHandler}
                            />
                        </div>
                        <div className="row">
                            <DashboardPanel
                                isMobile={isMobile}
                                loadingtable={loadingtable}
                                mediapath={mediaroutepath}
                                productpath={productroutepath}
                                ////////////////////////////////////////////////////////////////////////////////ADMIN
                                alluser={alluser}
                                userprops={userprops}
                                allapplication={allapplication}
                                ////////////////////////////////////////////////////////////////////////////////ADMIN
                                ////////////////////////////////////////////////////////////////////////////////WEBSITE
                                /////////////////////////////////////////////////////////////////PORTFOLIO
                                allcategory={allcategory}
                                allportfolio={allportfolio}
                                /////////////////////////////////////////////////////////////////PORTFOLIO
                                allgallery={allgallery}
                                allslider={allslider}
                                ////////////////////////////////////////////////////////////////////////////////WEBSITE
                                ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
                                allegallery={allegallery}
                                alleslider={alleslider}
                                allproduct={allproduct}
                                allvendors={allvendors}
                                allematerialstocks={allematerialstocks}
                                allgoods={allgoods}
                                ////////////////////////////////////////////////////////////////////////////////ECOMMERCE
                            />
                        </div>
                        <div className="row">
                            {
                                userprops.userData && userprops.userData.masteradmin > 0 ?
                                    <AdminPanel
                                        userprops={userprops}
                                        isMobile={isMobile}
                                        loadingtable={loadingtable}
                                        showadminpath={adminroutepath}
                                        alluser={alluser}
                                        allapplication={allapplication}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default DashboardScreen;