import React, {
    useState,
    useEffect
} from 'react';
import {
    // useSelector,
    useDispatch
} from 'react-redux';

import FormField from '../../../utils/form/formfield';
// import FileUpload from '../../../utils/form/fileupload';
import { update, generateData, isFormValid } from '../../../utils/form/formactions';
import {
    getVendors,
    addVendor,
    clearVendor,
    updateVendor,
    clearUpdateVendor
} from '../../../../../store/actions/vendor_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const VendorTableScreen = (props) => {
    // const { getalldata } = useSelector(state => ({
    //     getalldata: state.user
    // }))
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');
    const [formdata, formdataHandler] = useState({
        name: {
            element: 'input',
            title: 'Name',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        code: {
            element: 'input',
            title: 'Vendor Code',
            value: '',
            config: {
                name: 'codeInput',
                type: 'text',
                placeholder: 'Enter vendor code'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        address: {
            element: 'input',
            title: 'Address',
            value: '',
            config: {
                name: 'addressInput',
                type: 'text',
                placeholder: 'Enter address'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        details: {
            element: 'input',
            title: 'Address details',
            value: '',
            config: {
                name: 'detailsInput',
                type: 'text',
                placeholder: 'Enter address details'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        email: {
            element: 'input',
            title: 'Email',
            value: '',
            config: {
                name: 'emailInput',
                type: 'text',
                placeholder: 'Enter email'
            },
            validation: {
                required: false,
                email: true
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        phone: {
            element: 'input',
            title: 'Phone',
            value: '',
            config: {
                name: 'phoneInput',
                type: 'text',
                placeholder: 'Enter phone'
            },
            validation: {
                required: true,
                phone: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
    });
    // const [imageToUpload, imageToUploadHandler] = useState({
    //     images: {
    //         value: []
    //     }
    // });
    // const [imageToDelete, imageToDeleteHandler] = useState({
    //     images: {
    //         value: []
    //     }
    // });
    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'vendor');
        formErrorHandler(false)
        formdataHandler(newFormdata)
    }

    useEffect(() => {
        if (editformdatastatus && dataselected) {
            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            for (let key in formdata) {
                const newElement = {
                    ...temporaryFormdata[key]
                }
                if(newElement.element === 'select'){
                    newElement.selectedValue = dataselected[key].name;
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                } 
                if(newElement.element === 'input') {
                    newElement.value = dataselected[key];
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
            }
            formdataHandler(temporaryFormdata);
        }
    }, [editformdatastatus, dataselected])
    // const previewFile = (files, multifiles, uploadname) => {
    //     formErrorHandler(false)
    //     const arrOfPromises = files.map((file, i) => {
    //         return new Promise((resolve, reject) => {
    //             const reader = new FileReader();
    //             reader.onload = (e) => {
    //                 file.id = i;
    //                 file.url = e.target.result;
    //                 resolve(file);
    //             }
    //             reader.readAsDataURL(file);
    //         });

    //     });

    //     Promise.all(arrOfPromises).then(imagesToAdd => {
    //         let imagesToAddSort = imagesToAdd.sort((a, b) => {
    //             if (b.name > a.name)
    //                 return -1;
    //             if (b.name < a.name)
    //                 return 1;
    //             return 0;
    //         });

    //         if (editformdatastatus) {
    //             const newimageToUpload = { ...imageToUpload }
    //             const elementImageToUpload = {
    //                 ...newimageToUpload[uploadname]
    //             }
    //             for (let zxc = 0; zxc < imagesToAddSort.length; zxc++) {
    //                 elementImageToUpload.value.push(imagesToAddSort[zxc])
    //             }
    //             newimageToUpload[uploadname] = elementImageToUpload
    //             imageToUploadHandler(newimageToUpload);
    //         } else {
    //             var newFormdata = formdata;
    //             const temporaryFormdata = {
    //                 ...newFormdata
    //             }

    //             const newElement = {
    //                 ...temporaryFormdata[uploadname]
    //             }
    //             if (multifiles) {
    //                 for (let zxc = 0; zxc < imagesToAddSort.length; zxc++) {
    //                     newElement.value.push(imagesToAddSort[zxc])
    //                 }
    //             } else {
    //                 if (imagesToAddSort.length < 2) {
    //                     newElement.value = imagesToAddSort
    //                 } else {
    //                     formErrorHandler(true)
    //                     errorMessageHandler("Please upload one image only")
    //                 }
    //             }
    //             temporaryFormdata[uploadname] = newElement;
    //             formdataHandler(temporaryFormdata);
    //         }

    //     }).catch(err => console.log('err loading images', err));
    // }
    // const onRemove = (item, uploadname) => {
    //     var newFormdata = formdata;
    //     const temporaryFormdata = {
    //         ...newFormdata
    //     }

    //     const newElement = {
    //         ...temporaryFormdata[uploadname]
    //     }
    //     if (editformdatastatus) {
    //         var newFormdata2 = imageToDelete;
    //         const temporaryFormdata2 = {
    //             ...newFormdata2
    //         }

    //         const newElement2 = {
    //             ...temporaryFormdata2[uploadname]
    //         }
    //         const updatedImages = newElement.value.filter(i => i.url !== item.url);
    //         newElement2.value.push(item.public_id);
    //         newElement.value = updatedImages;
    //         temporaryFormdata[uploadname] = newElement;
    //         temporaryFormdata2[uploadname] = newElement2;
    //         formdataHandler(temporaryFormdata);
    //         imageToDeleteHandler(temporaryFormdata2);
    //     } else {
    //         const updatedImages = newElement.value.filter(i => i.id !== item.id);
    //         newElement.value = updatedImages;
    //         temporaryFormdata[uploadname] = newElement;
    //         formdataHandler(temporaryFormdata);
    //     }
    // }

    // const showSoonToUpdateImages = (uploadname) => {
    //     var newFormdata = formdata;
    //     const temporaryFormdata = {
    //         ...newFormdata
    //     }

    //     const newElement = {
    //         ...temporaryFormdata[uploadname]
    //     }

    //     if (newElement.value && newElement.value.length > 0) {
    //         return (
    //             <div className="showThumbnail">
    //                 {
    //                     newElement.value.map((item, i) => (
    //                         <div
    //                             key={i}
    //                             className="dropzoneImageWrapper"
    //                             onClick={() => onRemove(item, uploadname)}
    //                         >
    //                             <img src={item.url} alt="" />

    //                             <div className="dropzoneImageInner">
    //                                 {
    //                                     item.name ? <span>Name: {item.name}</span> : null
    //                                 }
    //                                 {
    //                                     item.size ? <span>Size: {item.size / 1000} Kb</span> : null
    //                                 }
    //                                 {
    //                                     item.type ? <span>Type: {item.type}</span> : null
    //                                 }
    //                             </div>


    //                         </div>
    //                     ))
    //                 }
    //             </div>
    //         )
    //     }
    // }

    // const showimagesWillbeRemove = (item, uploadname) => {
    //     var newFormdata = imageToUpload;
    //     const temporaryFormdata = {
    //         ...newFormdata
    //     }

    //     const newElement = {
    //         ...temporaryFormdata[uploadname]
    //     }

    //     const updatedImages = newElement.value.filter(i => i.id !== item.id);
    //     newElement.value = updatedImages;
    //     temporaryFormdata[uploadname] = newElement;
    //     imageToUploadHandler(temporaryFormdata);
    // }

    // const showimagesWillbeUpdate = (uploadname) => {
    //     var newFormdata = imageToUpload;
    //     const temporaryFormdata = {
    //         ...newFormdata
    //     }

    //     const newElement = {
    //         ...temporaryFormdata[uploadname]
    //     }
    //     if (newElement.value && newElement.value.length > 0) {
    //         return (
    //             <div className="showThumbnail">
    //                 {
    //                     newElement.value.map((item, i) => (
    //                         <div
    //                             key={i}
    //                             className="dropzoneImageWrapper"
    //                             onClick={() => showimagesWillbeRemove(item, uploadname)}
    //                         >
    //                             <img src={item.url} alt="" />

    //                             <div className="dropzoneImageInner">
    //                                 {
    //                                     item.name ? <span>Name: {item.name}</span> : null
    //                                 }
    //                                 {
    //                                     item.size ? <span>Size: {item.size / 1000} Kb</span> : null
    //                                 }
    //                                 {
    //                                     item.type ? <span>Type: {item.type}</span> : null
    //                                 }
    //                             </div>


    //                         </div>
    //                     ))
    //                 }
    //             </div>
    //         )

    //     }
    // }

    const goBackToTable = () => {
        props.history.push('/admin/vendor');
    }

    const fetchingnewData = async (id) => {
        let selectedproduct = await dispatch(getVendors());
        let final = selectedproduct.payload.vendors.find(site => site._id === id);
        props.history.push({
            pathname: `/admin/vendor/editdata/${id}`,
            state: {
                dataselected: final,
                editformdata: true
            }
        })
    }

    const submitData = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formdata, 'vendor');
        let formIsValid = isFormValid(formdata, 'vendor');

        if (formIsValid) {
            dispatch(addVendor(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearVendor());
                    formSuccessHandler(true);
                    props.history.push('/admin/vendor');
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'vendoredit');
        let formIsValid2 = isFormValid(formdata, 'vendoredit');
        let dataselectedid = dataselected._id;
        let datachange = 0;

        for (let key1 in dataToSubmit2) {
            // if (key1 === "category") {
            //     if (dataselected[key1]._id.toString() !== dataToSubmit2[key1].toString()) {
            //         datachange = datachange + 1;
            //     }
            // } else if (key1 === "images") {
            //     if (imageToUpload.images.value && imageToUpload.images.value.length > 0) {

            //     } else {
            //         if (dataselected[key1] !== dataToSubmit2[key1]) {
            //             datachange = datachange + 1;
            //         }
            //     }
            // } else {
            //     if (dataselected[key1] !== dataToSubmit2[key1]) {
            //         datachange = datachange + 1;
            //     }
            // }

            /////////////////////////////////////////////////////////////
            // if (key1 === "category") {
            //     if (dataselected[key1]._id.toString() !== dataToSubmit2[key1].toString()) {
            //         datachange = datachange + 1;
            //     }
            // } else {
            //     if (dataselected[key1] !== dataToSubmit2[key1]) {
            //         datachange = datachange + 1;
            //     }
            // }
            //////////////////////////////////////////////////////////////
            if (dataselected[key1] !== dataToSubmit2[key1]) {
                datachange = datachange + 1;
            }
        }
        if (datachange > 0) {
            const totaldataToSubmit = {
                ...dataToSubmit2
            }
            if (formIsValid2) {
                // totaldataToSubmit.imagesToRemove = imageToDelete.images.value;
                // totaldataToSubmit.imagesToAdd = imageToUpload.images.value;
                dispatch(updateVendor(totaldataToSubmit, dataselectedid)).then(response => {
                    if (response.payload.success) {
                        dispatch(clearUpdateVendor());
                        formSuccessHandler(true);
                        setTimeout(() => {
                            fetchingnewData(dataselectedid);
                        }, 1000);
                        // props.history.push('/admin/vendor');
                    } else {
                        formErrorHandler(true);
                        errorMessageHandler(response.payload.message);
                    }
                })
            } else {
                formErrorHandler(true);
                errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler('CHANGE DATA BEFORE SUBMIT!');
        }
    }

    return (
        <div className="cardBody formstyle">
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.name.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'name'}
                        formdata={formdata.name}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.code.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faBarcode}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'code'}
                        formdata={formdata.code}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.address.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faMapMarkedAlt}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'address'}
                        formdata={formdata.address}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.details.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faMapSigns}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'details'}
                        formdata={formdata.details}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.email.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'email'}
                        formdata={formdata.email}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.phone.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faPhone}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'phone'}
                        formdata={formdata.phone}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            {/* <div className="row pb25">
                <label className="col-md-12 col-xs-12 colFormLabel">{formdata.images.title}</label>
                <div className="col-md-12 col-xs-12">
                    <FileUpload
                        id={'fileupload'}
                        reset={formSuccess}
                        myclass={'form-control'}
                        onFilesAlreadyAdded={previewFile}
                        multifiles={false}
                        uploadname={'images'}
                    />
                    {
                        showSoonToUpdateImages('images')
                    }
                    {
                        editformdatastatus ? showimagesWillbeUpdate('images') : null
                    }
                </div>
            </div> */}
            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                            </div>
                            : null
                }

                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    <div className="doubleButton">
                        <button
                            onClick={editformdatastatus ? (event) => submitEditData(event) : (event) => submitData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorTableScreen;