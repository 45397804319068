import React from 'react';
import moment from 'moment';
import { convertToRupiah } from '../../../utils/form/formactions';

const ThisConverterTable = (props) => {
    return (
        <tr key={props.index} className="image" onClick={() => props.editData(props.item)}>
            <td>
                {props.item.vendor && props.item.vendor.name}
            </td>
            <td>
                {moment(props.item.createdAt).format("DD MMM YYYY")}
            </td>
            <td>
                {props.item.quantityconverted} / {props.item.converter.subunit.shortname}
            </td>
            <td>
                Rp. {convertToRupiah(props.item.modal)},-
            </td>
            <td>
                Rp. {props.item.modaleach},-
            </td>
        </tr>
    );
};

export default ThisConverterTable;