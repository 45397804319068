import React, { useState } from 'react';
import {
    useSelector
} from 'react-redux';
import { useWindowSize } from '../widget/windowsize';
import { Element } from 'react-scroll';
import { FaWhatsapp } from "react-icons/fa";

import BackDrop from '../views/backdrop';

import Header from '../views/header';
import SideDrawer from '../views/header/sidedrawer';
import Home from '../views/home';
import Footer from '../views/footer';

const HeaderVisibleLayout = (props) => {
    const { loadingprops, allwebsite } = useSelector(state => ({
        loadingprops: state.loading.isLoading,
        allwebsite: state.website.getWebsite && state.website.getWebsite.websites
    }));

    const size = useWindowSize();
    const [sideDrawerOpen, sideDrawerOpenHandler] = useState(false);

    const drawerToggleClickHandler = () => {
        sideDrawerOpenHandler(!sideDrawerOpen);
    };

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        sideDrawerOpenHandler(false);
    }

    const isMobile = size.width <= 767.98;
    const isTablet = size.width >= 767.99 && size.width <= 1025.98;
    return (
        <div style={{ height: '100%' }} id="lumisoft-front">
            {
                loadingprops ? null :
                    <Header
                        drawerClickHandler={drawerToggleClickHandler}
                        isMobile={isMobile}
                        isTablet={isTablet}
                    />
            }
            {
                loadingprops ? null :
                    <SideDrawer
                        show={sideDrawerOpen}
                        click={backdropClickHandler}
                        isMobile={isMobile}
                        isTablet={isTablet}
                    />
            }
            {
                loadingprops ? null :
                <Home />
            }
            {
                sideDrawerOpen &&
                <BackDrop click={backdropClickHandler} />
            }
            {
                loadingprops ? null :

                    <Element name="contact">
                        <Footer
                            isMobile={isMobile}
                            isTablet={isTablet}
                        />
                    </Element>
            }
            {
                loadingprops ? null :
                isMobile ?
                    <a
                        rel="noopener noreferrer"
                        href={`whatsapp://send?text=hello&phone=+62${allwebsite && allwebsite[0].whatsapp}`}
                        target="_blank"
                        style={{ paddingTop: '0', cursor: 'pointer' }}
                    >
                        <button
                            className="floatingbutton"
                        >
                            <div className="buttonicon">
                                <FaWhatsapp
                                    size={28}
                                />
                            </div>
                        </button>
                    </a>
                    :
                    <a
                        rel="noopener noreferrer"
                        href={`https://web.whatsapp.com/send?phone=+62${allwebsite && allwebsite[0].whatsapp}`}
                        target="_blank"
                        style={{ paddingTop: '30px', cursor: 'pointer' }}
                    >
                        <button
                            className="floatingbutton"
                        >
                            <div className="buttonicon">
                                <FaWhatsapp
                                    size={28}
                                />
                            </div>
                        </button>
                    </a>
            }
        </div>
    );
};

export default HeaderVisibleLayout;