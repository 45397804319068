import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';

import BackDrop from '../../views/layout/backdrop/tablebackdrop';
import FormField from '../../utils/form/formfield';
import FileUploadSingle from '../../utils/form/fileuploadsingle';
import { update, validate, generateData, isFormValid } from '../../utils/form/formactions';
import {
    getWebsiteAddress,
    addWebsiteAddress,
    clearWebsiteAddress,
    addWebsite,
    clearWebsite,
    getWebsite,
    addWebsiteLogo,
    addWebsiteLogoMobile,
    addWebsiteLogoFooter,
    updateWebsite,
    clearUpdateWebsite
} from '../../../../store/actions/website_action';

// import {
//     getProvince,
//     getCity
// } from '../../../store/actions/ongkir_action';

import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const WebsiteTableScreen = (props) => {
    const useOutsideAlerter = (ref) => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                searchmodeHandler(false);
                // chooseprovinceHandler(false);
            }
        }

        useEffect(() => {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const {
        getalldata
        // getongkir 
    } = useSelector(state => ({
        getalldata: state.website
        // getongkir: state.ongkir
    }))
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    // const size = useWindowSize();
    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [additionalformError, additionalformErrorHandler] = useState(false);
    const [additionalformSuccess, additionalformSuccessHandler] = useState(false);
    const [additionalinfoError, additionalinfoErrorHandler] = useState(false);
    // const [additionalinfoSuccess] = useState(false);
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    const [alldata, alldataHandler] = useState([]);
    // const [allprovince, allprovinceHandler] = useState([]);
    // const [allcity, allcityHandler] = useState([]);

    const [addaddress, addaddressHandler] = useState(false);
    const [dontblur, dontblurHandler] = useState(false);

    // const [chooseprovince, chooseprovinceHandler] = useState(false);
    // const [dontblurprovince, dontblurprovinceHandler] = useState(false);

    // const [choosecity, choosecityHandler] = useState(false);
    // const [dontblurcity, dontblurcityHandler] = useState(false);

    // const [choosesubdistrict, choosesubdistrictHandler] = useState(false);
    // const [dontblursubdistrict, dontblursubdistrictHandler] = useState(false);

    const [searchmode, searchmodeHandler] = useState(false);

    const [searchaddress, searchaddressHandler] = useState("");
    // const [searchprovince, searchprovinceHandler] = useState("");
    // const [searchcity, searchcityHandler] = useState("");
    // const [searchsubdistrict, searchsubdistrictHandler] = useState("");

    const [searchresult, searchresultHandler] = useState([]);
    const [imageToUpload, imageToUploadHandler] = useState({
        logo: {
            value: []
        }
    });
    const [imageToDelete, imageToDeleteHandler] = useState({
        logo: {
            value: []
        }
    });
    const [image2ToUpload, image2ToUploadHandler] = useState({
        logomobile: {
            value: []
        }
    });
    const [image2ToDelete, image2ToDeleteHandler] = useState({
        logomobile: {
            value: []
        }
    });
    const [image3ToUpload, image3ToUploadHandler] = useState({
        logofooter: {
            value: []
        }
    });
    const [image3ToDelete, image3ToDeleteHandler] = useState({
        logofooter: {
            value: []
        }
    });

    const [formdata, formdataHandler] = useState({
        name: {
            element: 'input',
            title: 'Title',
            value: editformdatastatus ? dataselected && dataselected.name : '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter website name'
            },
            validation: {
                required: true
            },
            valid: editformdatastatus ? true : false,
            touched: editformdatastatus ? true : false,
            validationMessage: ''
        },
        company: {
            element: 'input',
            title: 'Company',
            value: editformdatastatus ? dataselected && dataselected.company : '',
            config: {
                name: 'companyInput',
                type: 'text',
                placeholder: 'Enter company name'
            },
            validation: {
                required: false
            },
            valid: editformdatastatus ? true : false,
            touched: editformdatastatus ? true : false,
            validationMessage: ''
        },
        copyright: {
            element: 'input',
            title: 'Copyright',
            value: editformdatastatus ? dataselected && dataselected.copyright : '',
            config: {
                name: 'copyrightInput',
                type: 'text',
                placeholder: 'Enter copyright name'
            },
            validation: {
                required: false
            },
            valid: editformdatastatus ? true : false,
            touched: editformdatastatus ? true : false,
            validationMessage: ''
        },
        aboutus: {
            element: 'input',
            title: 'Aboutus',
            value: editformdatastatus ? dataselected && dataselected.aboutus : '',
            config: {
                name: 'aboutusInput',
                type: 'text',
                placeholder: 'Enter aboutus'
            },
            validation: {
                required: true
            },
            valid: editformdatastatus ? true : false,
            touched: editformdatastatus ? true : false,
            validationMessage: ''
        },
        email: {
            element: 'input',
            title: 'Email',
            value: editformdatastatus ? dataselected && dataselected.email : '',
            config: {
                name: 'emailInput',
                type: 'text',
                placeholder: 'Enter email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: editformdatastatus ? true : false,
            touched: editformdatastatus ? true : false,
            validationMessage: ''
        },
        phone: {
            element: 'input',
            title: 'Phone',
            value: editformdatastatus ? dataselected && dataselected.phone : '',
            config: {
                name: 'phoneInput',
                type: 'text',
                placeholder: 'Enter phone'
            },
            validation: {
                required: true,
                phone: true
            },
            valid: editformdatastatus ? true : false,
            touched: editformdatastatus ? true : false,
            validationMessage: ''
        },
        address: {
            element: 'select',
            title: 'Address',
            selectedValue: editformdatastatus ? `${dataselected && dataselected.address && dataselected.address.name.replace(/^\w/, c => c.toUpperCase())} ${dataselected && dataselected.address && dataselected.address.addressname ? `(${dataselected && dataselected.address && dataselected.address.addressname})` : null}` : '',
            selectedData: editformdatastatus ? dataselected && dataselected.address : {},
            value: editformdatastatus ? dataselected && dataselected.address && dataselected.address._id : '',
            config: {
                name: 'addressInput',
                options: [],
                placeholder: editformdatastatus ? `${dataselected && dataselected.address && dataselected.address.name.replace(/^\w/, c => c.toUpperCase())} ${dataselected && dataselected.address && dataselected.address.addressname ? `(${dataselected && dataselected.address && dataselected.address.addressname})` : null}` : 'Choose address'
            },
            validation: {
                required: true
            },
            valid: editformdatastatus ? true : false,
            touched: editformdatastatus ? true : false,
            validationMessage: ''
        },
        facebook: {
            element: 'input',
            title: 'Facebook',
            value: editformdatastatus ? dataselected && dataselected.facebook : '',
            config: {
                name: 'facebookInput',
                type: 'text',
                placeholder: 'Enter facebook'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        instagram: {
            element: 'input',
            title: 'Instagram',
            value: editformdatastatus ? dataselected && dataselected.instagram : '',
            config: {
                name: 'instagramInput',
                type: 'text',
                placeholder: 'Enter instagram id'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        whatsapp: {
            element: 'input',
            title: 'Whatsapp',
            value: editformdatastatus ? dataselected && dataselected.whatsapp : '',
            config: {
                name: 'whatsappInput',
                type: 'text',
                placeholder: 'Enter whatsapp number'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });
    const [formlogo, formlogoHandler] = useState({
        logo: {
            title: 'Logo',
            value: editformdatastatus ? dataselected && dataselected.logo : [],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    })
    const [formlogomobile, formlogomobileHandler] = useState({
        logomobile: {
            title: 'Logomobile',
            value: editformdatastatus ? dataselected && dataselected.logomobile : [],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    })
    const [formlogofooter, formlogofooterHandler] = useState({
        logofooter: {
            title: 'Logofooter',
            value: editformdatastatus ? dataselected && dataselected.logofooter : [],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    })
    const [formaddaddress, formaddaddressHandler] = useState({
        name: {
            element: 'input',
            title: 'Name',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter your name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        phone: {
            element: 'input',
            title: 'Phone',
            value: '',
            config: {
                name: 'phoneInput',
                type: 'text',
                placeholder: 'Enter address phone'
            },
            validation: {
                required: true,
                phone: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        addressname: {
            element: 'input',
            title: 'Addressname',
            value: '',
            config: {
                name: 'addressnameInput',
                type: 'text',
                placeholder: 'Enter address name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        address: {
            element: 'input',
            title: 'Address',
            value: '',
            config: {
                name: 'address1Input',
                type: 'text',
                placeholder: 'Enter address'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        rtrw: {
            element: 'input',
            title: 'RT / RW',
            value: '',
            config: {
                name: 'rtrwInput',
                type: 'text',
                placeholder: 'Enter RT / RW'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        kelurahan: {
            element: 'input',
            title: 'Kelurahan',
            value: '',
            config: {
                name: 'kelurahanInput',
                type: 'text',
                placeholder: 'Kelurahan'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        kecamatan: {
            element: 'input',
            title: 'Kecamatan',
            value: '',
            config: {
                name: 'kecamatanInput',
                type: 'text',
                placeholder: 'Kecamatan'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        postcode: {
            element: 'input',
            title: 'Postcode',
            value: '',
            config: {
                name: 'postcodeInput',
                type: 'text',
                placeholder: 'Enter website postcode'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
        // province: {
        //     element: 'select',
        //     title: 'Province',
        //     selectedValue: '',
        //     value: '',
        //     config: {
        //         name: 'provinceInput',
        //         options: [],
        //         placeholder: 'Choose province'
        //     },
        //     validation: {
        //         required: true
        //     },
        //     valid: false,
        //     touched: false,
        //     validationMessage: ''
        // },
        // city: {
        //     element: 'select',
        //     title: 'City',
        //     selectedValue: '',
        //     value: '',
        //     config: {
        //         name: 'cityInput',
        //         options: [],
        //         placeholder: 'Choose city'
        //     },
        //     validation: {
        //         required: true
        //     },
        //     valid: false,
        //     touched: false,
        //     validationMessage: ''
        // },
        // subdistrict: {
        //     element: 'select',
        //     title: 'Subdistrict',
        //     selectedValue: '',
        //     value: '',
        //     config: {
        //         name: 'subdistrictInput',
        //         options: [],
        //         placeholder: 'Choose subdistrict'
        //     },
        //     validation: {
        //         required: true
        //     },
        //     valid: false,
        //     touched: false,
        //     validationMessage: ''
        // },
    });

    const additionalClickHandler = () => {
        document.body.style.overflow = 'overlay';
        addaddressHandler(false);
        additionalinfoErrorHandler(false);
    }

    const AddAddressBackdrop = () => {
        addaddressHandler(true);
        formErrorHandler(false);
        additionalformErrorHandler(false);
    }

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        var newFormdata = formaddaddress;
        for (let key in newFormdata) {
            // newFormdata[key].value = dataselected[key];
            // newFormdata[key].valid = true;
            // newFormdata[key].touched = true;
            // newFormdata[key].validationMessage = '';
            if (key === 'images') {
                newFormdata[key].value = [];
                newFormdata[key].valid = true;
            } else {
                newFormdata[key].value = '';
                newFormdata[key].valid = false;
            }
            newFormdata[key].touched = false;
            newFormdata[key].validationMessage = '';
        }

        formaddaddressHandler(newFormdata);
        addaddressHandler(false);
        additionalformErrorHandler(false);
    }
    // useEffect(() => {
    //     let mounted = true;
    //     async function editformInputData() {
    //         if (mounted && props.editformdatastatus === true) {
    //             var newFormdata = formdata;
    //             for (let key in newFormdata) {
    //                 newFormdata[key].value = dataselected[key];
    //                 newFormdata[key].valid = true;
    //                 newFormdata[key].touched = true;
    //                 newFormdata[key].validationMessage = '';
    //                 // if (key === 'images') {
    //                 //     newFormdata[key].value = [];
    //                 //     newFormdata[key].valid = true;
    //                 // } else {
    //                 //     newFormdata[key].value = '';
    //                 //     newFormdata[key].valid = false;
    //                 // }
    //                 // newFormdata[key].touched = false;
    //                 // newFormdata[key].validationMessage = '';
    //             }
    //             formdataHandler(newFormdata);
    //         }
    //     }
    //     editformInputData();
    //     return () => {
    //         mounted = false;
    //     }
    // }, [props.editformdatastatus, dataselected, formdata])\

    useEffect(() => {
        dispatch(getWebsiteAddress());
        // dispatch(getProvince());
        // dispatch(getCity());
    }, [dispatch])
    useEffect(() => {
        if (getalldata.getWebsiteAddress && getalldata.getWebsiteAddress.success) {
            let totaldata = getalldata.getWebsiteAddress.addresses.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            alldataHandler(totaldata);
            searchresultHandler(totaldata);
        }
        // if (getongkir.province && getongkir.province.success) {
        //     let totalprovince = JSON.parse('[' + getongkir.province.province + ']')[0].rajaongkir.results
        //     allprovinceHandler(totalprovince);
        // }
        // if (getongkir.city && getongkir.city.success) {
        //     let totalcity = JSON.parse('[' + getongkir.city.city + ']')[0].rajaongkir.results
        //     allcityHandler(totalcity);
        // }
    }, [
        getalldata
        // getongkir
    ])
    useEffect(() => {
        if (alldata) {
            const results = alldata.filter(result =>
                result.name.toLowerCase().includes(searchaddress.toLowerCase())
            );
            searchresultHandler(results);
        }
    }, [alldata, searchaddress]);
    // useEffect(() => {
    //     if (allprovince) {
    //         const provinceresults = allprovince.filter(province =>
    //             province.province.toLowerCase().includes(searchprovince.toString().toLowerCase())
    //         );
    //         searchprovinceHandler(provinceresults);
    //     }
    // }, []);

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'portfolio');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }
    const additionalUpdateForm = (element) => {
        const newFormdata = update(element, formaddaddress, 'add address');
        additionalformErrorHandler(false);
        formaddaddressHandler(newFormdata);
    }


    ////////////////////////////////////////////////// ADDRESS
    const searchForm = () => {
        searchmodeHandler(true)
    }
    const onMouseEnter = () => {
        dontblurHandler(true)
    }
    const onMouseLeave = () => {
        dontblurHandler(false)
    }
    const hideprofilemenu = () => {
        // searchaddressHandler("");
        searchmodeHandler(false)
    }
    const handleChange = (event) => {
        searchaddressHandler(event.target.value);
    }
    const selectedItem = (data, whichdata) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = `${data.name.replace(/^\w/, c => c.toUpperCase())} ${data.addressname ? `(${data.addressname})` : null}`;
        newElement.selectedData = data;
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        formdataHandler(temporaryFormdata);
        searchaddressHandler("");
        formErrorHandler(false);
        searchmodeHandler(false);
        dontblurHandler(false);
    }
    ////////////////////////////////////////////////// ADDRESS


    ////////////////////////////////////////////////// PROVINCE
    // const searchFormProvince = () => {
    //     chooseprovinceHandler(true);
    // }
    // const onMouseEnterProvince = () => {
    //     dontblurprovinceHandler(true)
    // }
    // const onMouseLeaveProvince = () => {
    //     dontblurprovinceHandler(false)
    // }
    // const hideprovince = () => {
    //     searchprovinceHandler("");
    //     chooseprovinceHandler(false)
    // }
    // const handleChangeProvince = (event) => {
    //     searchprovinceHandler(event.target.value);
    // }
    // const selectedItemProvince = (data, whichdata) => {
    //     var newFormdata = formaddaddress;
    //     const temporaryFormdata = {
    //         ...newFormdata
    //     }

    //     const newElement = {
    //         ...temporaryFormdata[whichdata]
    //     }

    //     newElement.value = data._id;
    //     newElement.selectedValue = `${data.name.replace(/^\w/, c => c.toUpperCase())} ${data.ipaddress}`;
    //     let validData = validate(newElement, newFormdata);
    //     newElement.valid = validData[0];
    //     newElement.validationMessage = validData[1];
    //     temporaryFormdata[whichdata] = newElement;
    //     formaddaddressHandler(temporaryFormdata);
    //     formErrorHandler(false);
    //     chooseprovinceHandler(false);
    //     dontblurprovinceHandler(false);
    // }

    // const showProvince = (type, whichdata, whichfunction) => {
    //     let list = [];

    //     if (type && type.length > 0) {
    //         type.forEach((item) => {
    //             list.push(item)
    //         });
    //     }

    //     return list.map((item, i) => (
    //         <li
    //             key={i}
    //             onClick={() => whichfunction(item, whichdata)}
    //             className="listgroupitem"
    //         >
    //             <span className="memberHeaderLink">
    //                 {item.province.replace(/^\w/, c => c.toUpperCase())}
    //             </span>

    //         </li>
    //     ))
    // }
    ////////////////////////////////////////////////// PROVINCE


    const showLinks = (type, whichdata, whichfunction) => {
        let list = [];

        if (type && type.length > 0) {
            type.forEach((item) => {
                list.push(item)
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={() => whichfunction(item, whichdata)}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {item.name.replace(/^\w/, c => c.toUpperCase())} {item.addressname ? `(${item.addressname})` : null}
                    </span>
                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    ////////////////////////////////////////////////// FORMLOGO
    const previewFileLogo = (files, uploadname) => {
        formErrorHandler(false)
        const arrOfPromises = files.map((file, i) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                const img = new Image();
                reader.onload = (e) => {
                    img.src = e.target.result;
                    img.onload = function () {
                        file.id = i;
                        file.url = e.target.result;
                        file.width = img.width;
                        file.height = img.height;
                        resolve(file);
                    }
                }
                reader.readAsDataURL(file);
            });

        });

        Promise.all(arrOfPromises).then(imagesToAdd => {
            let imagesToAddSort = imagesToAdd.sort((a, b) => {
                if (b.name > a.name)
                    return -1;
                if (b.name < a.name)
                    return 1;
                return 0;
            });

            let pass = false;
            imagesToAddSort.forEach((item, index) => {
                if (item.width === 200 && item.height === 40) {
                    pass = true;
                }
            })
            if (pass) {
                var newFormdata = formlogo;
                const temporaryFormdata = {
                    ...newFormdata
                }

                const newElement = {
                    ...temporaryFormdata[uploadname]
                }
                if (imagesToAddSort.length < 2) {
                    newElement.value = imagesToAddSort
                    newElement.valid = true
                    newElement.touched = true
                } else {
                    formErrorHandler(true)
                    errorMessageHandler("ONLY ONE IMAGE!")
                }
                temporaryFormdata[uploadname] = newElement;
                formlogoHandler(temporaryFormdata);
            } else {
                formErrorHandler(true)
                errorMessageHandler("200 x 40 !!")
            }

        }).catch(err => console.log('err loading images', err));
    }

    const onRemoveLogo = (item, uploadname) => {
        var newFormdata = formlogo;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (editformdatastatus) {
            var newFormdata2 = imageToDelete;
            const temporaryFormdata2 = {
                ...newFormdata2
            }

            const newElement2 = {
                ...temporaryFormdata2[uploadname]
            }
            const updatedImages = newElement.value.filter(i => i.url !== item.url);
            newElement2.value.push(item.public_id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            temporaryFormdata2[uploadname] = newElement2;
            formlogoHandler(temporaryFormdata);
            imageToDeleteHandler(temporaryFormdata2);
        } else {
            const updatedImages = newElement.value.filter(i => i.id !== item.id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            formlogoHandler(temporaryFormdata);
        }
    }

    const showSoonToUpdateLogo = (uploadname) => {
        var newFormdata = formlogo;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail mt10">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => onRemoveLogo(item, uploadname)}
                            >
                                <img src={item.url} alt="" style={{ height: '50px' }} />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    const showlogoWillbeRemove = (item, uploadname) => {
        var newFormdata = imageToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        const updatedImages = newElement.value.filter(i => i.id !== item.id);
        newElement.value = updatedImages;
        temporaryFormdata[uploadname] = newElement;
        imageToUploadHandler(temporaryFormdata);
    }

    const showlogoWillbeUpdate = (uploadname) => {
        var newFormdata = imageToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail mt10">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => showlogoWillbeRemove(item, uploadname)}
                            >
                                <img src={item.url} alt="" style={{ height: '50px' }} />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )

        }
    }
    ////////////////////////////////////////////////// FORMLOGO


    ////////////////////////////////////////////////// FORMLOGOMOBILE
    const previewFileLogoMobile = (files, uploadname) => {
        formErrorHandler(false)
        const arrOfPromises = files.map((file, i) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                const img = new Image();
                reader.onload = (e) => {
                    img.src = e.target.result;
                    img.onload = function () {
                        file.id = i;
                        file.url = e.target.result;
                        file.width = img.width;
                        file.height = img.height;
                        resolve(file);
                    }
                }
                reader.readAsDataURL(file);
            });

        });

        Promise.all(arrOfPromises).then(imagesToAdd => {
            let imagesToAddSort = imagesToAdd.sort((a, b) => {
                if (b.name > a.name)
                    return -1;
                if (b.name < a.name)
                    return 1;
                return 0;
            });

            let pass = false;
            imagesToAddSort.forEach((item, index) => {
                if (item.width === 160 && item.height === 50) {
                    pass = true;
                }
            })
            if (pass) {
                var newFormdata = formlogomobile;
                const temporaryFormdata = {
                    ...newFormdata
                }

                const newElement = {
                    ...temporaryFormdata[uploadname]
                }
                if (imagesToAddSort.length < 2) {
                    newElement.value = imagesToAddSort
                    newElement.valid = true
                    newElement.touched = true
                } else {
                    formErrorHandler(true)
                    errorMessageHandler("ONLY ONE IMAGE!")
                }
                temporaryFormdata[uploadname] = newElement;
                formlogomobileHandler(temporaryFormdata);
            } else {
                formErrorHandler(true)
                errorMessageHandler("160 x 50 !!")
            }

        }).catch(err => console.log('err loading images', err));
    }

    const onRemoveLogoMobile = (item, uploadname) => {
        var newFormdata = formlogomobile;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (editformdatastatus) {
            var newFormdata2 = image2ToDelete;
            const temporaryFormdata2 = {
                ...newFormdata2
            }

            const newElement2 = {
                ...temporaryFormdata2[uploadname]
            }
            const updatedImages = newElement.value.filter(i => i.url !== item.url);
            newElement2.value.push(item.public_id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            temporaryFormdata2[uploadname] = newElement2;
            formlogomobileHandler(temporaryFormdata);
            image2ToDeleteHandler(temporaryFormdata2);
        } else {
            const updatedImages = newElement.value.filter(i => i.id !== item.id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            formlogomobileHandler(temporaryFormdata);
        }
    }

    const showSoonToUpdateLogoMobile = (uploadname) => {
        var newFormdata = formlogomobile;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail mt10">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => onRemoveLogoMobile(item, uploadname)}
                            >
                                <img src={item.url} alt="" style={{ height: '50px' }} />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    const showlogoMobileWillbeRemove = (item, uploadname) => {
        var newFormdata = image2ToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        const updatedImages = newElement.value.filter(i => i.id !== item.id);
        newElement.value = updatedImages;
        temporaryFormdata[uploadname] = newElement;
        image2ToUploadHandler(temporaryFormdata);
    }

    const showlogoMobileWillbeUpdate = (uploadname) => {
        var newFormdata = image2ToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail mt10">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => showlogoMobileWillbeRemove(item, uploadname)}
                            >
                                <img src={item.url} alt="" style={{ height: '50px' }} />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )

        }
    }
    ////////////////////////////////////////////////// FORMLOGOMOBILE

    ////////////////////////////////////////////////// FORMLOGOFOOTER
    const previewFileLogoFooter = (files, uploadname) => {
        formErrorHandler(false)
        const arrOfPromises = files.map((file, i) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                const img = new Image();
                reader.onload = (e) => {
                    img.src = e.target.result;
                    img.onload = function () {
                        file.id = i;
                        file.url = e.target.result;
                        file.width = img.width;
                        file.height = img.height;
                        resolve(file);
                    }
                }
                reader.readAsDataURL(file);
            });

        });

        Promise.all(arrOfPromises).then(imagesToAdd => {
            let imagesToAddSort = imagesToAdd.sort((a, b) => {
                if (b.name > a.name)
                    return -1;
                if (b.name < a.name)
                    return 1;
                return 0;
            });

            let pass = false;
            imagesToAddSort.forEach((item, index) => {
                if (item.width === 160 && item.height === 50) {
                    pass = true;
                }
            })
            if (pass) {
                var newFormdata = formlogofooter;
                const temporaryFormdata = {
                    ...newFormdata
                }

                const newElement = {
                    ...temporaryFormdata[uploadname]
                }
                if (imagesToAddSort.length < 2) {
                    newElement.value = imagesToAddSort
                    newElement.valid = true
                    newElement.touched = true
                } else {
                    formErrorHandler(true)
                    errorMessageHandler("ONLY ONE IMAGE!")
                }
                temporaryFormdata[uploadname] = newElement;
                formlogofooterHandler(temporaryFormdata);
            } else {
                formErrorHandler(true)
                errorMessageHandler("160 x 50 !!")
            }

        }).catch(err => console.log('err loading images', err));
    }

    const onRemoveLogoFooter = (item, uploadname) => {
        var newFormdata = formlogofooter;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (editformdatastatus) {
            var newFormdata2 = image3ToDelete;
            const temporaryFormdata2 = {
                ...newFormdata2
            }

            const newElement2 = {
                ...temporaryFormdata2[uploadname]
            }
            const updatedImages = newElement.value.filter(i => i.url !== item.url);
            newElement2.value.push(item.public_id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            temporaryFormdata2[uploadname] = newElement2;
            formlogofooterHandler(temporaryFormdata);
            image3ToDeleteHandler(temporaryFormdata2);
        } else {
            const updatedImages = newElement.value.filter(i => i.id !== item.id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            formlogofooterHandler(temporaryFormdata);
        }
    }

    const showSoonToUpdateLogoFooter = (uploadname) => {
        var newFormdata = formlogofooter;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail mt10">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => onRemoveLogoFooter(item, uploadname)}
                            >
                                <img src={item.url} alt="" style={{ height: '50px' }} />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    const showlogoFooterWillbeRemove = (item, uploadname) => {
        var newFormdata = image3ToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        const updatedImages = newElement.value.filter(i => i.id !== item.id);
        newElement.value = updatedImages;
        temporaryFormdata[uploadname] = newElement;
        image3ToUploadHandler(temporaryFormdata);
    }

    const showlogoFooterWillbeUpdate = (uploadname) => {
        var newFormdata = image3ToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail mt10">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => showlogoFooterWillbeRemove(item, uploadname)}
                            >
                                <img src={item.url} alt="" style={{ height: '50px' }} />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )

        }
    }
    ////////////////////////////////////////////////// FORMLOGOMOBILE

    const goBackToTable = () => {
        props.history.push('/admin/website');
    }

    const submitAdditionalAddress = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let addressToSubmit = generateData(formaddaddress, 'addaddress');
        let addressIsValid = isFormValid(formaddaddress, 'addaddress');

        if (addressIsValid) {
            dispatch(addWebsiteAddress(addressToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearWebsiteAddress());
                    additionalformSuccessHandler(true);
                    props.history.push('/admin/website/addnew');
                } else {
                    additionalformErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            additionalformErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('FORM ERROR!');
        }
    }

    const fetchingnewData = async (id) => {
        let selectedwebsite = await dispatch(getWebsite());
        let final = selectedwebsite.payload.websites.find(site => site._id === id);
        props.history.push({
            pathname: `/admin/website/editdata/${id}`,
            state: {
                dataselected: final,
                editformdata: true
            }
        })
    }

    const SubmitWebsiteLogo = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formlogo, 'formlogo');
        let formIsValid = isFormValid(formlogo, 'formlogo');
        let dataselectedid = dataselected._id;

        if (formIsValid) {
            dispatch(addWebsiteLogo(dataToSubmit, dataselectedid)).then(response => {
                if (response.payload.success) {
                    formSuccessHandler(true);
                    fetchingnewData(dataselectedid);
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const SubmitWebsiteLogoMobile = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formlogomobile, 'formlogomobile');
        let formIsValid = isFormValid(formlogomobile, 'formlogomobile');
        let dataselectedid = dataselected._id;

        if (formIsValid) {
            dispatch(addWebsiteLogoMobile(dataToSubmit, dataselected._id)).then(response => {
                if (response.payload.success) {
                    formSuccessHandler(true);
                    fetchingnewData(dataselectedid);
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const SubmitWebsiteLogoFooter = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formlogofooter, 'formlogofooter');
        let formIsValid = isFormValid(formlogofooter, 'formlogofooter');
        let dataselectedid = dataselected._id;

        if (formIsValid) {
            dispatch(addWebsiteLogoFooter(dataToSubmit, dataselected._id)).then(response => {
                if (response.payload.success) {
                    formSuccessHandler(true);
                    fetchingnewData(dataselectedid);
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const submitData = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formdata, 'addWebsite');
        let formIsValid = isFormValid(formdata, 'addWebsite');

        if (formIsValid) {
            dispatch(addWebsite(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearWebsite());
                    formSuccessHandler(true);
                    props.history.push('/admin/website');
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            // errorMessageHandler(portfolioprops)
        }
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'formdataedit');
        let formIsValid2 = isFormValid(formdata, 'formdataedit');
        let dataselectedid = dataselected._id;
        let datachange = 0;

        for (let key1 in dataToSubmit2) {
            // console.log(dataselected[key1], `${key1} >>>>>dataselected`)
            // console.log(dataToSubmit2[key1], `${key1} >>>>>dataToSubmit2`)
            if (key1 === 'address') {
                if (dataselected[key1]._id !== dataToSubmit2[key1]) {
                    datachange = datachange + 1;
                }
            } else {
                if (dataselected[key1] !== dataToSubmit2[key1]) {
                    datachange = datachange + 1;
                }
            }

        }

        if (datachange > 0) {
            if (formIsValid2) {
                dispatch(updateWebsite(dataToSubmit2, dataselectedid)).then(response => {
                    if (response.payload.success) {
                        dispatch(clearUpdateWebsite());
                        formSuccessHandler(true);
                        setTimeout(() => {
                            fetchingnewData(dataselectedid);
                        }, 1000);
                    } else {
                        formErrorHandler(true);
                        errorMessageHandler(response.payload.message);
                    }
                })
            } else {
                formErrorHandler(true);
                errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler('PLEASE CHANGE DATA FIRST!');
        }
        // if (datachange === 5) {
        //     formErrorHandler(true);
        //     props.loadingtableHandler(false);
        //     errorMessageHandler('CHANGE DATA BEFORE SUBMIT!');
        // } else {
        //     const totaldataToSubmit = {
        //         ...dataToSubmit2,
        //         _id: dataselected._id,
        //     }
        //     if (formIsValid2) {
        //         totaldataToSubmit.imagesToRemove = imageToDelete.images.value;
        //         totaldataToSubmit.imagesToAdd = imageToUpload.images.value;
        //         dispatch(updatePortfolio(totaldataToSubmit)).then(response => {
        //             if (response.payload.success) {
        //                 dispatch(clearUpdatePortfolio());
        //                 formSuccessHandler(true);
        //                 props.history.push('/admin/portfolio');
        //             } else {
        //                 formErrorHandler(true);
        //                 props.loadingtableHandler(false);
        //                 errorMessageHandler(response.payload.message);
        //             }
        //         })
        //     } else {
        //         formErrorHandler(true);
        //         props.loadingtableHandler(false);
        //         errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        //     }
        // }
    }

    // const deleteAdditional = (data, number) => {
    //     var newFormdata = formadditional;
    //     const temporaryFormdata = {
    //         ...newFormdata
    //     }

    //     const newElement = {
    //         ...temporaryFormdata['additional']
    //     }

    //     const updateValue = newElement.value.filter(a => a !== number);

    //     if (updateValue.length < 1) {
    //         newElement.value = [];
    //         newElement.valid = false;
    //         temporaryFormdata['additional'] = newElement;
    //         formadditionalHandler(temporaryFormdata);
    //     } else {
    //         newElement.value = updateValue;
    //         temporaryFormdata['additional'] = newElement;
    //         formadditionalHandler(temporaryFormdata);
    //     }

    // }

    // const showAdditionalInfo = () => {
    //     if (formadditional.additional.value && formadditional.additional.value.length > 0) {
    //         return (
    //             <div className="row pb25">
    //                 {
    //                     formadditional.additional.value.map((data, index) => (
    //                         <div className="col-md-12 col-xs-12 p0" key={index}>
    //                             <label className="col-md-12 col-xs-12 colFormLabel">
    //                                 <div className="deleteButtonWrapper">
    //                                     {data.info}
    //                                     <div
    //                                         className="deleteButton buttonColor"
    //                                         title=""
    //                                         onClick={() => deleteAdditional(data, formadditional.additional.value[index])}
    //                                         style={{
    //                                             marginLeft: '10px'
    //                                         }}
    //                                     >
    //                                         <FontAwesomeIcon
    //                                             icon={faTimes}
    //                                             className="icon agraicon w18px"
    //                                         />
    //                                     </div>
    //                                 </div>
    //                             </label>
    //                             <div className="col-md-5 col-xs-12" key={index}>
    //                                 <input
    //                                     disabled
    //                                     autoCapitalize="none"
    //                                     autoComplete="off"
    //                                     autoCorrect="off"
    //                                     className="form-control noicon"
    //                                     type="text"
    //                                     value={data.subinfo}
    //                                 />
    //                             </div>
    //                             <div className="col-md-7 col-xs-12">
    //                                 <input
    //                                     disabled
    //                                     autoCapitalize="none"
    //                                     autoComplete="off"
    //                                     autoCorrect="off"
    //                                     className="form-control noicon"
    //                                     type="text"
    //                                     value={data.link}
    //                                 />
    //                             </div>
    //                         </div>

    //                     ))
    //                 }

    //             </div>
    //         )
    //     }
    // }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////// SUBMIT ADDITIONAL DATA
    ////////////////////////////////////////////////////////////////////////////////////////////////////////


    // const showInformation = () => {
    //     let theinformation = formdata.additional.value.filter((item) => {
    //         return item.type === 'information'
    //     })
    //     if (theinformation && theinformation.length > 0) {
    //         return (
    //             <div className="row pb25">
    //                 <label className="col-md-12 col-xs-12 colFormLabel">Information</label>
    //                 {
    //                     theinformation.map((data, index) => (
    //                         <div className="col-md-12 col-xs-12 p0">
    //                             <label className="col-md-2 col-xs-12 colFormLabel">{data.info}</label>
    //                             <div className="col-md-10 col-xs-12" key={index}>
    //                                 <input
    //                                     disabled
    //                                     autoCapitalize="none"
    //                                     autoComplete="off"
    //                                     autoCorrect="off"
    //                                     className="form-control"
    //                                     type="text"
    //                                     value={data.subinfo}
    //                                 />
    //                             </div>

    //                         </div>

    //                     ))
    //                 }

    //             </div>
    //         )
    //     }
    // }

    // const showButton = () => {
    //     let thebutton = formdata.additional.value.filter((item) => {
    //         return item.type === 'button'
    //     })
    //     if (thebutton && thebutton.length > 0) {
    //         return (
    //             <div className="row pb25">
    //                 <label className="col-md-12 col-xs-12 colFormLabel">Button</label>
    //                 {
    //                     thebutton.map((data, index) => (
    //                         <div className="col-md-12 col-xs-12" key={index}>
    //                             <input
    //                                 disabled
    //                                 autoCapitalize="none"
    //                                 autoComplete="off"
    //                                 autoCorrect="off"
    //                                 className="form-control"
    //                                 type="text"
    //                                 value={data.info}
    //                             />
    //                         </div>
    //                     ))
    //                 }

    //             </div>
    //         )
    //     }
    // }

    return (
        <div className="cardBody formstyle">
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.name.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'name'}
                        formdata={formdata.name}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.company.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faAlignLeft}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'company'}
                        formdata={formdata.company}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.copyright.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faAlignLeft}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'copyright'}
                        formdata={formdata.copyright}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.aboutus.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faAlignLeft}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'aboutus'}
                        formdata={formdata.aboutus}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.email.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'email'}
                        formdata={formdata.email}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.phone.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faPhone}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'phone'}
                        formdata={formdata.phone}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.facebook.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <div className="icon agraicon">
                            <FaFacebookF />
                        </div>
                        {/* <FontAwesomeIcon
                                    icon={faPhone}
                                    className="icon agraicon"
                                /> */}
                    </div>
                    <FormField
                        id={'facebook'}
                        formdata={formdata.facebook}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.instagram.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <div className="icon agraicon">
                            <FaInstagram />
                        </div>
                    </div>
                    <FormField
                        id={'instagram'}
                        formdata={formdata.instagram}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.whatsapp.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <div className="icon agraicon">
                            <FaWhatsapp />
                        </div>
                    </div>
                    <FormField
                        id={'whatsapp'}
                        formdata={formdata.whatsapp}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.address.title}</label>
                <div className="col-md-9 col-xs-9 paddingRight">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faSortAlphaDown}
                            className="icon agraicon"
                        />
                    </div>
                    {
                        searchmode ?
                            <div ref={wrapperRef}>
                                <div
                                    onBlur={dontblur ? null : hideprofilemenu}
                                    tabIndex={0}
                                >
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name={formdata.address.title}
                                        placeholder={formdata.address.title}
                                        title={formdata.address.config.placeholder}
                                        value={searchaddress}
                                        onChange={(event) => handleChange(event)}
                                        autoFocus={true}
                                    />

                                </div>
                                <ul
                                    className="dropdownmenu listgroup profilemenu"
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                >
                                    {showLinks(searchresult, 'address', selectedItem)}
                                </ul>
                            </div>


                            :
                            <FormField
                                id={'address'}
                                formdata={formdata.address}
                                options={alldata}
                                change={searchForm}
                                myclass={`${alldata && alldata.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            />
                    }


                </div>
                <div className="col-md-1 col-xs-3">
                    <div className="categoryButtonWrapper">
                        <div
                            className="categoryButton buttonColor"
                            title=""
                            onClick={() => AddAddressBackdrop()}
                        >
                            <FontAwesomeIcon
                                icon={faPlus}
                                className="icon agraicon w18px"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                formdata.address && formdata.address.value && formdata.address.selectedValue && formdata.address.selectedData ?
                    <hr />
                    : null
            }
            {
                formdata.address && formdata.address.value && formdata.address.selectedValue && formdata.address.selectedData ?
                    <div className="row pb15 pt10">
                        <div className="col-md-12 col-xs-12">
                            <ul>
                                <li>Name: {formdata.address.selectedData.name}</li>
                                <li>Phone: {formdata.address.selectedData.phone}</li>
                                <li>Address Name: {formdata.address.selectedData.addressname}</li>
                                <li>Address: {formdata.address.selectedData.address}</li>
                                <li>RT / RW: {formdata.address.selectedData.rtrw}</li>
                                <li>Kelurahan: {formdata.address.selectedData.kelurahan}</li>
                                <li>Kecamatan: {formdata.address.selectedData.kecamatan}</li>
                                <li>Postcode: {formdata.address.selectedData.postcode}</li>
                            </ul>
                        </div>
                    </div>
                    : null
            }

            {
                editformdatastatus ? <hr /> : null
            }
            {
                editformdatastatus ?
                    <div className="row pb25">
                        <div className="col-md-4 col-xs-12 pl0 pr0 pb40">
                            <label className="col-md-11 col-xs-9 colFormLabel">{formlogo.logo.title}</label>
                            <div className="col-md-1 col-xs-3">
                                <div className="categoryButtonWrapper">
                                    <div
                                        className="categoryButton buttonColor"
                                        title=""
                                        onClick={(event) => SubmitWebsiteLogo(event)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            className="icon agraicon w18px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12 pt5">
                                <FileUploadSingle
                                    id={'fileuploadsingle'}
                                    reset={formSuccess}
                                    myclass={'form-control'}
                                    onFilesAlreadyAdded={previewFileLogo}
                                    uploadname={'logo'}
                                    formdata={formlogo}
                                />
                                {
                                    showSoonToUpdateLogo('logo')
                                }
                                {
                                    editformdatastatus ? showlogoWillbeUpdate('logo') : null
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 pl0 pr0 pb40">
                            <label className="col-md-11 col-xs-9 colFormLabel">{formlogomobile.logomobile.title}</label>
                            <div className="col-md-1 col-xs-3">
                                <div className="categoryButtonWrapper">
                                    <div
                                        className="categoryButton buttonColor"
                                        title=""
                                        onClick={(event) => SubmitWebsiteLogoMobile(event)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            className="icon agraicon w18px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12 pt5">
                                <FileUploadSingle
                                    id={'fileuploadsingle'}
                                    reset={formSuccess}
                                    myclass={'form-control'}
                                    onFilesAlreadyAdded={previewFileLogoMobile}
                                    uploadname={'logomobile'}
                                    formdata={formlogomobile}
                                />
                                {
                                    showSoonToUpdateLogoMobile('logomobile')
                                }
                                {
                                    editformdatastatus ? showlogoMobileWillbeUpdate('logomobile') : null
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 pl0 pr0 pb40">
                            <label className="col-md-11 col-xs-9 colFormLabel">{formlogofooter.logofooter.title}</label>
                            <div className="col-md-1 col-xs-3">
                                <div className="categoryButtonWrapper">
                                    <div
                                        className="categoryButton buttonColor"
                                        title=""
                                        onClick={(event) => SubmitWebsiteLogoFooter(event)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            className="icon agraicon w18px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12 pt5">
                                <FileUploadSingle
                                    id={'fileuploadsingle'}
                                    reset={formSuccess}
                                    myclass={'form-control'}
                                    onFilesAlreadyAdded={previewFileLogoFooter}
                                    uploadname={'logofooter'}
                                    formdata={formlogofooter}
                                />
                                {
                                    showSoonToUpdateLogoFooter('logofooter')
                                }
                                {
                                    editformdatastatus ? showlogoFooterWillbeUpdate('logofooter') : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
            }

            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                                    </div>
                            : null
                }
                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    {/* {
                                editformdatastatus && props.postadditionaldata ?
                                    <div
                                        className="doubleButton"
                                        style={{
                                            marginRight: '20px'
                                        }}
                                    >
                                        <button
                                            onClick={editformdatastatus ? (event) => submitAdditionalData(event) : null}
                                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                                        >
                                            Additional
                                        </button>
                                    </div>
                                    : null
                            } */}
                    <div className="doubleButton">
                        <button
                            onClick={editformdatastatus ? (event) => submitEditData(event) : (event) => submitData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                                </button>
                    </div>
                </div>
            </div>
            {
                addaddress &&
                <BackDrop click={backdropClickHandler} />
            }
            <div className={addaddress ? "rightSideOption open" : "rightSideOption"}>
                {
                    addaddress ?
                        <div className="actionTitle">
                            <div className="actionTitleText">Add Address</div>
                        </div>
                        : null

                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.name.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faFont}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'name'}
                                        formdata={formaddaddress.name}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.phone.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faPhone}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'phone'}
                                        formdata={formaddaddress.phone}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.addressname.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faFont}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'addressname'}
                                        formdata={formaddaddress.addressname}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.address.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faFont}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'address'}
                                        formdata={formaddaddress.address}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.rtrw.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faFont}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'rtrw'}
                                        formdata={formaddaddress.rtrw}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.kelurahan.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faFont}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'kelurahan'}
                                        formdata={formaddaddress.kelurahan}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.kecamatan.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faFont}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'kecamatan'}
                                        formdata={formaddaddress.kecamatan}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.postcode.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faFont}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'postcode'}
                                        formdata={formaddaddress.postcode}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }


                {/* {
                    addaddress ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >{formaddaddress.province.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    {
                                        chooseprovince ?
                                            <div>
                                                <div
                                                    onBlur={dontblurprovince ? null : hideprovince}
                                                    tabIndex={0}
                                                >
                                                    <input
                                                        autoCapitalize="none"
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        className="tableSearch"
                                                        type="text"
                                                        name={formaddaddress.province.title}
                                                        placeholder={formaddaddress.province.title}
                                                        title={formaddaddress.province.config.placeholder}
                                                        value={searchprovince}
                                                        onChange={(event) => handleChangeProvince(event)}
                                                        autoFocus={true}
                                                    />

                                                </div>
                                                <ul
                                                    className="dropdownmenu listgroup profilemenu"
                                                    onMouseEnter={onMouseEnterProvince}
                                                    onMouseLeave={onMouseLeaveProvince}
                                                >
                                                    {showProvince(searchprovince, 'province', selectedItemProvince)}
                                                </ul>
                                            </div>
                                            :
                                            <FormField
                                                id={'province'}
                                                formdata={formaddaddress.province}
                                                options={allprovince}
                                                change={searchFormProvince}
                                                myclass={`${allprovince && allprovince.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                } */}
                {
                    addaddress ?
                        <div className="additionalformstyle">
                            <div className="row" style={{
                                padding: "0 15px"
                            }}>
                                <div className="col-md-12">
                                    <div className="sideformSubmitButtonWithBack">
                                        <button
                                            onClick={(event) => submitAdditionalAddress(event)}
                                            className="formbackButton formsubmitButtonShadow buttonColor"
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                className="icon agraicon w18px"
                                            />
                                            &nbsp;Address
                                        </button>
                                    </div>
                                </div>
                                {
                                    additionalformError ?
                                        <div className="errorAddCategory">
                                            {errorMessage}
                                        </div>
                                        : additionalformSuccess ?
                                            <div className="successAddCategory">
                                                ADD SUCCESS, PLEASE WAIT!
                                            </div>
                                            : null
                                }
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    );
};

export default WebsiteTableScreen;