import React from 'react';
import { Switch, Route } from 'react-router-dom';

import '../resources/styles.css';
import Layout from '../layout/property';
import Auth from './auth';

import Property from '../views/property';
import NoMatch from '../views/nomatch';

const PropertyLayout = () => {
    return (
        <Layout>
            <Switch>
                <Route path="/property/:id" exact component={Auth(Property, null)} />
                <Route component={NoMatch} />
            </Switch>
        </Layout>
    );
};

export default PropertyLayout;