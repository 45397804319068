import React from 'react';
import {
    useSelector
} from 'react-redux';
import { FaChrome, FaInstagram, FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';

const FooterFront = (props) => {
    const { allwebsite } = useSelector(state => ({
        allwebsite: state.website.getWebsite && state.website.getWebsite.websites
    }));
    return (
        <div id="frontfooter">
            <div className="container">
                <div className="row">
                    <div className="lumi-md-6 lumi-xs-12">
                        <div className="frontwidget first">
                            <h3>{allwebsite && allwebsite[0].name}</h3>
                            <p>{allwebsite && allwebsite[0].aboutus}</p>
                            {
                                allwebsite && allwebsite[0].facebook === "" || allwebsite && allwebsite[0].instagram === "" ? null
                                    :
                                    <span>
                                        <address className="mb10">
                                            <strong>Offices: </strong>
                                            <br />
                                            {allwebsite && allwebsite[0].address.address}
                                            <br />
                                            {allwebsite && allwebsite[0].address.rtrw},&nbsp;{allwebsite && allwebsite[0].address.kecamatan},&nbsp;{allwebsite && allwebsite[0].address.kelurahan}&nbsp;{allwebsite && allwebsite[0].address.postcode}
                                            <br />
                                        </address>
                                        <abbr title="Phone Number">
                                            <strong>Phone: </strong>
                                        </abbr>
                                        {allwebsite && allwebsite[0].phone}
                                        <br />
                                        <abbr title="Phone Number 2">
                                            <strong>Phone: </strong>
                                        </abbr>
                                        {allwebsite && allwebsite[0].address.phone}
                                        <br />
                                        <abbr title="Email Address">
                                            <strong>Email: </strong>
                                        </abbr>
                                        {allwebsite && allwebsite[0].email}
                                    </span>
                            }
                        </div>
                    </div>
                    <div className="lumi-md-6 lumi-xs-12">
                        <div className="frontwidget">
                            {
                                allwebsite && allwebsite[0].facebook === "" || allwebsite && allwebsite[0].instagram === "" ?
                                    <span>
                                        <h3>COMPANY DETAILS</h3>
                                        <address className="mb10">
                                            <strong>Offices: </strong>
                                            <br />
                                            {allwebsite && allwebsite[0].address.address}
                                            <br />
                                            {allwebsite && allwebsite[0].address.rtrw},&nbsp;{allwebsite && allwebsite[0].address.kecamatan},&nbsp;{allwebsite && allwebsite[0].address.kelurahan}&nbsp;{allwebsite && allwebsite[0].address.postcode}
                                            <br />
                                        </address>
                                        <abbr title="Phone Number">
                                            <strong>Phone: </strong>
                                        </abbr>
                                        {allwebsite && allwebsite[0].phone}
                                        <br />
                                        <abbr title="Phone Number 2">
                                            <strong>Phone: </strong>
                                        </abbr>
                                        {allwebsite && allwebsite[0].address.phone}
                                        <br />
                                        <abbr title="Email Address">
                                            <strong>Email: </strong>
                                        </abbr>
                                        {allwebsite && allwebsite[0].email}
                                    </span>
                                    :
                                    <aside className="footersocial">
                                        <h3>Follow Us</h3>
                                        <ul className="list">
                                            <li>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="/"
                                                >
                                                    <FaFacebookSquare size={32} />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="/"
                                                >
                                                    <FaTwitterSquare size={32} />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="/"
                                                >
                                                    <FaInstagram size={34} />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="/"
                                                >
                                                    <FaChrome size={32} />
                                                </a>
                                            </li>
                                        </ul>
                                    </aside>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="container homecopyright">
                <div className="row">
                    <div className="lumi-md-12 lumi-xs-12">
                        <p className="footercopy">
                            © 2020
                            <a
                                href="https://lumisoft.co.id"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                &nbsp;{allwebsite && allwebsite[0].copyright}
                            </a>.{props.isMobile ? <br /> : null} All Rights Reserved / <a href="/" target="_blank" rel="noopener noreferrer">Terms of Use</a> / <a href="/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterFront;