import React, { useState, useEffect } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import { useHistory } from 'react-router';

import path from '../../../allroutes';
import { portopenaction } from '../../../../../store/actions/portfoliostatus_action';
import { stockstatusaction } from '../../../../../store/actions/stockstatus_action';
import { NavLink } from 'react-router-dom';
// import { logoutUser } from '../../../../../store/actions/user_action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const SideDrawer = (props) => {
    const history = useHistory();
    const { userprops } = useSelector(state => ({
        userprops: state.user.userData
    }));
    const dispatch = useDispatch();
    const [publicnav] = useState(props.routepath);
    const [stocknav] = useState(path.stockpath);
    const [portfolionav] = useState(path.portfoliopath);
    const [medianav] = useState(props.mediaroutepath);
    const [productnav] = useState(props.productroutepath);
    const [adminnav] = useState(props.adminroutepath);

    const [portopen, portopenHandler] = useState(false);
    const [stockopen, stockopenHandler] = useState(false);

    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();
        const statusCheck = async () => {
            try {
                if (mounted) {
                    if (
                        history.location.pathname.includes('/admin/portfolio') ||
                        history.location.pathname.includes('/admin/category')
                    ) {
                        portopenHandler(true);
                    } else if (
                        history.location.pathname.includes('/admin/stock') ||
                        history.location.pathname.includes('/admin/vendor') ||
                        history.location.pathname.includes('/admin/goods') ||
                        history.location.pathname.includes('/admin/material') ||
                        history.location.pathname.includes('/admin/converter') ||
                        history.location.pathname.includes('/admin/unit') ||
                        history.location.pathname.includes('/admin/formula')
                    ) {
                        stockopenHandler(true);
                    } else {
                        portopenHandler(false);
                        stockopenHandler(false);
                    }
                }
            } catch (error) {

            }
        }
        statusCheck();
        return () => {
            mounted = false;
            abortController.abort();
        }
    }, []);

    const openClosed = () => {
        if (portopen) {
            dispatch(portopenaction(false));
            portopenHandler(false);
        } else {
            dispatch(portopenaction(true));
            portopenHandler(true);
        }

    }

    const openClosedStock = () => {
        if (stockopen) {
            dispatch(stockstatusaction(false));
            stockopenHandler(false);
        } else {
            dispatch(stockstatusaction(true));
            stockopenHandler(true);
        }
    }

    // const logoutHandler = () => {
    //     dispatch(logoutUser()).then(response => {
    //         if (response.payload.success) {
    //             // console.log(response.payload);
    //             props.click()
    //             props.history.push('/');
    //         }
    //     })
    // }

    const publicLink = (items) => (
        userprops ?
            items.map((item, i) => {
                if (item.show) {
                    return (
                        <li key={i}>
                            <NavLink
                                to={item.link}
                                activeClassName="active"
                                onClick={() => props.click()}
                                exact
                            >
                                <div className="itemContent">
                                    <div className="itemIcon">
                                        <FontAwesomeIcon
                                            icon={item.faicons}
                                            className="icon"
                                        />
                                    </div>
                                    <div className="itemInner">
                                        <span className="title">{item.name}</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                    )
                } else {
                    return null;
                }
            })
            : null

    )

    const templateLink = (items) => (
        userprops ?
            items && items.map((item, i) => {
                if (item.show) {
                    return (
                        <li key={i}>
                            <NavLink
                                to={item.link}
                                activeClassName="active"
                                onClick={() => props.click()}
                                exact
                            >
                                <div className="itemContent">
                                    <div className="itemIcon">
                                        <FontAwesomeIcon
                                            icon={item.faicons}
                                            className="icon"
                                        />
                                    </div>
                                    <div className="itemInner">
                                        <span className="title">{item.name}</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                    )
                } else {
                    return null;
                }
            })
            : null
    )

    const dropdownLink = (items) => (
        userprops ?
            <ul className={portopen ? "customdropdown open" : "customdropdown"}>
                <li
                    onClick={() => openClosed()}
                >
                    <div className="itemContent">
                        <div
                            className="itemIcon"
                        >
                            <FontAwesomeIcon
                                icon={faLayerGroup}
                                className="icon"
                            />
                        </div>
                        <div className="customdropdowniconright">
                            <div className="itemInner">
                                <span className="title">{items[0].name}</span>
                            </div>
                            <span>
                                {
                                    portopen ?
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="icon"
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className="icon"
                                        />
                                }
                            </span>
                        </div>
                    </div>
                </li>
                {
                    portopen ?
                        items.map((item, i) => {
                            if (item.show) {
                                return (
                                    <li key={i}>
                                        <NavLink
                                            to={item.link}
                                            activeClassName="active"
                                            onClick={() => props.click()}
                                            exact
                                        >
                                            <div className="itemContent">
                                                <div className="itemIcon">
                                                    <FontAwesomeIcon
                                                        icon={item.faicons}
                                                        className="icon"
                                                    />
                                                </div>
                                                <div className="itemInner">
                                                    <span className="title">{item.name}</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                )
                            } else {
                                return null;
                            }
                        })
                        : null
                }
            </ul>
            : null
    )

    const statusdropdownLink = (items) => (
        userprops ?
            <ul className={stockopen ? "customdropdown open" : "customdropdown"}>
                <li
                    onClick={() => openClosedStock()}
                >
                    <div className="itemContent">
                        <div
                            className="itemIcon"
                        >
                            <FontAwesomeIcon
                                icon={faLayerGroup}
                                className="icon"
                            />
                        </div>
                        <div className="customdropdowniconright">
                            <div className="itemInner">
                                <span className="title">{items[0].name}</span>
                            </div>
                            <span>
                                {
                                    stockopen ?
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="icon"
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className="icon"
                                        />
                                }
                            </span>
                        </div>
                    </div>
                </li>
                {
                    stockopen ?
                        items.map((item, i) => {
                            if (item.show) {
                                return (
                                    <li key={i}>
                                        <NavLink
                                            to={item.link}
                                            activeClassName="active"
                                            onClick={() => props.click()}
                                            exact
                                        >
                                            <div className="itemContent">
                                                <div className="itemIcon">
                                                    <FontAwesomeIcon
                                                        icon={item.faicons}
                                                        className="icon"
                                                    />
                                                </div>
                                                <div className="itemInner">
                                                    <span className="title">{item.name}</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                )
                            } else {
                                return null;
                            }
                        })
                        : null
                }
            </ul>
            : null
    )

    const adminLink = (items) => (
        userprops ?
            items.map((item, i) => (
                <li key={i}>
                    <NavLink
                        to={item.link}
                        activeClassName="active"
                        onClick={() => props.click()}
                        exact
                    >
                        <div className="itemContent">
                            <div className="itemIcon">
                                <FontAwesomeIcon
                                    icon={item.faicons}
                                    className="icon"
                                />
                            </div>
                            <div className="itemInner">
                                <span className="title">{item.name}</span>
                            </div>
                        </div>
                    </NavLink>
                </li>
            ))
            : null
    )

    const className = `sideDrawer ${props.show ? 'open' : ''}`;
    //const className = drawerClasses.join(' ');
    // console.log(this.props)
    return (
        <nav className={className}>
            <div className="sideDrawerLogo">
                <img className="logo_img" src={window.location.origin + "/assets/images/lumisoft_side_white.png"} alt="" />
            </div>
            <ul>
                <li className="sidenavTitle" style={{ backgroundColor: '#222' }}>Admin version</li>
                {publicLink(publicnav)}
                <li className="sidenavTitle">Media Area</li>
                {dropdownLink(portfolionav)}
                {templateLink(medianav)}
                <li className="sidenavTitle">Ecommerce Area</li>
                {statusdropdownLink(stocknav)}
                {templateLink(productnav)}
                {
                    userprops ?
                        userprops.masteradmin > 0 ?
                            <li className="sidenavTitle">Masteradmin Area</li>
                            : null
                        : null
                }
                {
                    userprops ?
                        userprops.masteradmin > 0 ?
                            adminLink(adminnav)
                            : null
                        : null

                }
                <li className="version">{path.version}</li>
            </ul>
        </nav >
    );
};

export default SideDrawer;