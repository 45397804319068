import React, { useState, useEffect } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';

import path from '../../../allroutes';
import { portopenaction } from '../../../../../store/actions/portfoliostatus_action';
import { stockstatusaction } from '../../../../../store/actions/stockstatus_action';
import { useWindowSize } from '../../../../widget/windowsize';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faUser, faEnvelope, faShoppingBag, faCog, faShoppingBasket, faShoppingCart, faStickyNote, faMapMarkerAlt, faGlobe, faAddressBook, faHeart, faChessQueen, faLayerGroup, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faHome, faUser, faEnvelope, faShoppingBag, faCog, faShoppingBasket, faBell, faShoppingCart, faStickyNote, faMapMarkerAlt, faGlobe, faAddressBook, faHeart, faChessQueen)

const AdminLayout = (props) => {
    const history = useHistory();
    const size = useWindowSize();
    const { userprops } = useSelector(state => ({
        userprops: state.user.userData
    }));
    
    const dispatch = useDispatch();
    const [height] = useState(size.height);
    const [publicnav] = useState(path.mainpath);
    const [stocknav] = useState(path.stockpath);
    const [portfolionav] = useState(path.portfoliopath);
    const [medianav] = useState(path.mediapath);
    const [productnav] = useState(path.productpath);
    const [adminnav] = useState(path.adminpath);

    const [portopen, portopenHandler] = useState(false);
    const [stockopen, stockopenHandler] = useState(false);

    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();
        const statusCheck = async () => {
            try {
                if (mounted) {
                    if (
                        history.location.pathname.includes('/admin/portfolio') ||
                        history.location.pathname.includes('/admin/category')
                    ) {
                        portopenHandler(true);
                    } else if (
                        history.location.pathname.includes('/admin/stock') ||
                        history.location.pathname.includes('/admin/vendor') ||
                        history.location.pathname.includes('/admin/goods') ||
                        history.location.pathname.includes('/admin/material') ||
                        history.location.pathname.includes('/admin/converter') ||
                        history.location.pathname.includes('/admin/unit') ||
                        history.location.pathname.includes('/admin/formula')
                    ) {
                        stockopenHandler(true);
                    } else {
                        portopenHandler(false);
                        stockopenHandler(false);
                    }
                }
            } catch (error) {

            }
        }
        statusCheck();
        return () => {
            mounted = false;
            abortController.abort();
        }
    }, []);

    const openClosed = () => {
        if (portopen) {
            dispatch(portopenaction(false));
            portopenHandler(false);
        } else {
            dispatch(portopenaction(true));
            portopenHandler(true);
        }
    }

    const openClosedStock = () => {
        if (stockopen) {
            dispatch(stockstatusaction(false));
            stockopenHandler(false);
        } else {
            dispatch(stockstatusaction(true));
            stockopenHandler(true);
        }
    }

    const publicLink = (items) => (
        userprops ?
            items.map((item, i) => {
                if (item.show) {
                    return (
                        <li key={i} className="menuItem">
                            <NavLink
                                to={item.link}
                                className="linksEach menuLink"
                                activeClassName="active"
                            >
                                <div
                                    className="iconcontainer"
                                >
                                    <FontAwesomeIcon
                                        icon={item.faicons}
                                        className="icon agraicon"
                                    />
                                </div>
                                {item.name}
                            </NavLink>
                        </li>
                    )
                } else {
                    return null;
                }
            })
            : null

    )

    const templateLink = (items) => (
        userprops ?
            items.map((item, i) => {
                if (item.show) {
                    return (
                        <li key={i} className="menuItem">
                            <NavLink
                                to={item.link}
                                className="linksEach menuLink"
                                activeClassName="active"
                            >
                                <div
                                    className="iconcontainer"
                                >
                                    <FontAwesomeIcon
                                        icon={item.faicons}
                                        className="icon agraicon"
                                    />
                                </div>
                                {item.name}
                            </NavLink>
                        </li>
                    )
                } else {
                    return null;
                }
            })
            : null
    )

    const dropdownLink = (items) => (
        userprops ?
            <ul className={portopen ? "customdropdown open" : "customdropdown"}>
                <li
                    className="menuItem"
                    onClick={() => openClosed()}
                >
                    <div
                        // to={item.link}
                        className={portopen ? "linksEach menuLink singledropdown active" : "linksEach menuLink singledropdown"}
                    >
                        <div
                            className="iconcontainer"
                        >
                            <FontAwesomeIcon
                                icon={faLayerGroup}
                                className="icon agraicon"
                            />
                        </div>
                        <div className="customdropdowniconright">
                            {items[0].name}
                            <span>
                                {
                                    portopen ?
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="icon"
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className="icon"
                                        />
                                }
                            </span>
                        </div>
                    </div>
                </li>
                {
                    portopen ?
                        items.map((item, i) => {
                            if (item.show) {
                                return (
                                    <li key={i} className="menuItem">
                                        <NavLink
                                            to={item.link}
                                            className="linksEach menuLink"
                                            activeClassName="active"
                                        >
                                            <div
                                                className="iconcontainer"
                                            >
                                                <FontAwesomeIcon
                                                    icon={item.faicons}
                                                    className="icon agraicon"
                                                />
                                            </div>
                                            {item.name}
                                        </NavLink>
                                    </li>
                                )
                            } else {
                                return null;
                            }
                        })
                        : null
                }
            </ul>
            : null
    )

    const statusdropdownLink = (items) => (
        userprops ?
            <ul className={stockopen ? "customdropdown open" : "customdropdown"}>
                <li
                    className="menuItem"
                    onClick={() => openClosedStock()}
                >
                    <div
                        // to={item.link}
                        className={stockopen ? "linksEach menuLink singledropdown active" : "linksEach menuLink singledropdown"}
                    >
                        <div
                            className="iconcontainer"
                        >
                            <FontAwesomeIcon
                                icon={faLayerGroup}
                                className="icon agraicon"
                            />
                        </div>
                        <div className="customdropdowniconright">
                            {items[0].name}
                            <span>
                                {
                                    stockopen ?
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="icon"
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            className="icon"
                                        />
                                }
                            </span>
                        </div>
                    </div>
                </li>
                {
                    stockopen ?
                        items.map((item, i) => {
                            if (item.show) {
                                return (
                                    <li key={i} className="menuItem">
                                        <NavLink
                                            to={item.link}
                                            className="linksEach menuLink"
                                            activeClassName="active"
                                        >
                                            <div
                                                className="iconcontainer"
                                            >
                                                <FontAwesomeIcon
                                                    icon={item.faicons}
                                                    className="icon agraicon"
                                                />
                                            </div>
                                            {item.name}
                                        </NavLink>
                                    </li>
                                )
                            } else {
                                return null;
                            }
                        })
                        : null
                }
            </ul>
            : null
    )

    const adminLink = (items) => (
        userprops ?
            items.map((item, i) => (
                <li key={i} className="menuItem">
                    <NavLink
                        to={item.link}
                        className="linksEach menuLink"
                        activeClassName="active"
                    >
                        <div
                            className="iconcontainer"
                        >
                            <FontAwesomeIcon
                                icon={item.faicons}
                                className="icon agraicon"
                            />
                        </div>
                        {item.name}
                    </NavLink>
                </li>
            ))
            : null
    )

    const fixedHeight = height - 56;
    return (
        <div className="pageWrapper">
            <div className="memberpageLeftNav">
                <nav className="sidebarNavigation agContainer">
                    <ul className="menu">
                        <li className="navigationHeader" style={{ backgroundColor: '#222' }}>Admin version</li>
                        {publicLink(publicnav)}
                        <li className="navigationHeader">Media Area</li>
                        {dropdownLink(portfolionav)}
                        {templateLink(medianav)}
                        <li className="navigationHeader">Ecommerce Area</li>
                        {statusdropdownLink(stocknav)}
                        {templateLink(productnav)}
                        {
                            userprops ?
                                userprops.masteradmin > 0 ?
                                    <li className="navigationHeader">Masteradmin Area</li>
                                    : null
                                : null
                        }
                        {
                            userprops ?
                                userprops.masteradmin > 0 ?
                                    adminLink(adminnav)
                                    : null
                                : null
                        }
                        <li className="version">{path.version}</li>
                    </ul>
                </nav>
            </div>
            <div className="masterContainer" style={{
                minHeight: fixedHeight
            }}>
                {props.children}
            </div>
        </div>
    );

};

export default AdminLayout;