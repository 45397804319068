import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import AdminLayout from '../../../views/layout/adminpage';
import PageHeader from '../../../../widget/pageheader';
import TableMedia from '../../../../widget/tablemedia';
import ThisTable from './thisTable';

import { useWindowSize } from '../../../../widget/windowsize';
import { getEmaterialstocks } from '../../../../../store/actions/ematerialstocks_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faRulerCombined, faRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake)

const Ematerialstocks = (props) => {
    const { userprops, getematerialstocks } = useSelector(state => ({
        userprops: state.user,
        getematerialstocks: state.ematerialstocks
    }));

    const size = useWindowSize();
    const isMobile = size.width <= 767.98;
    const dispatch = useDispatch();

    const [pagename] = useState('Material List');
    const [breadcrumb] = useState([
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            public: true,
            faicons: faHome
        },
        {
            name: 'Stock',
            linkTo: '/admin/stock',
            public: true
        },
        {
            name: 'Goods',
            linkTo: '/admin/goods',
            public: true
        },
        {
            name: 'Material',
            linkTo: `${props.location.pathname}`,
            public: true
        },
        {
            name: pagename,
            linkTo: '',
            public: true
        }
    ]);

    /////////////////////////////////////////////////////////////
    const [addnewEmaterialstocks] = useState(true);
    const [refreshEmaterialstocks] = useState(true);
    const [uploadfileEmaterialstocks] = useState(false);
    const [downloadfileEmaterialstocks] = useState(false);
    const [pagetitletable] = useState(true);
    const [gobackbutton] = useState(true);

    const [showeditEmaterialstocks, showeditHandlerEmaterialstocks] = useState(false);
    const [selectedEmaterialstocks, selectedHandlerEmaterialstocks] = useState({});
    const [showsettingmenuEmaterialstocks, showsettingmenuHandlerEmaterialstocks] = useState(false);

    const [loadingtableEmaterialstocks, loadingtableEmaterialstocksHandler] = useState(false);
    const [currentPageEmaterialstocks, currentPageEmaterialstocksHandler] = useState(1);
    const [pageSizeEmaterialstocks] = useState(5);

    const [dontblurEmaterialstocks, dontblurEmaterialstocksHandler] = useState(false);
    const [tablenameEmaterialstocks] = useState('Material stocks');
    const [tablemenuEmaterialstocks] = useState([
        {
            head: 'Image'
        },
        {
            head: 'Name'
        },
        {
            head: 'Material'
        },
    ]);
    const [allematerialstocks, allematerialstocksHandler] = useState([]);

    const [filterEmaterialstocks, filterEmaterialstocksHandler] = useState("");
    const [dataEmaterialstocks, dataEmaterialstocksHandler] = useState("");
    const [myEmaterialstocks, myEmaterialstocksHandler] = useState([]);
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    useEffect(() => {
        loadingtableEmaterialstocksHandler(true);
        dispatch(getEmaterialstocks());
    }, [dispatch]);

    useEffect(() => {
        if (getematerialstocks && getematerialstocks.getEmaterialstocks && getematerialstocks.getEmaterialstocks.success) {
            let totalematerialstocks = getematerialstocks.getEmaterialstocks.ematerialstocks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allematerialstocksHandler(totalematerialstocks);
        }
    }, [getematerialstocks]);
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    useEffect(() => {
        if (allematerialstocks && allematerialstocks.length > 0) {
            var myEmaterialstocks = [];
            var resultsEmaterialstocks = [];
            var offsetEmaterialstocks = (currentPageEmaterialstocks - 1) * pageSizeEmaterialstocks;
            resultsEmaterialstocks = allematerialstocks.map((item, index) => <ThisTable key={index} item={item} index={index} />)
            myEmaterialstocks = resultsEmaterialstocks.slice(offsetEmaterialstocks, offsetEmaterialstocks + pageSizeEmaterialstocks);
            myEmaterialstocksHandler(myEmaterialstocks);
            dataEmaterialstocksHandler(resultsEmaterialstocks.length);
            loadingtableEmaterialstocksHandler(false);
        } else {
            setTimeout(() => {
                loadingtableEmaterialstocksHandler(false);
            }, 1000)
        }
    }, [allematerialstocks, currentPageEmaterialstocks, pageSizeEmaterialstocks])
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    useEffect(() => {
        var resultsEmaterialstocks = [];
        var resultsdataEmaterialstocks = [];
        var offsetEmaterialstocks = (currentPageEmaterialstocks - 1) * pageSizeEmaterialstocks;
        if (allematerialstocks) {
            resultsEmaterialstocks = allematerialstocks.filter(dataEmaterialstocks =>
                (dataEmaterialstocks.name.toLowerCase().indexOf(filterEmaterialstocks.toLowerCase()) !== -1
                    || dataEmaterialstocks.code.toLowerCase().indexOf(filterEmaterialstocks.toLowerCase()) !== -1))
            resultsdataEmaterialstocks = resultsEmaterialstocks.map((item, index) => <ThisTable item={item} index={index} key={index} editData={editDataEmaterialstocks} />)
            var semuadataEmaterialstocks = [...resultsdataEmaterialstocks];
            var mydatasEmaterialstocks = semuadataEmaterialstocks.slice(offsetEmaterialstocks, offsetEmaterialstocks + pageSizeEmaterialstocks);
            myEmaterialstocksHandler(mydatasEmaterialstocks);
        }

    }, [filterEmaterialstocks, currentPageEmaterialstocks, allematerialstocks, pageSizeEmaterialstocks])
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    const handleChangeEmaterialstocks = (event) => {
        filterEmaterialstocksHandler(event.target.value);
        currentPageEmaterialstocksHandler(1);
    }

    const clickHandlerEmaterialstocks = (link) => {
        props.history.push(link);
    }

    const showMenuHandlerEmaterialstocks = () => {
        showsettingmenuHandlerEmaterialstocks(!showsettingmenuEmaterialstocks);
    }

    const hideMenuHandlerEmaterialstocks = () => {
        showsettingmenuHandlerEmaterialstocks(false);
    }

    const onMouseEnterEmaterialstocks = () => {
        // console.log("onMouseEnter")
        dontblurEmaterialstocksHandler(true)
    }

    const onMouseLeaveEmaterialstocks = () => {
        // console.log("onMouseLeave")
        dontblurEmaterialstocksHandler(false)
    }

    const addNewHandlerEmaterialstocks = () => {
        // console.log("add new handler")
        props.history.push(`${props.location.pathname}/addnew`);
    }

    const onRefreshHandlerEmaterialstocks = async () => {
        try {
            loadingtableEmaterialstocksHandler(true);
            let alldatas = await dispatch(getEmaterialstocks());
            let totaldataEmaterialstocks = await alldatas.payload.ematerialstocks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            var mydataEmaterialstocks = [];
            var resultsdataEmaterialstocks = [];
            var offsetEmaterialstocks = (currentPageEmaterialstocks - 1) * pageSizeEmaterialstocks;
            allematerialstocksHandler(totaldataEmaterialstocks);
            resultsdataEmaterialstocks = totaldataEmaterialstocks.map((item, index) => <ThisTable key={index} item={item} index={index} editData={editDataEmaterialstocks} />)
            mydataEmaterialstocks = resultsdataEmaterialstocks.slice(offsetEmaterialstocks, offsetEmaterialstocks + pageSizeEmaterialstocks);
            myEmaterialstocksHandler(mydataEmaterialstocks);
            dataEmaterialstocksHandler(resultsdataEmaterialstocks.length);
            loadingtableEmaterialstocksHandler(false);
        } catch (error) {

        }
    }
    // console.log(dontblur, "<<<<<<<<")

    const pageChangedEmaterialstocks = (pageNumber, e) => {
        e.preventDefault();
        currentPageEmaterialstocksHandler(pageNumber)
    }

    const editDataEmaterialstocks = (data) => {
        // console.log(data, "<<<<<<<<<<<<data data dtat")
        showeditHandlerEmaterialstocks(true);
        selectedHandlerEmaterialstocks(data);
    }

    const backdropClickHandlerEmaterialstocks = () => {
        document.body.style.overflow = 'overlay';
        showeditHandlerEmaterialstocks(false)
        selectedHandlerEmaterialstocks({});
    }

    const editDataHandlerEmaterialstocks = () => {
        props.history.push({
            pathname: `${props.location.pathname}/editdata/${selectedEmaterialstocks._id}`,
            state: {
                dataselected: selectedEmaterialstocks
            }
        })
    }

    const deleteDataHandlerEmaterialstocks = () => {
        console.log("deletedata")
    }

    const goBackToFront = () => {
        props.history.push('/admin/goods');
    }
    /////////////////////////////////////////////////////////////

    return (
        <AdminLayout>
            <div className="masterDashboard">
                <div className="masterDashboardContainer">
                    <div className="containerfluid tableloading">
                        <div className="pagetitlebox">
                            <PageHeader
                                {...props}
                                pagename={pagename}
                                breadcrumb={breadcrumb}
                                clickHandler={clickHandlerEmaterialstocks}
                            />
                        </div>
                        {
                            loadingtableEmaterialstocks ? null :
                                <div className="row pb15 mb15 catbutton">
                                    <div className={isMobile ? "col-md-3 col-xs-12 p0 mb15" : "col-md-3 col-xs-12 pl0 mb15"}>
                                        <div className="dashboardlink">
                                            <NavLink
                                                to={'/admin/unit'}
                                                activeClassName="active"
                                                exact
                                            >
                                                <div className="bgpattern card">
                                                    <div className="cardBody cardBodyTable" style={{ paddingBottom: "1.2rem" }}>
                                                        <div className="ministate">
                                                            <h6 className="ministatetitle">Unit</h6>
                                                            <FontAwesomeIcon
                                                                icon={faRuler}
                                                                className="icon agraicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={isMobile ? "col-md-3 col-xs-12 p0 mb15" : "col-md-3 col-xs-12 pr0 mb15"}>
                                        <div className="dashboardlink">
                                            <NavLink
                                                to={'/admin/converter'}
                                                activeClassName="active"
                                                exact
                                            >
                                                <div className="bgpattern card">
                                                    <div className="cardBody cardBodyTable" style={{ paddingBottom: "1.2rem" }}>
                                                        <div className="ministate">
                                                            <h6 className="ministatetitle">Converter</h6>
                                                            <FontAwesomeIcon
                                                                icon={faRulerCombined}
                                                                className="icon agraicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className="row">
                            <div className="col-md-12 col-xs-12 p0">
                                <TableMedia
                                    pagetitletable={pagetitletable}
                                    gobackbutton={gobackbutton}
                                    addnew={addnewEmaterialstocks}
                                    refresh={refreshEmaterialstocks}
                                    uploadfile={uploadfileEmaterialstocks}
                                    downloadfile={downloadfileEmaterialstocks}
                                    showsettingmenu={showsettingmenuEmaterialstocks}
                                    dontblur={dontblurEmaterialstocks}
                                    onMouseEnter={onMouseEnterEmaterialstocks}
                                    onMouseLeave={onMouseLeaveEmaterialstocks}
                                    addNewHandler={addNewHandlerEmaterialstocks}
                                    hideMenuHandler={hideMenuHandlerEmaterialstocks}
                                    showMenuHandler={showMenuHandlerEmaterialstocks}
                                    onRefreshHandler={onRefreshHandlerEmaterialstocks}
                                    userprops={userprops}
                                    isMobile={isMobile}
                                    loadingtable={loadingtableEmaterialstocks}
                                    filterText={filterEmaterialstocks}
                                    handleChange={handleChangeEmaterialstocks}
                                    currentPage={currentPageEmaterialstocks}
                                    dataCount={dataEmaterialstocks}
                                    pageSize={pageSizeEmaterialstocks}
                                    tablename={tablenameEmaterialstocks}
                                    tablemenu={tablemenuEmaterialstocks}
                                    mydata={myEmaterialstocks}
                                    pageChanged={pageChangedEmaterialstocks}
                                    showedit={showeditEmaterialstocks}
                                    editDataHandler={editDataHandlerEmaterialstocks}
                                    deleteDataHandler={deleteDataHandlerEmaterialstocks}
                                    backdropClickHandler={backdropClickHandlerEmaterialstocks}
                                    goBackToFront={goBackToFront}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Ematerialstocks;