import axios from 'axios';
import {
    ADD_ESLIDER,
    CLEAR_ESLIDER,
    GET_ESLIDER,
    GET_ESLIDER_BY_ID,
    UPDATE_ESLIDER,
    CLEAR_UPDATE_ESLIDER
} from '../types';
import { ESLIDER_SERVER } from '../misc';

export function addSlider(dataToSubmit) {
    const formData = new FormData();
    for (const key in dataToSubmit) {
        if ((dataToSubmit[key])) {
            if (key === 'images') {
                dataToSubmit[key].forEach((img, i) => {
                    formData.append(`image${i}`, img);
                });
            } else {
                formData.append(key, dataToSubmit[key]);
            }
        }
    }

    const request = axios.post(`${ESLIDER_SERVER}/addslider`, formData)
        .then(response => response.data);

    return {
        type: ADD_ESLIDER,
        payload: request
    }
}

export function clearSlider() {
    return {
        type: CLEAR_ESLIDER,
        payload: ''
    }
}

export function updateSlider(dataToSubmit, id) {
    const formData = new FormData();
    for (const key in dataToSubmit) {
        if ((dataToSubmit[key])) {
            if (key === 'imagesToAdd') {
                dataToSubmit[key].forEach((img, i) => {
                    formData.append(`image${i}`, img);
                });
            } else {
                formData.append(key, dataToSubmit[key]);
            }
        }
    }

    const request = axios.post(`${ESLIDER_SERVER}/updateslider?id=${id}`, formData)
        .then(response => response.data);

    return {
        type: UPDATE_ESLIDER,
        payload: request
    }
}

export function clearUpdateSlider() {
    return {
        type: CLEAR_UPDATE_ESLIDER,
        payload: ''
    }
}

export function getESlider() {
    const request = axios.get(`${ESLIDER_SERVER}/getslider`)
        .then(response => response.data);

    return {
        type: GET_ESLIDER,
        payload: request
    }
}

export function getSliderByid(id){
    const request = axios.get(`${ESLIDER_SERVER}/getsliderbyid?id=${id}&type=single`)
    .then(response => response.data)

    return {
        type: GET_ESLIDER_BY_ID,
        payload: request
    }
}