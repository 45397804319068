import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminLayout from '../../../views/layout/adminpage';
import PageHeader from '../../../../widget/pageheader';
import TableMedia from '../../../../widget/tablemedia';
import ThisTable from './thisTable';

import { useWindowSize } from '../../../../widget/windowsize';
import { getEUnit } from '../../../../../store/actions/eunit_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake)

const EUnitTable = (props) => {
    const { userprops, getalleunit } = useSelector(state => ({
        userprops: state.user,
        getalleunit: state.eunit
    }));

    const size = useWindowSize();
    const isMobile = size.width <= 767.98;
    const dispatch = useDispatch();

    const [pagename] = useState('Unit List');
    const [breadcrumb] = useState([
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            public: true,
            faicons: faHome
        },
        {
            name: 'Stock',
            linkTo: '/admin/stock',
            public: true
        },
        {
            name: 'Goods',
            linkTo: '/admin/goods',
            public: true
        },
        {
            name: 'Material',
            linkTo: '/admin/material',
            public: true
        },
        {
            name: 'Unit',
            linkTo: `${props.location.pathname}`,
            public: true
        },
        {
            name: pagename,
            linkTo: '',
            public: true
        }
    ]);

    /////////////////////////////////////////////////////////////
    const [addnew] = useState(true);
    const [refresh] = useState(true);
    const [uploadfile] = useState(false);
    const [downloadfile] = useState(false);
    const [pagetitletable] = useState(true);
    const [gobackbutton] = useState(true);

    const [showedit, showeditHandler] = useState(false);
    const [selected, selectedHandler] = useState({});
    const [showsettingmenu, showsettingmenuHandler] = useState(false);

    const [loadingtable, loadingtableHandler] = useState(false);
    const [currentPage, currentPageHandler] = useState(1);
    const [pageSize] = useState(10);

    const [dontblur, dontblurHandler] = useState(false);

    const [tablename] = useState('Unit');
    const [tablemenu] = useState([
        {
            head: 'Name'
        },
        {
            head: 'Short Name'
        }
    ]);
    const [alldata, alldataHandler] = useState([]);

    const [filterText, filterTextHandler] = useState("");
    const [dataCount, dataCountHandler] = useState("");
    const [mydata, mydataHandler] = useState([]);
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    useEffect(() => {
        loadingtableHandler(true);
        dispatch(getEUnit());
    }, [dispatch]);

    useEffect(() => {
        if (getalleunit && getalleunit.getEunit && getalleunit.getEunit.success) {
            let totaleunit = getalleunit.getEunit.eunits.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            alldataHandler(totaleunit);
        }

    }, [getalleunit]);
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    useEffect(() => {
        if (alldata && alldata.length > 0) {
            var mydata = [];
            var resultsdata = [];
            var offset = (currentPage - 1) * pageSize;
            resultsdata = alldata.map((item, index) => <ThisTable key={index} item={item} index={index} />)
            mydata = resultsdata.slice(offset, offset + pageSize);
            mydataHandler(mydata);
            dataCountHandler(resultsdata.length);
            loadingtableHandler(false);
        } else {
            setTimeout(() => {
                loadingtableHandler(false);
            }, 1000)
        }
    }, [alldata, currentPage, pageSize])
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    useEffect(() => {
        var results = [];
        var resultsdata = [];
        var offset = (currentPage - 1) * pageSize;
        if (alldata) {
            results = alldata.filter(data =>
                (data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                    || data.shortname.toLowerCase().indexOf(filterText.toLowerCase()) !== -1))
            resultsdata = results.map((item, index) => <ThisTable item={item} index={index} key={index} editData={editData} />)
            var semuadata = [...resultsdata];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            mydataHandler(mydatas);
        }

    }, [filterText, currentPage, alldata, pageSize])
    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1)
    }

    const clickHandler = (link) => {
        props.history.push(link);
    }

    const showMenuHandler = () => {
        showsettingmenuHandler(!showsettingmenu);
    }

    const hideMenuHandler = () => {
        showsettingmenuHandler(false);
    }

    const onMouseEnter = () => {
        // console.log("onMouseEnter")
        dontblurHandler(true)
    }

    const onMouseLeave = () => {
        // console.log("onMouseLeave")
        dontblurHandler(false)
    }

    const addNewHandler = () => {
        // console.log("add new handler")
        props.history.push(`${props.location.pathname}/addnew`);
    }

    const onRefreshHandler = async () => {
        try {
            loadingtableHandler(true);
            let alldatas = await dispatch(getEUnit());
            let totaldata = await alldatas.payload.eunits.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            var mydata = [];
            var resultsdata = [];
            var offset = (currentPage - 1) * pageSize;
            alldataHandler(totaldata);
            resultsdata = totaldata.map((item, index) => <ThisTable key={index} item={item} index={index} editData={editData} />)
            mydata = resultsdata.slice(offset, offset + pageSize);
            mydataHandler(mydata);
            dataCountHandler(resultsdata.length);
            loadingtableHandler(false);
        } catch (error) {

        }
    }
    // console.log(dontblur, "<<<<<<<<")

    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber)
    }

    const editData = (data) => {
        // console.log(data, "<<<<<<<<<<<<data data dtat")
        showeditHandler(true);
        selectedHandler(data);
    }

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        showeditHandler(false)
        selectedHandler({});
    }

    const editDataHandler = () => {
        props.history.push({
            pathname: `${props.location.pathname}/editdata/${selected._id}`,
            state: {
                dataselected: selected
            }
        })
    }

    const deleteDataHandler = () => {
        console.log("deletedata")
    }
    /////////////////////////////////////////////////////////////

    const goBackToFront = () => {
        props.history.push('/admin/material');
    }

    return (
        <AdminLayout>
            <div className="masterDashboard">
                <div className="masterDashboardContainer">
                    <div className="containerfluid tableloading">
                        <div className="pagetitlebox">
                            <PageHeader
                                {...props}
                                pagename={pagename}
                                breadcrumb={breadcrumb}
                                clickHandler={clickHandler}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12 p0">
                                <TableMedia
                                    pagetitletable={pagetitletable}
                                    gobackbutton={gobackbutton}
                                    addnew={addnew}
                                    refresh={refresh}
                                    uploadfile={uploadfile}
                                    downloadfile={downloadfile}
                                    showsettingmenu={showsettingmenu}
                                    dontblur={dontblur}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    addNewHandler={addNewHandler}
                                    hideMenuHandler={hideMenuHandler}
                                    showMenuHandler={showMenuHandler}
                                    onRefreshHandler={onRefreshHandler}
                                    userprops={userprops}
                                    isMobile={isMobile}
                                    loadingtable={loadingtable}
                                    filterText={filterText}
                                    handleChange={handleChange}
                                    currentPage={currentPage}
                                    dataCount={dataCount}
                                    pageSize={pageSize}
                                    tablename={tablename}
                                    tablemenu={tablemenu}
                                    mydata={mydata}
                                    pageChanged={pageChanged}
                                    showedit={showedit}
                                    editDataHandler={editDataHandler}
                                    deleteDataHandler={deleteDataHandler}
                                    backdropClickHandler={backdropClickHandler}
                                    goBackToFront={goBackToFront}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default EUnitTable;