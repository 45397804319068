import React, { useState } from 'react';
import {
    useSelector
    // useDispatch
} from 'react-redux';
import { Element, scroller } from 'react-scroll';
import { useWindowSize } from '../../widget/windowsize';
import Carousel from './carousel';
import Fade from 'react-reveal/Fade';
import { FaHandshake, FaHome, FaLayerGroup, FaUniversity, FaWallet, FaWrench } from "react-icons/fa";
import { useHistory } from 'react-router-dom';

const HomePage = (props) => {
    const { allgallery } = useSelector(state => ({
        allgallery: state.gallery.getGallery && state.gallery.getGallery.galleries
    }));
    const size = useWindowSize();
    const history = useHistory();
    const isMobile = size.width <= 767.98;
    const isTablet = size.width >= 767.99 && size.width <= 835.98;
    const biggerTablet = size.width >= 1024 && size.width <= 1279.98;

    const [loading, loadingHandler] = useState(false);
    const [alldata, alldataHandler] = useState([]);

    const scrollToElement = (element) => {
        scroller.scrollTo(element, {
            duration: 1000,
            delay: 100,
            offset: -90,
            smooth: true
        });
    }

    // useEffect(() => {
    //     let mounted = true;
    //     const abortController = new AbortController();

    //     const getAllData = async () => {
    //         try {
    //             loadingsingleHandler(true);
    //             let portfoliofilter = [];
    //             const id = await params.id;
    //             let alldatas = await dispatch(getPortfolioByid(id), { signal: abortController.signal });
    //             let portfolio = await dispatch(getPortfolio(), { signal: abortController.signal });
    //             let allportfolios = await portfolio.payload.portfolios.filter(data => data.category.name !== 'Apps');
    //             allportfolios.forEach((item, index) => {
    //                 if (alldatas.payload._id !== item._id) {
    //                     portfoliofilter.push(item);
    //                 }
    //             })
    //             if (mounted) {
    //                 selectedProductHandler(alldatas.payload.docs[0]);
    //                 allportfolioHandler(portfoliofilter);
    //                 loadingsingleHandler(false);
    //             }

    //         } catch (error) {

    //         }
    //     }
    //     getAllData();
    //     return () => {
    //         mounted = false;
    //         abortController.abort();
    //     }
    // }, [dispatch, params.id])

    return (
        <section id="homepage" style={{ marginBottom: 0, transform: "none" }}>
            <div className="contentwrap" style={{ transform: "none" }}>
                <Element name="home">
                    <div id="frontendslider" style={{ position: 'relative' }}>
                        <Carousel isMobile={isMobile} isTablet={isTablet} />
                        <div className={isMobile ? "hometitlemobile" : "hometitle"}>
                            <Fade bottom delay={isMobile ? 300 : 500}>
                                <h1 className={isMobile ? "big_whitemobile" : "big_white"}><span className="element">Find </span>
                                    the right home
                                </h1>
                                <div className="hero-copy">Scroll down for more information</div>
                                {/* 
                                    <span className="btn-holder">
                                        <a href="#products" className="hero-btn lm-button">See Our Products</a>
                                    </span> 
                                */}
                            </Fade>
                        </div>
                    </div>
                </Element>
                <Element name="about">
                    <div className="section" style={{ marginBottom: isMobile ? '0px' : '0px', paddingBottom: isMobile ? '0px' : '60px', marginTop: isMobile ? '0px' : '30px' }}>
                        <div className="container">
                            <div
                                className="headingblock center mb30"
                                style={{
                                    maxWidth: `${size.height - ((size.height * 20) / 100)}px`
                                }}
                            >
                                <h2>WHY CHOOSE US</h2>
                                <small>We offer best value in real estate services.</small>

                            </div>
                            <div className="row">
                                <div className="lumi-md-12 lumi-xs-12">
                                    <div
                                        className="mainhomeabout center"
                                        style={{
                                            maxWidth: `${size.height - ((size.height * 20) / 100)}px`
                                        }}
                                    >
                                        <p>
                                            PT. BINTANG PUTRA MANDIRI ("BPM GROUP") was founded in 2002 to undertake construction and development. starting the project at kemuning (Tigaraksa) and Citra (Cikupa) as contractor.
                                            <br /><br />From 2005 BPM GROUP started to develope RSS (RUMAH SANGAT SEDERHANA) residence at Sepatan Residence, Griya Sepatan, Griya Lebak Wangi 1, Griya Lebak Wangi 2, Pesona Lebak Wangi 1 and Pesona Lebak Wangi 2.
                                            <br /><br />In 2014, starting with 1 hectare of land in Sukatani Tangerang, BPM GROUP managed to build Griya Sukatani Residential, sold with 80+ units.
                                            <br /><br />Continuing giving the right home with best value, BPM GROUP build a new project at Tanjakan mekar rajeg called Rajeg Hill Residence.
                                            <br /><br />BPM GROUP will keep develope, build and delivering services with dedicated personnel, commitment and knowledge, as our mission is to be known as expertise to build subsidies residence.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt30">
                                <div className={isMobile ? "lumi-md-4 lumi-xs-12 mt30" : "lumi-md-4 lumi-xs-12"}>
                                    <Fade right delay={isMobile ? 100 : 200}>
                                        <div className="aboutcardwrapper" style={{ marginBottom: '50px' }}>
                                            <div className="aboutcard">
                                                <FaUniversity size={56} />
                                                <div className="aboutcardbody">
                                                    <h5>Construction </h5>
                                                Provides general construction, project management, estimating, with dedicated and skilled personnel to exceed expectations on every project.
                                        </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className={isMobile ? "lumi-md-4 lumi-xs-12 mt30" : "lumi-md-4 lumi-xs-12"}>
                                    <Fade right delay={isMobile ? 200 : 400}>
                                        <div className="aboutcardwrapper" style={{ marginBottom: '50px' }}>
                                            <div className="aboutcard">
                                                <FaWrench />
                                                <div className="aboutcardbody">
                                                    <h5>Renovation</h5>
                                            Brings to you the best value renovation service for your dream home, you can trust us to give your home a stunning renovation.
                                        </div>
                                            </div>
                                        </div>
                                    </Fade>

                                </div>
                                <div className={isMobile ? "lumi-md-4 lumi-xs-12 mt30" : "lumi-md-4 lumi-xs-12"}>
                                    <Fade right delay={isMobile ? 300 : 600}>
                                        <div className="aboutcardwrapper" style={{ marginBottom: '50px' }}>
                                            <div className="aboutcard">
                                                <FaLayerGroup size={50} />
                                                <div className="aboutcardbody">
                                                    <h5>Building materials</h5>
                                            Support every project and residence with our own building materials that will suit your taste.
                                        </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
                <Element name="property">
                    <div
                        className="section"
                        style=
                        {{
                            marginBottom: '0px',
                            marginTop: '30px',
                            paddingTop: '30px',
                            paddingBottom: '100px',
                            // background: `rgba(234, 234, 234, 1) url('/assets/images/rumah.jpg')`,
                            background: `url('/assets/images/rumah.png')`,
                            backgroundPosition: 'left center',
                            backgroundRepeat: 'no-repeat',
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            backgroundSize: 'cover'
                        }}>

                        <div className="container">
                            <div
                                className="headingblock center mb30"
                                style={{
                                    maxWidth: `${size.height - ((size.height * 20) / 100)}px`
                                }}
                            >
                                <h2>ADVANTAGES </h2>
                                <small>We offer best value in real estate services.</small>
                            </div>
                            <div className="row">
                                <div className={isMobile ? "lumi-md-4 lumi-xs-12 mt30" : "lumi-md-4 lumi-xs-12"}>
                                    <Fade right delay={isMobile ? 100 : 200}>
                                        <div className="aboutcardwrapper" style={{ marginBottom: '50px' }}>
                                            <div className="aboutcard">
                                                <FaHome size={56} />
                                                <div className="aboutcardbody">
                                                    <h5>Find your future home</h5>
                                                Helping you to find a new home by offering the best value in our residence.
                                            </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className={isMobile ? "lumi-md-4 lumi-xs-12 mt30" : "lumi-md-4 lumi-xs-12"}>
                                    <Fade right delay={isMobile ? 200 : 400}>
                                        <div className="aboutcardwrapper" style={{ marginBottom: '50px' }}>
                                            <div className="aboutcard">
                                                <FaHandshake />
                                                <div className="aboutcardbody">
                                                    <h5>Security</h5>
                                                Supporting residence with a protection from invasions of privacy, home invasions and other crimes, threats and disturbances.
                                        </div>
                                            </div>
                                        </div>
                                    </Fade>

                                </div>
                                <div className={isMobile ? "lumi-md-4 lumi-xs-12 mt30" : "lumi-md-4 lumi-xs-12"}>
                                    <Fade right delay={isMobile ? 300 : 600}>
                                        <div className="aboutcardwrapper" style={{ marginBottom: '50px' }}>
                                            <div className="aboutcard">
                                                <FaWallet size={50} />
                                                <div className="aboutcardbody">
                                                    <h5>KPR Support</h5>
                                                Collaborating with bank partners to support your financing to purchase your house.
                                        </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                            <Fade bottom delay={isMobile ? 200 : 400}>
                                <div className="row">
                                    <div className="lumi-md-3 lumi-xs-12 verticalmiddle">
                                        <div className="introwrapper">
                                            <h2>Residence</h2>
                                            <p>Please choose our list of residence</p>
                                            <button
                                                className="gallerybutton"
                                                onClick={() => scrollToElement('contact')}
                                            >
                                                <div className="buttonicon">
                                                    Contact us
                                        </div>
                                            </button>
                                        </div>

                                    </div>
                                    {
                                        allgallery && allgallery.length > 0 ?
                                            allgallery.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="lumi-md-3 lumi-xs-12"
                                                >
                                                    <div
                                                        className="gallerywrapper"
                                                        onClick={() => history.push(`/property/${item._id}`)}
                                                    >
                                                        <div className="gallerythumbnailwrapper">
                                                            <div
                                                                className="gallerythumbnail"
                                                                style=
                                                                {{
                                                                    height: '100%',
                                                                    // background: `rgba(234, 234, 234, 1) url('/assets/images/rumah.jpg')`,
                                                                    // background: `url('/assets/images/rumah.png')`,
                                                                    background: `url(${item.images && item.images.length > 0 ? item.images[0].url : "/assets/images/thumbnailgallery.jpg"})`,
                                                                    backgroundPosition: 'center center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    overflow: 'hidden',
                                                                    backgroundSize: 'cover'
                                                                }}></div>
                                                        </div>

                                                        {/* <div className="gallerythumbnail"><img src={item.images && item.images.length > 0 ? item.images[0].url : "/assets/images/thumbnailgallery.jpg"} alt="" /></div> */}
                                                        <div className="galleryname">{item.name}</div>
                                                        <div className="galleryaddress">{item.location}</div>
                                                        <div className="gallerytext">{item.company}</div>
                                                    </div>
                                                </div>
                                            ))
                                            : null
                                    }
                                </div>
                            </Fade>

                        </div>
                    </div>
                </Element>
            </div>
        </section >
    );
};

export default HomePage;