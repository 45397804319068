import React, {
    useState,
    useEffect
} from 'react';
import {
    // useSelector,
    useDispatch
} from 'react-redux';

import FormField from '../../utils/form/formfield';
import FileUpload from '../../utils/form/fileupload';
import { update, generateData, isFormValid } from '../../utils/form/formactions';
import {
    addGallery,
    clearGallery,
    updateGallery,
    clearUpdateGallery,
    getGallery
} from '../../../../store/actions/gallery_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt, faBuilding, faGlobe, faStoreAlt, faRulerCombined, faLayerGroup, faHouseUser, faImage, faBorderAll, faBorderStyle, faExclamationTriangle, faWater, faTint
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const GalleryTableScreen = (props) => {
    // const { getalldata } = useSelector(state => ({
    //     getalldata: state.user
    // }))
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');
    const [formdata, formdataHandler] = useState({
        name: {
            element: 'input',
            title: 'Title',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter title'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        subname: {
            element: 'input',
            title: 'Description',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter description'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        company: {
            element: 'input',
            title: 'Company',
            value: '',
            config: {
                name: 'companyInput',
                type: 'text',
                placeholder: 'Enter company'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        location: {
            element: 'input',
            title: 'Location',
            value: '',
            config: {
                name: 'locationInput',
                type: 'text',
                placeholder: 'Enter location'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        latitude: {
            element: 'input',
            title: 'Latitude',
            value: '',
            config: {
                name: 'latitudeInput',
                type: 'text',
                placeholder: 'Enter latitude'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        longitude: {
            element: 'input',
            title: 'Longitude',
            value: '',
            config: {
                name: 'longitudeInput',
                type: 'text',
                placeholder: 'Enter longitude'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        pondation: {
            element: 'input',
            title: 'Pondation',
            value: '',
            config: {
                name: 'pondationInput',
                type: 'text',
                placeholder: 'Enter pondation'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        structure: {
            element: 'input',
            title: 'Structure',
            value: '',
            config: {
                name: 'structureInput',
                type: 'text',
                placeholder: 'Enter structure'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        wall: {
            element: 'input',
            title: 'Wall',
            value: '',
            config: {
                name: 'wallInput',
                type: 'text',
                placeholder: 'Enter wall'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        floor: {
            element: 'input',
            title: 'Floor',
            value: '',
            config: {
                name: 'floorInput',
                type: 'text',
                placeholder: 'Enter floor'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        roof: {
            element: 'input',
            title: 'Roof',
            value: '',
            config: {
                name: 'roofInput',
                type: 'text',
                placeholder: 'Enter roof'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        jamb: {
            element: 'input',
            title: 'Jamb',
            value: '',
            config: {
                name: 'jambInput',
                type: 'text',
                placeholder: 'Enter jamb'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        window: {
            element: 'input',
            title: 'Window',
            value: '',
            config: {
                name: 'windowInput',
                type: 'text',
                placeholder: 'Enter window'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        plafond: {
            element: 'input',
            title: 'Plafond',
            value: '',
            config: {
                name: 'plafondInput',
                type: 'text',
                placeholder: 'Enter plafond'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        electricity: {
            element: 'input',
            title: 'Electricity',
            value: '',
            config: {
                name: 'electricityInput',
                type: 'text',
                placeholder: 'Enter electricity'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        sanitation: {
            element: 'input',
            title: 'Sanitair',
            value: '',
            config: {
                name: 'sanitairInput',
                type: 'text',
                placeholder: 'Enter sanitair'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        // category: {
        //     element: 'select',
        //     title: 'Category',
        //     selectedValue: editformdatastatus ? dataselected.category.name.replace(/^\w/, c => c.toUpperCase()) : '',
        //     value: editformdatastatus ? dataselected.category._id : '',
        //     config: {
        //         name: 'categoryInput',
        //         options: [],
        //         placeholder: editformdatastatus ? dataselected.category.name.replace(/^\w/, c => c.toUpperCase()) : 'Choose category'
        //     },
        //     validation: {
        //         required: true
        //     },
        //     valid: editformdatastatus ? true : false,
        //     touched: editformdatastatus ? true : false,
        //     validationMessage: ''
        // },
        images: {
            element: 'input',
            title: 'Images',
            value: [],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });
    const [imageToUpload, imageToUploadHandler] = useState({
        images: {
            value: []
        }
    });
    const [imageToDelete, imageToDeleteHandler] = useState({
        images: {
            value: []
        }
    });
    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'gallery');
        formErrorHandler(false)
        formdataHandler(newFormdata)
    }
    useEffect(() => {
        if (editformdatastatus && dataselected) {
            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            for (let key in formdata) {
                const newElement = {
                    ...temporaryFormdata[key]
                }
                if (newElement.element === 'select') {
                    newElement.selectedValue = dataselected[key].name;
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
                if (newElement.element === 'input') {
                    newElement.value = dataselected[key];
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
            }
            formdataHandler(temporaryFormdata);
        }
    }, [editformdatastatus, dataselected])
    const previewFile = (files, multifiles, uploadname) => {
        formErrorHandler(false)
        const arrOfPromises = files.map((file, i) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    file.id = i;
                    file.url = e.target.result;
                    resolve(file);
                }
                reader.readAsDataURL(file);
            });

        });

        Promise.all(arrOfPromises).then(imagesToAdd => {
            let imagesToAddSort = imagesToAdd.sort((a, b) => {
                if (b.name > a.name)
                    return -1;
                if (b.name < a.name)
                    return 1;
                return 0;
            });

            if (editformdatastatus) {
                const newimageToUpload = { ...imageToUpload }
                const elementImageToUpload = {
                    ...newimageToUpload[uploadname]
                }
                for (let zxc = 0; zxc < imagesToAddSort.length; zxc++) {
                    elementImageToUpload.value.push(imagesToAddSort[zxc])
                }
                newimageToUpload[uploadname] = elementImageToUpload
                imageToUploadHandler(newimageToUpload);
            } else {
                var newFormdata = formdata;
                const temporaryFormdata = {
                    ...newFormdata
                }

                const newElement = {
                    ...temporaryFormdata[uploadname]
                }
                if (multifiles) {
                    for (let zxc = 0; zxc < imagesToAddSort.length; zxc++) {
                        newElement.value.push(imagesToAddSort[zxc])
                    }
                } else {
                    if (imagesToAddSort.length < 2) {
                        newElement.value = imagesToAddSort
                    } else {
                        formErrorHandler(true)
                        errorMessageHandler("Please upload one image only")
                    }
                }
                temporaryFormdata[uploadname] = newElement;
                formdataHandler(temporaryFormdata);
            }

        }).catch(err => console.log('err loading images', err));
    }
    const onRemove = (item, uploadname) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (editformdatastatus) {
            var newFormdata2 = imageToDelete;
            const temporaryFormdata2 = {
                ...newFormdata2
            }

            const newElement2 = {
                ...temporaryFormdata2[uploadname]
            }
            const updatedImages = newElement.value.filter(i => i.url !== item.url);
            newElement2.value.push(item.public_id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            temporaryFormdata2[uploadname] = newElement2;
            formdataHandler(temporaryFormdata);
            imageToDeleteHandler(temporaryFormdata2);
        } else {
            const updatedImages = newElement.value.filter(i => i.id !== item.id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            formdataHandler(temporaryFormdata);
        }
    }

    const showSoonToUpdateImages = (uploadname) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => onRemove(item, uploadname)}
                            >
                                <img src={item.url} alt="" />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    const showimagesWillbeRemove = (item, uploadname) => {
        var newFormdata = imageToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        const updatedImages = newElement.value.filter(i => i.id !== item.id);
        newElement.value = updatedImages;
        temporaryFormdata[uploadname] = newElement;
        imageToUploadHandler(temporaryFormdata);
    }

    const showimagesWillbeUpdate = (uploadname) => {
        var newFormdata = imageToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => showimagesWillbeRemove(item, uploadname)}
                            >
                                <img src={item.url} alt="" />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )

        }
    }

    const goBackToTable = () => {
        props.history.push('/admin/gallery');
    }

    const fetchingnewData = async (id) => {
        let selectedproduct = await dispatch(getGallery());
        let final = selectedproduct.payload.galleries.find(site => site._id === id);
        props.history.push({
            pathname: `/admin/gallery/editdata/${id}`,
            state: {
                dataselected: final,
                editformdata: true
            }
        })
    }

    const submitData = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formdata, 'gallery');
        let formIsValid = isFormValid(formdata, 'gallery');

        if (formIsValid) {
            dispatch(addGallery(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearGallery());
                    formSuccessHandler(true);
                    props.history.push('/admin/gallery');
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'galleryedit');
        let formIsValid2 = isFormValid(formdata, 'galleryedit');
        let dataselectedid = dataselected._id;
        // let datachange = 0;

        // for (let key1 in dataToSubmit2) {
        //     if (key1 === "images") {
        //         if (imageToUpload.images.value && imageToUpload.images.value.length > 0){

        //         } else {
        //             if (dataselected[key1] !== dataToSubmit2[key1]){
        //                 datachange = datachange + 1;
        //             }
        //         }
        //     } else {
        //         if (dataselected[key1] !== dataToSubmit2[key1]) {
        //             datachange = datachange + 1;
        //         }
        //     }
        // }
        // if (datachange > 0) {
        const totaldataToSubmit = {
            ...dataToSubmit2
        }
        if (formIsValid2) {
            totaldataToSubmit.imagesToRemove = imageToDelete.images.value;
            totaldataToSubmit.imagesToAdd = imageToUpload.images.value;
            dispatch(updateGallery(totaldataToSubmit, dataselectedid)).then(response => {
                if (response.payload.success) {
                    dispatch(clearUpdateGallery());
                    formSuccessHandler(true);
                    setTimeout(() => {
                        fetchingnewData(dataselectedid);
                    }, 1000);
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
        // } else {
        //     formErrorHandler(true);
        //     errorMessageHandler('CHANGE DATA BEFORE SUBMIT!');
        // }
    }
    return (
        <div className="cardBody formstyle">
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.name.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'name'}
                        formdata={formdata.name}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.subname.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faAlignLeft}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'subname'}
                        formdata={formdata.subname}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <hr />
            <div className="row pb25 pt20">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.company.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faBuilding}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'company'}
                        formdata={formdata.company}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.location.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faMapMarkedAlt}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'location'}
                        formdata={formdata.location}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.latitude.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faGlobe}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'latitude'}
                        formdata={formdata.latitude}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.longitude.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faGlobe}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'longitude'}
                        formdata={formdata.longitude}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <hr />
            <div className="row pb25 pt20">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.pondation.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faStoreAlt}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'pondation'}
                        formdata={formdata.pondation}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.structure.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faWeightHanging}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'structure'}
                        formdata={formdata.structure}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.wall.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faRulerCombined}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'wall'}
                        formdata={formdata.wall}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.floor.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faLayerGroup}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'floor'}
                        formdata={formdata.floor}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.roof.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faHouseUser}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'roof'}
                        formdata={formdata.roof}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.jamb.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faImage}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'jamb'}
                        formdata={formdata.jamb}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.window.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faBorderAll}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'window'}
                        formdata={formdata.window}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.plafond.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faBorderStyle}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'plafond'}
                        formdata={formdata.plafond}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.electricity.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'electricity'}
                        formdata={formdata.electricity}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.sanitation.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faTint}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'sanitation'}
                        formdata={formdata.sanitation}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-12 col-xs-12 colFormLabel">{formdata.images.title}</label>
                <div className="col-md-12 col-xs-12">
                    <FileUpload
                        id={'fileupload'}
                        reset={formSuccess}
                        myclass={'form-control'}
                        onFilesAlreadyAdded={previewFile}
                        multifiles={true}
                        uploadname={'images'}
                    />
                    {
                        showSoonToUpdateImages('images')
                    }
                    {
                        editformdatastatus ? showimagesWillbeUpdate('images') : null
                    }
                </div>
            </div>
            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                            </div>
                            : null
                }

                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    <div className="doubleButton">
                        <button
                            onClick={editformdatastatus ? (event) => submitEditData(event) : (event) => submitData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryTableScreen;