import React from 'react';
import Slider from 'react-slick';

const PropertyCarouselPage = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500
    }

    return (
        <div
            className="slidercontainer"
            style={
                {
                    overflow: "hidden"
                }
            }
        >
            {
                props.selected.images && props.selected.images.length > 0 ?
                    <Slider {...settings}>
                        {
                            props.selected.images && props.selected.images.length > 0 && props.selected.images.map((item, index) => {
                                return (
                                    <div className="carouselimage" key={index}>
                                        <div
                                            className="section"
                                            style=
                                            {{
                                                height: '360px',
                                                // background: `rgba(234, 234, 234, 1) url('/assets/images/rumah.jpg')`,
                                                background: `url(${item.url})`,
                                                backgroundPosition: 'center center',
                                                backgroundRepeat: 'no-repeat',
                                                overflow: 'hidden',
                                                backgroundSize: 'cover'
                                            }}></div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                    :
                    <img src={window.location.origin + "/assets/images/notavailablef.jpg"} alt="" />
            }
        </div>
    );
};

export default PropertyCarouselPage;