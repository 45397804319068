import React, {
    useState,
    useEffect
} from 'react';
import {
    useDispatch
} from 'react-redux';
import { useParams } from 'react-router-dom';

import AdminLayout from '../../../views/layout/adminpage';
import PageHeader from '../../../../widget/pageheader';
import Table from './table';

import { getVendorsByid } from '../../../../../store/actions/vendor_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake)


const VendorEditScreen = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const [pagename] = useState('Edit Vendor');
    const [loadingtable, loadingtableHandler] = useState(false);
    const [addnew] = useState(false);
    const [refresh] = useState(false);
    const [uploadfile] = useState(false);
    const [downloadfile] = useState(false);
    const [subname] = useState(true);
    const [buttonlink] = useState(true);
    const [buttondestination] = useState(true);
    const [breadcrumb] = useState([
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            public: true,
            faicons: faHome
        },
        {
            name: 'Stock',
            linkTo: '/admin/stock',
            public: true
        },
        {
            name: 'Vendor',
            linkTo: `${props.location.pathname}`,
            public: true
        },
        {
            name: pagename,
            linkTo: '',
            public: true
        }
    ]);

    const [dataselected, dataselectedHandler] = useState();
    const [editformdatastatus] = useState(true);

    const clickHandler = (link) => {
        props.history.push(link);
    }

    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();
        const DataselectedCheck = async () => {
            try {
                const id = await params.id;
                let selectedata = await dispatch(getVendorsByid(id), { signal: abortController.signal });
                if (mounted && selectedata.payload.success) {
                    dataselectedHandler(selectedata.payload.docs[0]);
                }
            } catch (error) {

            }
        }
        DataselectedCheck();
        return () => {
            mounted = false;
            abortController.abort();
        }
    }, [dispatch]);

    return (
        <AdminLayout>
            <div className="masterDashboard">
                <div className="masterDashboardContainer">
                    <div className="containerfluid">
                        <div className="pagetitlebox">
                            <PageHeader
                                {...props}
                                addnew={addnew}
                                refresh={refresh}
                                uploadfile={uploadfile}
                                downloadfile={downloadfile}
                                pagename={pagename}
                                breadcrumb={breadcrumb}
                                clickHandler={clickHandler}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-12 pl0 pr0">
                                {
                                    loadingtable ?
                                        <div className="tableloading">
                                            <div className="lds-ripple"><div></div><div></div></div>
                                        </div>
                                        :
                                        dataselected ?
                                            <div className="card">
                                                <div className="cardTitle verticalCenter">
                                                    <span>Please edit the information below</span>
                                                </div>
                                                <Table
                                                    {...props}
                                                    dataselected={dataselected}
                                                    editformdatastatus={editformdatastatus}
                                                    loadingtableHandler={loadingtableHandler}
                                                    subname={subname}
                                                    buttonlink={buttonlink}
                                                    buttondestination={buttondestination}
                                                />
                                            </div>
                                            : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default VendorEditScreen;