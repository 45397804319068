import axios from 'axios';
import {
    GET_EMATERIALSTOCKS,
    GET_EMATERIALSTOCKS_BY_ID,
    GET_EMATERIALSTOCKS_SINGLE_BY_ID,
    ADD_EMATERIALSTOCKS,
    UPDATE_EMATERIALSTOCKS,
    CLEAR_UPDATE_EMATERIALSTOCKS,
    CLEAR_EMATERIALSTOCKS,
    UPDATE_SINGLE_EMATERIALSTOCKS,
    CLEAR_SINGLE_EMATERIALSTOCKS,
    UPDATE_SINGLE_EMATERIALSTOCKS_SINGLE,
    CLEAR_SINGLE_EMATERIALSTOCKS_SINGLE
} from '../types';
import { EMATERIALSTOCKS_SERVER } from '../misc';

export function getEmaterialstocks() {
    const request = axios.get(`${EMATERIALSTOCKS_SERVER}/getematerialstocks`)
        .then(response => response.data);

    return {
        type: GET_EMATERIALSTOCKS,
        payload: request
    }
}

export function getEmaterialstocksByid(id) {
    const request = axios.get(`${EMATERIALSTOCKS_SERVER}/getematerialstocksbyid?id=${id}&type=single`)
        .then(response => response.data);

    return {
        type: GET_EMATERIALSTOCKS_BY_ID,
        payload: request
    }
}

export function getEmaterialSinglestocksByid(id) {
    const request = axios.get(`${EMATERIALSTOCKS_SERVER}/getematerialsinglestocksbyid?id=${id}&type=single`)
        .then(response => response.data);

    return {
        type: GET_EMATERIALSTOCKS_SINGLE_BY_ID,
        payload: request
    }
}

export function addEmaterialstocks(dataToSubmit) {
    const request = axios.post(`${EMATERIALSTOCKS_SERVER}/addematerialstocks`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_EMATERIALSTOCKS,
        payload: request
    }
}

export function clearEmaterialstocks() {
    return {
        type: CLEAR_EMATERIALSTOCKS,
        payload: ''
    }
}

export function updateEmaterialstocks(dataToSubmit, id) {
    const request = axios.post(`${EMATERIALSTOCKS_SERVER}/updateematerialstocks?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_EMATERIALSTOCKS,
        payload: request
    }
}

export function clearUpdateEmaterialstocks() {
    return {
        type: CLEAR_UPDATE_EMATERIALSTOCKS,
        payload: ''
    }
}

export function updateSingleEmaterialstocks(dataToSubmit, id) {
    const request = axios.post(`${EMATERIALSTOCKS_SERVER}/updatepushdatatomaterial?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_SINGLE_EMATERIALSTOCKS,
        payload: request
    }
}

export function clearSingleUpdateEmaterialstocks() {
    return {
        type: CLEAR_SINGLE_EMATERIALSTOCKS,
        payload: ''
    }
}

export function updateEmaterialSinglestocks(dataToSubmit, id) {
    const request = axios.post(`${EMATERIALSTOCKS_SERVER}/updateematerialsinglestocks?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_SINGLE_EMATERIALSTOCKS_SINGLE,
        payload: request
    }
}

export function clearUpdateEmaterialSinglestocks() {
    return {
        type: CLEAR_SINGLE_EMATERIALSTOCKS_SINGLE,
        payload: ''
    }
}