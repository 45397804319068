import React from 'react';
import { convertToRupiah } from '.././../../utils/form/formactions';

const ThisEmaterialTable2nd = (props) => {
    return (
        <tr key={props.index} className="image" onClick={() => props.editData(props.item)}>
            {
                props.item.images[0] && props.item.images[0].url ?
                    <td>
                        <img src={props.item.images[0].url} alt="" />
                    </td>
                    :
                    <td>
                        <img src={window.location.origin + "/assets/images/notavailablef.jpg"} alt="" />
                    </td>
            }
            <td>
                {props.item.name}
            </td>
            {
                props.item.material && props.item.material.length > 0 ?
                    <td>
                        material existed
                    </td>
                    :
                    <td>
                        please click here to input data for {props.item.name}
                    </td>
            }
            {/* <td>
                {props.item.quantity} {props.item.converter && props.item.converter.subname}
            </td>
            <td>
                {props.item.quantityconverted}
            </td>
            <td>
                {
                    props.item.modal ?
                        `Rp. ${convertToRupiah(props.item.modal)},-`
                        : null
                }

            </td>
            <td>
                {
                    props.item.modaleach ?
                        `Rp. ${convertToRupiah(props.item.modaleach)},-`
                        : null
                }

            </td> */}
        </tr>
    );
};

export default ThisEmaterialTable2nd;