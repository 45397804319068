import MainDashboard from './views/dashboard';
import EmailScreen from './views/email';

import WebsiteScreen from './views/website';
import WebsiteAddScreen from './views/website/addnew';
import WebsiteEditScreen from './views/website/edit';

import CategoryScreen from './templateviews/category';
import CategoryAddNewScreen from './templateviews/category/addnew';
import CategoryEditdataScreen from './templateviews/category/edit';

import PortfolioScreen from './templateviews/portfolio';
import PortfolioAddNewScreen from './templateviews/portfolio/addnew';
import PortfolioEditdataScreen from './templateviews/portfolio/edit';

import GalleryScreen from './templateviews/gallery';
import GalleryAddNewScreen from './templateviews/gallery/addnew';
import GalleryEditdataScreen from './templateviews/gallery/edit';

import SliderScreen from './templateviews/slider';
import SliderAddNewScreen from './templateviews/slider/addnew';
import SliderEditdataScreen from './templateviews/slider/edit';

// ECOMMERCE SCREEN
import EGalleryScreen from './templateviews/egallery';
import EGalleryAddNewScreen from './templateviews/egallery/addnew';
import EGalleryEditdataScreen from './templateviews/egallery/edit';

import ESliderScreen from './templateviews/eslider';
import ESliderAddNewScreen from './templateviews/eslider/addnew';
import ESliderEditdataScreen from './templateviews/eslider/edit';

import ProductScreen from './templateviews/product';
import ProductAddNewScreen from './templateviews/product/addnew';
import ProductEditdataScreen from './templateviews/product/edit';

import StockScreen from './templateviews/stock';

import GoodsScreen from './templateviews/stock/goods';
import GoodsAddnewScreen from './templateviews/stock/goods/addnew';
import GoodsEditdataScreen from './templateviews/stock/goods/edit';

import UnitScreen from './templateviews/stock/eunit';
import UnitAddNewScreen from './templateviews/stock/eunit/addnew';
import UnitEditdataScreen from './templateviews/stock/eunit/edit';

import ConverterScreen from './templateviews/stock/econverter';
import ConverterAddNewScreen from './templateviews/stock/econverter/addnew';
import ConverterEditdataScreen from './templateviews/stock/econverter/edit';

import VendorStockScreen from './templateviews/stock/vendor';
import VendorAddNewScreen from './templateviews/stock/vendor/addnew';
import VendorEditdataScreen from './templateviews/stock/vendor/edit';

import MaterialStockScreen from './templateviews/stock/ematerialstocks';
import EMaterialStocksAddNewScreen from './templateviews/stock/ematerialstocks/addnew';
import EMaterialStocksEditdataScreen from './templateviews/stock/ematerialstocks/edit';
import EMaterialStocksSubEditdataScreen from './templateviews/stock/ematerialstocks/edit2nd';

import FormulaScreen from './templateviews/stock/formula';
import FormulaAddNewScreen from './templateviews/stock/formula/addnew';
import FormulaEditScreen from './templateviews/stock/formula/edit';

// ADMIN SCREEN
import MasterUser from './masteradminviews/users';
import MasterAddUser from './masteradminviews/users/addnew';
import MasterEditUser from './masteradminviews/users/edit';

import MasterApplication from './masteradminviews/applications';
import MasterAddApplication from './masteradminviews/applications/addnew';
import MasterEditApplication from './masteradminviews/applications/edit';

import MasterSettings from './masteradminviews/settings';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faPhotoVideo, faEdit, faBoxes, faBox, faCubes, faPlus, faList, faCartPlus, faCalculator, faLayerGroup, faBrain, faConciergeBell, faDraftingCompass, faCoins } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faEnvelope,
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake)

let version = "v5.0.11b"
let mainpath = [
    {
        name: 'Dashboard',
        link: '/admin/dashboard',
        component: MainDashboard,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faHome
    },
    {
        name: 'Website',
        link: '/admin/website',
        component: WebsiteScreen,
        exact: true,
        public: true,
        show: false,
        dashboard: false,
        faicons: faAddressCard
    },
    {
        name: 'Message',
        link: '/admin/message',
        component: EmailScreen,
        exact: true,
        public: true,
        show: false,
        dashboard: false,
        faicons: faEnvelope
    }
]

let portfoliopath = [
    {
        name: 'Portfolio',
        link: '/admin/portfolio',
        component: PortfolioScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Category',
        link: '/admin/category',
        component: CategoryScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faList
    },
]

let mediapath = [
    {
        name: 'Gallery',
        link: '/admin/gallery',
        component: GalleryScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Slider',
        link: '/admin/slider',
        component: SliderScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: true,
        faicons: faPhotoVideo
    }
]

let stockpath = [
    {
        name: 'Stock',
        link: '/admin/stock',
        component: StockScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: true,
        faicons: faBoxes
    },
    {
        name: 'Vendor',
        link: '/admin/vendor',
        component: VendorStockScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faUsers
    },
    {
        name: 'Goods',
        link: '/admin/goods',
        component: GoodsScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faCartPlus
    },
    {
        name: 'Formula',
        link: '/admin/formula',
        component: FormulaScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faBrain
    },
    {
        name: 'Material',
        link: '/admin/material',
        component: MaterialStockScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faCoins
    },
    {
        name: 'Converter',
        link: '/admin/converter',
        component: ConverterScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faDraftingCompass
    },
    {
        name: 'Unit',
        link: '/admin/unit',
        component: UnitScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: false,
        faicons: faConciergeBell
    }
]

let productpath = [
    {
        name: 'EGallery',
        link: '/admin/egallery',
        component: EGalleryScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: true,
        faicons: faPhotoVideo
    },
    {
        name: 'ESlider',
        link: '/admin/eslider',
        component: ESliderScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Product',
        link: '/admin/product',
        component: ProductScreen,
        exact: true,
        public: true,
        show: true,
        dashboard: true,
        faicons: faCubes
    }
]

let subtemplatepath = [
    {
        name: 'Add Website',
        link: '/admin/website/addnew',
        component: WebsiteAddScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPlus
    },
    {
        name: 'Edit Website',
        link: '/admin/website/editdata/:id',
        component: WebsiteEditScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faEdit
    },
    {
        name: 'Add Category',
        link: '/admin/category/addnew',
        component: CategoryAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Category',
        link: '/admin/category/editdata/:id',
        component: CategoryEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add Portfolio',
        link: '/admin/portfolio/addnew',
        component: PortfolioAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Portfolio',
        link: '/admin/portfolio/editdata/:id',
        component: PortfolioEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add Gallery',
        link: '/admin/gallery/addnew',
        component: GalleryAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Gallery',
        link: '/admin/gallery/editdata/:id',
        component: GalleryEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add Slider',
        link: '/admin/slider/addnew',
        component: SliderAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Slider',
        link: '/admin/slider/editdata/:id',
        component: SliderEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add Gallery',
        link: '/admin/egallery/addnew',
        component: EGalleryAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Gallery',
        link: '/admin/egallery/editdata/:id',
        component: EGalleryEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add Slider',
        link: '/admin/eslider/addnew',
        component: ESliderAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Slider',
        link: '/admin/eslider/editdata/:id',
        component: ESliderEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add Product',
        link: '/admin/product/addnew',
        component: ProductAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Product',
        link: '/admin/product/editdata/:id',
        component: ProductEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add New Vendor',
        link: '/admin/vendor/addnew',
        component: VendorAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Vendor',
        link: '/admin/vendor/editdata/:id',
        component: VendorEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add New EMaterialStocks',
        link: '/admin/material/addnew',
        component: EMaterialStocksAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit EMaterialstocks',
        link: '/admin/material/editdata/:id',
        component: EMaterialStocksEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Sub EMaterialstocks',
        link: '/admin/material/editdata/:id/:nextid',
        component: EMaterialStocksSubEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add New Converter',
        link: '/admin/converter/addnew',
        component: ConverterAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Converter',
        link: '/admin/converter/editdata/:id',
        component: ConverterEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add New Unit',
        link: '/admin/unit/addnew',
        component: UnitAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Unit',
        link: '/admin/unit/editdata/:id',
        component: UnitEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add New Formula',
        link: '/admin/formula/addnew',
        component: FormulaAddNewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Formula',
        link: '/admin/formula/editdata/:id',
        component: FormulaEditScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Add New Goods',
        link: '/admin/goods/addnew',
        component: GoodsAddnewScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    },
    {
        name: 'Edit Goods',
        link: '/admin/goods/editdata/:id',
        component: GoodsEditdataScreen,
        exact: true,
        public: true,
        show: true,
        faicons: faPhotoVideo
    }
]

let adminpath = [
    {
        name: 'Admin',
        link: '/admin/master/user',
        component: MasterUser,
        exact: true,
        public: true,
        show: true,
        faicons: faUsers
    },
    {
        name: 'Application',
        link: '/admin/master/application',
        component: MasterApplication,
        exact: true,
        public: true,
        show: true,
        faicons: faBoxes
    },
    {
        name: 'Settings',
        link: '/admin/master/settings',
        component: MasterSettings,
        exact: true,
        public: true,
        show: true,
        faicons: faCogs
    }
]

let subadminpath = [
    {
        name: 'Add User',
        link: '/admin/master/user/addnew',
        component: MasterAddUser,
        exact: true,
        public: true,
        show: true,
        faicons: faUsers
    },
    {
        name: 'Edit User',
        link: '/admin/master/user/editdata/:id',
        component: MasterEditUser,
        exact: true,
        public: true,
        show: true,
        faicons: faEdit
    },
    {
        name: 'Add Application',
        link: '/admin/master/application/addnew',
        component: MasterAddApplication,
        exact: true,
        public: true,
        show: true,
        faicons: faBox
    },
    {
        name: 'Edit Application',
        link: '/admin/master/application/editdata/:id',
        component: MasterEditApplication,
        exact: true,
        public: true,
        show: true,
        faicons: faEdit
    },
]


export default {
    version,
    mainpath,
    adminpath,
    subadminpath,
    portfoliopath,
    stockpath,
    mediapath,
    productpath,
    subtemplatepath
}