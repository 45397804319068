import React, {
    useState,
    useEffect
    // useRef 
} from 'react';
import {
    // useSelector,
    useDispatch
} from 'react-redux';

import FormField from '../../utils/form/formfield';
import { update, generateData, isFormValid } from '../../utils/form/formactions';
import {
    addCategory,
    clearCategory,
    getCategories,
    updateCategory,
    clearUpdateCategory
} from '../../../../store/actions/category_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const CategoryTableScreen = (props) => {
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    // const size = useWindowSize();
    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    const [formdata, formdataHandler] = useState({
        name: {
            element: 'input',
            title: 'Title',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter title'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'category');
        formErrorHandler(false);
        formdataHandler(newFormdata);
    }
    useEffect(() => {
        if (editformdatastatus && dataselected) {
            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            for (let key in formdata) {
                const newElement = {
                    ...temporaryFormdata[key]
                }
                if (newElement.element === 'select') {
                    newElement.selectedValue = dataselected[key].name;
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
                if (newElement.element === 'input') {
                    newElement.value = dataselected[key];
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
            }
            formdataHandler(temporaryFormdata);
        }
    }, [editformdatastatus, dataselected])
    const goBackToTable = () => {
        props.history.push('/admin/category');
    }

    const fetchingnewData = async (id) => {
        let selectedproduct = await dispatch(getCategories());
        let final = selectedproduct.payload.categories.find(site => site._id === id);
        props.history.push({
            pathname: `/admin/category/editdata/${id}`,
            state: {
                dataselected: final,
                editformdata: true
            }
        })
    }

    const submitData = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formdata, 'category');
        let formIsValid = isFormValid(formdata, 'category');

        if (formIsValid) {
            dispatch(addCategory(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearCategory());
                    formSuccessHandler(true);
                    props.history.push('/admin/category');
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            // errorMessageHandler(portfolioprops)
        }
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'categoryedit');
        let formIsValid2 = isFormValid(formdata, 'categoryedit');
        let dataselectedid = dataselected._id;
        let datachange = 0;

        for (let key1 in dataToSubmit2) {
            if (dataselected[key1] !== dataToSubmit2[key1]) {
                datachange = datachange + 1;
            }
        }

        if (datachange > 0) {
            if (formIsValid2) {
                dispatch(updateCategory(dataToSubmit2, dataselectedid)).then(response => {
                    if (response.payload.success) {
                        dispatch(clearUpdateCategory());
                        formSuccessHandler(true);
                        setTimeout(() => {
                            fetchingnewData(dataselectedid);
                        }, 1000);
                    } else {
                        formErrorHandler(true);
                        errorMessageHandler(response.payload.message);
                    }
                })
            } else {
                formErrorHandler(true);
                errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler('CHANGE DATA BEFORE SUBMIT!');
        }
    }

    return (
        <div className="cardBody formstyle">
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.name.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'name'}
                        formdata={formdata.name}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                                    </div>
                            : null
                }
                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    <div className="doubleButton">
                        <button
                            onClick={editformdatastatus ? (event) => submitEditData(event) : (event) => submitData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryTableScreen;