import axios from 'axios';
import {
    ADD_WEBSITE_ADDRESS,
    CLEAR_WEBSITE_ADDRESS,
    ADD_WEBSITE,
    CLEAR_WEBSITE,
    ADD_WEBSITE_LOGO,
    ADD_WEBSITE_LOGO_MOBILE,
    ADD_WEBSITE_LOGO_FOOTER,
    GET_WEBSITE_ADDRESS,
    GET_WEBSITE_ADDRESS_BY_ID,
    GET_WEBSITE,
    GET_WEBSITE_BY_ID,
    UPDATE_WEBSITE,
    CLEAR_UPDATE_WEBSITE
} from '../types';
import { WEBSITE_SERVER } from '../misc';

export function addWebsiteAddress(dataToSubmit) {
    const request = axios.post(`${WEBSITE_SERVER}/addaddress`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_WEBSITE_ADDRESS,
        payload: request
    }
}

export function clearWebsiteAddress() {
    return {
        type: CLEAR_WEBSITE_ADDRESS,
        payload: ''
    }
}

export function addWebsiteLogo(dataToSubmit, id) {
    const formData = new FormData();
    for (const key in dataToSubmit) {
        if ((dataToSubmit[key])) {
            if (key === 'logo') {
                dataToSubmit[key].forEach((logo, i) => {
                    formData.append(`logo${i}`, logo);
                });
            } else {
                formData.append(key, dataToSubmit[key]);
            }
        }
    }
    const request = axios.post(`${WEBSITE_SERVER}/addwebsitelogo?id=${id}`, formData)
        .then(response => response.data);

    return {
        type: ADD_WEBSITE_LOGO,
        payload: request
    }
}

export function addWebsiteLogoMobile(dataToSubmit, id) {
    const formData = new FormData();
    for (const key in dataToSubmit) {
        if ((dataToSubmit[key])) {
            if (key === 'logomobile') {
                dataToSubmit[key].forEach((logomobile, i) => {
                    formData.append(`logomobile${i}`, logomobile);
                });
            } else {
                formData.append(key, dataToSubmit[key]);
            }
        }
    }
    const request = axios.post(`${WEBSITE_SERVER}/addwebsitelogomobile?id=${id}`, formData)
        .then(response => response.data);

    return {
        type: ADD_WEBSITE_LOGO_MOBILE,
        payload: request
    }
}

export function addWebsiteLogoFooter(dataToSubmit, id) {
    const formData = new FormData();
    for (const key in dataToSubmit) {
        if ((dataToSubmit[key])) {
            if (key === 'logofooter') {
                dataToSubmit[key].forEach((logofooter, i) => {
                    formData.append(`logofooter${i}`, logofooter);
                });
            } else {
                formData.append(key, dataToSubmit[key]);
            }
        }
    }
    const request = axios.post(`${WEBSITE_SERVER}/addwebsitelogofooter?id=${id}`, formData)
        .then(response => response.data);

    return {
        type: ADD_WEBSITE_LOGO_FOOTER,
        payload: request
    }
}

export function addWebsite(dataToSubmit) {
    const request = axios.post(`${WEBSITE_SERVER}/addwebsite`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_WEBSITE,
        payload: request
    }
}

export function clearWebsite() {
    return {
        type: CLEAR_WEBSITE,
        payload: ''
    }
}

export function updateWebsite(dataToSubmit, id) {
    const request = axios.post(`${WEBSITE_SERVER}/updatewebsite?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_WEBSITE,
        payload: request
    }
}

export function clearUpdateWebsite() {
    return {
        type: CLEAR_UPDATE_WEBSITE,
        payload: ''
    }
}

export function getWebsiteAddress() {
    const request = axios.get(`${WEBSITE_SERVER}/getaddress`)
        .then(response => response.data);

    return {
        type: GET_WEBSITE_ADDRESS,
        payload: request
    }
}

export function getWebsiteAddressByid(id) {
    const request = axios.get(`${WEBSITE_SERVER}/getaddressbyid?id=${id}`)
        .then(response => {
            return response.data[0]
        })

    return {
        type: GET_WEBSITE_ADDRESS_BY_ID,
        payload: request
    }
}

export function getWebsite() {
    const request = axios.get(`${WEBSITE_SERVER}/getwebsite`)
        .then(response => response.data);

    return {
        type: GET_WEBSITE,
        payload: request
    }
}

export function getWebsiteByid(id) {
    const request = axios.get(`${WEBSITE_SERVER}/getwebsitebyid?id=${id}&type=single`)
        .then(response => response.data);

    return {
        type: GET_WEBSITE_BY_ID,
        payload: request
    }
}