import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEmaterialstocks } from '../../../../../store/actions/ematerialstocks_action';

const ThisVendorTable = (props) => {
    const { getematerialstocks } = useSelector(state => ({
        getematerialstocks: state.ematerialstocks
    }));
    const dispatch = useDispatch();
    const [allematerialstocks, allematerialstocksHandler] = useState([]);
    /////////////////////////////////////////////////////////////
    useEffect(() => {
        dispatch(getEmaterialstocks());
    }, [dispatch]);

    useEffect(() => {
        if (getematerialstocks && getematerialstocks.getEmaterialstocks && getematerialstocks.getEmaterialstocks.success) {
            let totalematerialstocks = getematerialstocks.getEmaterialstocks.ematerialstocks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allematerialstocksHandler(totalematerialstocks);
        }
    }, [getematerialstocks]);
    /////////////////////////////////////////////////////////////

    return (
        <tr key={props.index} className="image" onClick={() => props.editData(props.item)}>
            <td>
                {props.item.name}
            </td>
            <td>
                {
                    props.item.formula && props.item.formula.length > 0 ?
                        props.item.formula.map((item, index) => {
                            let showdata = allematerialstocks.filter(data => data._id === item.materialid);
                            if (showdata && showdata.length > 0) {
                                return (
                                    <div key={index}>
                                        {index + 1}. {showdata[0].name} ({item.quantity} {showdata[0].material[0].converter && showdata[0].material[0].converter.subunit && showdata[0].material[0].converter.subunit.shortname})
                                    </div>
                                )
                            }
                        })
                        : null
                }
            </td>
        </tr>
    );
};

export default ThisVendorTable;