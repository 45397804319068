import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminLayout from '../../views/layout/adminpage';
import PageHeader from '../../../widget/pageheader';

import { useWindowSize } from '../../../widget/windowsize';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

const MasterSettings = (props) => {

    const size = useWindowSize();
    const isMobile = size.width <= 767.98;
    const dispatch = useDispatch();
    const [loadingtable, loadingtableHandler] = useState(false);
    const [pagename] = useState('Website Settings');
    const [addnew] = useState(true);
    const [refresh] = useState(true);
    const [uploadfile] = useState(true);
    const [downloadfile] = useState(true);
    const [pagetitletable] = useState(true);
    const [gobackbutton] = useState(false);
    const [showedit, showeditHandler] = useState(false);
    const [selected, selectedHandler] = useState({});
    const [showsettingmenu, showsettingmenuHandler] = useState(false);
    const [breadcrumb] = useState([
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            public: true,
            faicons: faHome
        },
        {
            name: 'Master User',
            linkTo: `${props.location.pathname}`,
            public: true
        },
        {
            name: pagename,
            linkTo: '',
            public: true
        }
    ]);

    const clickHandler = (link) => {
        props.history.push(link);
    }

    return (
        <AdminLayout>
            <div className="masterDashboard">
                <div className="masterDashboardContainer">
                    <div className="containerfluid tableloading">
                        <div className="pagetitlebox">
                            <PageHeader
                                {...props}
                                pagename={pagename}
                                breadcrumb={breadcrumb}
                                clickHandler={clickHandler}
                            />
                        </div>
                        <div className="row">
                            {/* <TableMedia
                                pagetitletable={pagetitletable}
                                gobackbutton={gobackbutton}
                                addnew={addnew}
                                refresh={refresh}
                                uploadfile={uploadfile}
                                downloadfile={downloadfile}
                                showsettingmenu={showsettingmenu}
                                dontblur={dontblur}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                addNewHandler={addNewHandler}
                                hideMenuHandler={hideMenuHandler}
                                showMenuHandler={showMenuHandler}
                                onRefreshHandler={onRefreshHandler}
                                userprops={userprops}
                                isMobile={isMobile}
                                loadingtable={loadingtable}
                                filterText={filterText}
                                handleChange={handleChange}
                                currentPage={currentPage}
                                dataCount={dataCount}
                                pageSize={pageSize}
                                tablename={tablename}
                                tablemenu={tablemenu}
                                mydata={mydata}
                                pageChanged={pageChanged}
                                showedit={showedit}
                                editDataHandler={editDataHandler}
                                deleteDataHandler={deleteDataHandler}
                                backdropClickHandler={backdropClickHandler}
                                alladdress={alladdress}
                                goBackToFront={goBackToFront}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default MasterSettings;