import axios from 'axios';
import {
    GET_FORMULA,
    GET_FORMULA_BY_ID,
    ADD_FORMULA,
    UPDATE_FORMULA,
    UPDATE_SINGLE_FORMULA,
    CLEAR_SINGLE_FORMULA,
    CLEAR_UPDATE_FORMULA,
    CLEAR_FORMULA,
    DELETE_SINGLE_FORMULA
} from '../types';
import { FORMULA_SERVER } from '../misc';

export function getFormula() {
    const request = axios.get(`${FORMULA_SERVER}/geteformula`)
        .then(response => response.data);

    return {
        type: GET_FORMULA,
        payload: request
    }
}

export function getFormulaByid(id){
    const request = axios.get(`${FORMULA_SERVER}/geteformulabyid?id=${id}&type=single`)
    .then(response => response.data);

    return {
        type: GET_FORMULA_BY_ID,
        payload: request
    }
}

export function addFormula(dataToSubmit) {
    const request = axios.post(`${FORMULA_SERVER}/addeformula`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_FORMULA,
        payload: request
    }
}

export function clearFormula() {
    return {
        type: CLEAR_FORMULA,
        payload: ''
    }
}

export function updateFormula(dataToSubmit, id) {
    const request = axios.post(`${FORMULA_SERVER}/updateeformula?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_FORMULA,
        payload: request
    }
}

export function clearUpdateFormula() {
    return {
        type: CLEAR_UPDATE_FORMULA,
        payload: ''
    }
}

export function updateSingleFormula(dataToSubmit, id, indexarray) {
    const request = axios.post(`${FORMULA_SERVER}/updatesingleeformula?id=${id}&indexarray=${indexarray}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_SINGLE_FORMULA,
        payload: request
    }
}

export function clearUpdateSingleFormula() {
    return {
        type: CLEAR_SINGLE_FORMULA,
        payload: ''
    }
}

export function deleteSingleFormula(id, selectedid) {
    const request = axios.post(`${FORMULA_SERVER}/removesingleeformula?id=${id}&selectedid=${selectedid}`)
        .then(response => response.data);

    return {
        type: DELETE_SINGLE_FORMULA,
        payload: request
    }
}