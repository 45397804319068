import {
    GET_GOODS,
    GET_GOODS_BY_ID,
    ADD_GOODS,
    UPDATE_GOODS,
    CLEAR_UPDATE_GOODS,
    CLEAR_GOODS
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_GOODS:
            return {
                ...state,
                getGoods: action.payload
            }
        case GET_GOODS_BY_ID:
            return {
                ...state,
                getGoodsByid: action.payload
            }
        case ADD_GOODS:
            return {
                ...state,
                addGoods: action.payload
            }
        case CLEAR_GOODS:
            return {
                ...state,
                addGoods: action.payload
            }
        case UPDATE_GOODS:
            return {
                ...state,
                updateGoods: action.payload
            }
        case CLEAR_UPDATE_GOODS:
            return {
                ...state,
                updateGoods: action.payload
            }
        default:
            return state;
    }
}