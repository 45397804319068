import {
    ADD_WEBSITE_ADDRESS,
    CLEAR_WEBSITE_ADDRESS,
    ADD_WEBSITE,
    CLEAR_WEBSITE,
    ADD_WEBSITE_LOGO,
    ADD_WEBSITE_LOGO_MOBILE,
    ADD_WEBSITE_LOGO_FOOTER,
    GET_WEBSITE_ADDRESS,
    GET_WEBSITE_ADDRESS_BY_ID,
    GET_WEBSITE,
    GET_WEBSITE_BY_ID,
    UPDATE_WEBSITE,
    CLEAR_UPDATE_WEBSITE
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case ADD_WEBSITE_ADDRESS:
            return {
                ...state,
                addWebsiteAddress: action.payload
            }
        case CLEAR_WEBSITE_ADDRESS:
            return {
                ...state,
                addWebsiteAddress: action.payload
            }
        case ADD_WEBSITE:
            return {
                ...state,
                addWebsite: action.payload
            }
        case CLEAR_WEBSITE:
            return {
                ...state,
                addWebsite: action.payload
            }
        case ADD_WEBSITE_LOGO:
            return {
                ...state,
                addWebsiteLogo: action.payload
            }
        case ADD_WEBSITE_LOGO_MOBILE:
            return {
                ...state,
                addWebsiteLogoMobile: action.payload
            }
        case ADD_WEBSITE_LOGO_FOOTER:
            return {
                ...state,
                addWebsiteLogoFooter: action.payload
            }
        case GET_WEBSITE_ADDRESS:
            return {
                ...state,
                getWebsiteAddress: action.payload
            }
        case GET_WEBSITE_ADDRESS_BY_ID:
            return {
                ...state,
                getWebsiteAddressByid: action.payload
            }
        case GET_WEBSITE:
            return {
                ...state,
                getWebsite: action.payload
            }
        case GET_WEBSITE_BY_ID:
            return {
                ...state,
                getWebsiteByid: action.payload
            }
        case UPDATE_WEBSITE:
            return {
                ...state,
                updateWebsite: action.payload
            }
        case CLEAR_UPDATE_WEBSITE:
            return {
                ...state,
                updateWebsite: action.payload
            }
        default:
            return state;
    }
}