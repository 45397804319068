import React, {
    useState,
    useEffect
} from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';

import FormField from '../../../utils/form/formfield';
import { update, generateData, isFormValid, validate } from '../../../utils/form/formactions';
import {
    getEConverter,
    addEConverter,
    clearEConverter,
    updateEConverter,
    clearUpdateEConverter
} from '../../../../../store/actions/econverter_action';
import {
    getEUnit
} from '../../../../../store/actions/eunit_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const EConverterTableScreen = (props) => {
    const { getalldata } = useSelector(state => ({
        getalldata: state.eunit
    }))
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    const [alldata, alldataHandler] = useState([]);
    const [dontblur, dontblurHandler] = useState(false);
    const [searchmode, searchmodeHandler] = useState(false);
    const [searchunit, searchunitHandler] = useState("");
    const [searchresult, searchresultHandler] = useState([]);

    const [dontblursubunit, dontblursubunitHandler] = useState(false);
    const [searchmodesubunit, searchmodesubunitHandler] = useState(false);
    const [searchunitsubunit, searchunitsubunitHandler] = useState("");
    const [searchresultsubunit, searchresultsubunitHandler] = useState([]);

    const [formdata, formdataHandler] = useState({
        name: {
            element: 'input',
            title: 'Name',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        mainunit: {
            element: 'select',
            title: 'Main Unit',
            selectedValue: '',
            value: '',
            config: {
                name: 'mainunitInput',
                options: [],
                placeholder: 'Choose main unit'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        quantity: {
            element: 'input',
            title: 'Quantity',
            value: '',
            config: {
                name: 'quantityInput',
                type: 'text',
                placeholder: 'Enter quantity'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        subunit: {
            element: 'select',
            title: 'Sub Unit',
            selectedValue: '',
            value: '',
            config: {
                name: 'subunitInput',
                options: [],
                placeholder: 'Choose sub unit'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    useEffect(() => {
        dispatch(getEUnit());
    }, [dispatch])
    useEffect(() => {
        if (getalldata.getEunit && getalldata.getEunit.success) {
            let totaldata = getalldata.getEunit.eunits.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            alldataHandler(totaldata);
            searchresultHandler(totaldata);
            searchresultsubunitHandler(totaldata);
        }
    }, [getalldata])
    useEffect(() => {
        if (editformdatastatus && dataselected) {
            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            for (let key in formdata) {
                const newElement = {
                    ...temporaryFormdata[key]
                }
                if(newElement.element === 'select'){
                    newElement.selectedValue = `${dataselected[key].name} (${dataselected[key].shortname})`;
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                } 
                if(newElement.element === 'input') {
                    newElement.value = dataselected[key];
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
            }
            formdataHandler(temporaryFormdata);
        }
    }, [editformdatastatus, dataselected])
    useEffect(() => {
        if (alldata) {
            const results = alldata.filter(result =>
                result.name.toLowerCase().includes(searchunit.toLowerCase())
            );
            searchresultHandler(results);
            searchresultsubunitHandler(results);
        }

    }, [alldata, searchunit]);
    useEffect(() => {
        if (alldata) {
            const results = alldata.filter(result =>
                result.name.toLowerCase().includes(searchunitsubunit.toLowerCase())
            );
            searchresultsubunitHandler(results);
        }

    }, [alldata, searchunitsubunit]);

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'converter');
        formErrorHandler(false)
        formdataHandler(newFormdata)
    }

    ////////////////////////////////////////////////// MAINUNIT
    const searchForm = () => {
        searchmodeHandler(true)
    }
    const onMouseEnter = () => {
        dontblurHandler(true)
    }
    const onMouseLeave = () => {
        dontblurHandler(false)
    }
    const hideprofilemenu = () => {
        searchunitHandler("");
        searchmodeHandler(false)
    }
    const handleChange = (event) => {
        searchunitHandler(event.target.value);
    }
    const selectedItem = (data, whichdata) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = `${data.name.replace(/^\w/, c => c.toUpperCase())} (${data.shortname})`;
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        formdataHandler(temporaryFormdata);
        searchunitHandler("");
        formErrorHandler(false);
        searchmodeHandler(false);
        dontblurHandler(false);
    }
    ////////////////////////////////////////////////// MAINUNIT

    ////////////////////////////////////////////////// SUBUNIT
    const searchFormSubunit = () => {
        searchmodesubunitHandler(true)
    }
    const onMouseEnterSubunit = () => {
        dontblursubunitHandler(true)
    }
    const onMouseLeaveSubunit = () => {
        dontblursubunitHandler(false)
    }
    const hideprofilemenuSubunit = () => {
        searchunitsubunitHandler("");
        searchmodesubunitHandler(false)
    }
    const handleChangeSubunit = (event) => {
        searchunitsubunitHandler(event.target.value);
    }
    const selectedItemSubunit = (data, whichdata) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = `${data.name.replace(/^\w/, c => c.toUpperCase())} (${data.shortname})`;
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        formdataHandler(temporaryFormdata);
        searchunitsubunitHandler("");
        formErrorHandler(false);
        searchmodesubunitHandler(false);
        dontblursubunitHandler(false);
    }
    ////////////////////////////////////////////////// SUBUNIT

    const showLinks = (type, whichdata, whichfunction) => {
        let list = [];

        if (type && type.length > 0) {
            type.forEach((item) => {
                list.push(item)
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={() => whichfunction(item, whichdata)}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {item.name.replace(/^\w/, c => c.toUpperCase())}&nbsp;
                        ({item.shortname})
                    </span>
                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    const goBackToTable = () => {
        props.history.push('/admin/converter');
    }

    const fetchingnewData = async (id) => {
        let selectedproduct = await dispatch(getEConverter());
        let final = selectedproduct.payload.econverters.find(site => site._id === id);
        props.history.push({
            pathname: `/admin/converter/editdata/${id}`,
            state: {
                dataselected: final,
                editformdata: true
            }
        })
    }

    const submitData = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formdata, 'converter');
        let formIsValid = isFormValid(formdata, 'converter');

        if (formIsValid) {
            dispatch(addEConverter(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearEConverter());
                    formSuccessHandler(true);
                    props.history.push('/admin/converter');
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'vendoredit');
        let formIsValid2 = isFormValid(formdata, 'vendoredit');
        let dataselectedid = dataselected._id;
        let datachange = 0;

        for (let key1 in dataToSubmit2) {
            if(key1 === 'mainunit' || key1 === 'subunit') {
                if (dataselected[key1]._id !== dataToSubmit2[key1]) {
                    datachange = datachange + 1;
                }
            } else {
                if (dataselected[key1] !== dataToSubmit2[key1]) {
                    datachange = datachange + 1;
                }
            }
        }

        if (datachange > 0) {
            if (formIsValid2) {
                dispatch(updateEConverter(dataToSubmit2, dataselectedid)).then(response => {
                    if (response.payload.success) {
                        dispatch(clearUpdateEConverter());
                        formSuccessHandler(true);
                        setTimeout(() => {
                            fetchingnewData(dataselectedid);
                        }, 1000);
                        // props.history.push('/admin/vendor');
                    } else {
                        formErrorHandler(true);
                        errorMessageHandler(response.payload.message);
                    }
                })
            } else {
                formErrorHandler(true);
                errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler('CHANGE DATA BEFORE SUBMIT!');
        }
    }

    return (
        <div className="cardBody formstyle">
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.name.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'name'}
                        formdata={formdata.name}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.mainunit.title}</label>
                <div className="col-md-10 col-xs-12 paddingRight">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faSortAlphaDown}
                            className="icon agraicon"
                        />
                    </div>
                    {
                        searchmode ?
                            <div>
                                <div
                                    onBlur={dontblur ? null : hideprofilemenu}
                                    tabIndex={0}
                                >
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name={formdata.mainunit.title}
                                        placeholder={formdata.mainunit.title}
                                        title={formdata.mainunit.config.placeholder}
                                        value={searchunit}
                                        onChange={(event) => handleChange(event)}
                                        autoFocus={true}
                                    />

                                </div>
                                <ul
                                    className="dropdownmenu listgroup profilemenu"
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                >
                                    {showLinks(searchresult, 'mainunit', selectedItem)}
                                </ul>
                            </div>


                            :
                            <FormField
                                id={'mainunit'}
                                formdata={formdata.mainunit}
                                options={alldata}
                                change={searchForm}
                                myclass={`${alldata && alldata.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            />
                    }
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.quantity.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faMapMarkedAlt}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'quantity'}
                        formdata={formdata.quantity}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.subunit.title}</label>
                <div className="col-md-10 col-xs-12 paddingRight">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faSortAlphaDown}
                            className="icon agraicon"
                        />
                    </div>
                    {
                        searchmodesubunit ?
                            <div>
                                <div
                                    onBlur={dontblursubunit ? null : hideprofilemenuSubunit}
                                    tabIndex={0}
                                >
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name={formdata.subunit.title}
                                        placeholder={formdata.subunit.title}
                                        title={formdata.subunit.config.placeholder}
                                        value={searchunitsubunit}
                                        onChange={(event) => handleChangeSubunit(event)}
                                        autoFocus={true}
                                    />

                                </div>
                                <ul
                                    className="dropdownmenu listgroup profilemenu"
                                    onMouseEnter={onMouseEnterSubunit}
                                    onMouseLeave={onMouseLeaveSubunit}
                                >
                                    {showLinks(searchresultsubunit, 'subunit', selectedItemSubunit)}
                                </ul>
                            </div>


                            :
                            <FormField
                                id={'subunit'}
                                formdata={formdata.subunit}
                                options={alldata}
                                change={searchFormSubunit}
                                myclass={`${alldata && alldata.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            />
                    }
                </div>
            </div>
            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                            </div>
                            : null
                }

                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    <div className="doubleButton">
                        <button
                            onClick={editformdatastatus ? (event) => submitEditData(event) : (event) => submitData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EConverterTableScreen;