// SERVER ROUTES
export const USER_SERVER = '/api/users';
export const CLIENT_SERVER = '/api/clients';
export const ROLE_SERVER = '/api/roles';
export const CATEGORY_SERVER ='/api/category';
export const PORTFOLIO_SERVER = '/api/portfolio';
export const GALLERY_SERVER = '/api/gallery';
export const EGALLERY_SERVER = '/api/egallery';
export const APPLICATION_SERVER = '/api/applications';
export const PRODUCT_SERVER = '/api/product';
export const PRODUCTCAT_SERVER = '/api/productcat';
export const PRODUCTBRAND_SERVER = '/api/productbrand';
export const SLIDER_SERVER = '/api/slider';
export const ESLIDER_SERVER = '/api/eslider';
export const WEBSITE_SERVER = '/api/website';
export const ONGKIR_SERVER = '/api/ongkir';
export const VENDOR_SERVER = '/api/vendor';
export const EUNIT_SERVER = '/api/eunit';
export const ECONVERTER_SERVER = '/api/econverter';
export const EMATERIALSTOCKS_SERVER = '/api/ematerialstocks';
export const FORMULA_SERVER = '/api/eformula';
export const GOODS_SERVER = '/api/goods';
export const ESTOCKS_SERVER = '/api/estocks';