import {
    ADD_EGALLERY,
    CLEAR_EGALLERY,
    GET_EGALLERY,
    GET_EGALLERY_BY_ID,
    UPDATE_EGALLERY,
    CLEAR_UPDATE_EGALLERY
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case ADD_EGALLERY:
            return {
                ...state,
                addEGallery: action.payload
            }
        case CLEAR_EGALLERY:
            return {
                ...state,
                addEGallery: action.payload
            }
        case GET_EGALLERY:
            return {
                ...state,
                getEGallery: action.payload
            }
        case GET_EGALLERY_BY_ID:
            return {
                ...state,
                getEGalleryByid: action.payload
            }
        case UPDATE_EGALLERY:
            return {
                ...state,
                updateEGallery: action.payload
            }
        case CLEAR_UPDATE_EGALLERY:
            return {
                ...state,
                updateEGallery: action.payload
            }
        default:
            return state;
    }
}