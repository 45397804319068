import axios from 'axios';
import {
    GET_VENDOR,
    GET_VENDOR_BY_ID,
    ADD_VENDOR,
    UPDATE_VENDOR,
    CLEAR_UPDATE_VENDOR,
    CLEAR_VENDOR
} from '../types';
import { VENDOR_SERVER } from '../misc';

export function getVendors() {
    const request = axios.get(`${VENDOR_SERVER}/getvendors`)
        .then(response => response.data);

    return {
        type: GET_VENDOR,
        payload: request
    }
}

export function getVendorsByid(id){
    const request = axios.get(`${VENDOR_SERVER}/getvendorsbyid?id=${id}&type=single`)
    .then(response => response.data);

    return {
        type: GET_VENDOR_BY_ID,
        payload: request
    }
}

export function addVendor(dataToSubmit) {
    const request = axios.post(`${VENDOR_SERVER}/addvendor`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_VENDOR,
        payload: request
    }
}

export function clearVendor() {
    return {
        type: CLEAR_VENDOR,
        payload: ''
    }
}

export function updateVendor(dataToSubmit, id) {
    const request = axios.post(`${VENDOR_SERVER}/updatevendor?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_VENDOR,
        payload: request
    }
}

export function clearUpdateVendor() {
    return {
        type: CLEAR_UPDATE_VENDOR,
        payload: ''
    }
}