import axios from 'axios';
import {
    GET_GOODS,
    GET_GOODS_BY_ID,
    ADD_GOODS,
    UPDATE_GOODS,
    CLEAR_UPDATE_GOODS,
    CLEAR_GOODS
} from '../types';
import { GOODS_SERVER } from '../misc';

export function getGoods() {
    const request = axios.get(`${GOODS_SERVER}/getgoods`)
        .then(response => response.data);

    return {
        type: GET_GOODS,
        payload: request
    }
}

export function getGoodsByid(id) {
    const request = axios.get(`${GOODS_SERVER}/getgoodsbyid?id=${id}&type=single`)
    .then(response => response.data);

    return {
        type: GET_GOODS_BY_ID,
        payload: request
    }
}

export function addGoods(dataToSubmit) {
    const request = axios.post(`${GOODS_SERVER}/addgoods`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_GOODS,
        payload: request
    }
}

export function clearGoods() {
    return {
        type: CLEAR_GOODS,
        payload: ''
    }
}

export function updateGoods(dataToSubmit, id) {
    const request = axios.post(`${GOODS_SERVER}/updategoods?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_GOODS,
        payload: request
    }
}

export function clearUpdateGoods() {
    return {
        type: CLEAR_UPDATE_GOODS,
        payload: ''
    }
}