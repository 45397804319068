import { combineReducers } from 'redux';
import user from './reducers/user_reducer';
import client from './reducers/client_reducer';
import loading from './reducers/loading_reducer';
import category from './reducers/category_reducer';
import gallery from './reducers/gallery_reducer';
import egallery from './reducers/egallery_reducer';
import slider from './reducers/slider_reducer';
import eslider from './reducers/eslider_reducer';
import portfolio from './reducers/portfolio_reducer';
import product from './reducers/product_reducer';
import productcat from './reducers/productcat_reducer';
import productbrand from './reducers/productbrand_reducer';
import application from './reducers/application_reducer';
import website from './reducers/website_reducer';
import ongkir from './reducers/ongkir_reducer';
import vendor from './reducers/vendor_reducer';
import eunit from './reducers/eunit_reducer';
import econverter from './reducers/econverter_reducer';
import ematerialstocks from './reducers/ematerialstocks_reducer';
import formula from './reducers/formula_reducer';
import goods from './reducers/goods_reducer';
import estocks from './reducers/estocks_reducer';
import portfoliostatus from './reducers/portfoliostatus_reducer';
import stockstatus from './reducers/stockstatus_reducer';
// import role from './general/role_reducer';


const rootReducer = combineReducers({
    user,
    client,
    loading,
    category,
    gallery,
    egallery,
    slider,
    eslider,
    portfolio,
    product,
    productcat,
    productbrand,
    application,
    website,
    ongkir,
    vendor,
    eunit,
    econverter,
    ematerialstocks,
    formula,
    goods,
    estocks,
    portfoliostatus,
    stockstatus
    // role
});

export default rootReducer;