import {
    GET_EMATERIALSTOCKS,
    GET_EMATERIALSTOCKS_BY_ID,
    GET_EMATERIALSTOCKS_SINGLE_BY_ID,
    ADD_EMATERIALSTOCKS,
    UPDATE_EMATERIALSTOCKS,
    CLEAR_UPDATE_EMATERIALSTOCKS,
    CLEAR_EMATERIALSTOCKS,
    UPDATE_SINGLE_EMATERIALSTOCKS,
    CLEAR_SINGLE_EMATERIALSTOCKS,
    UPDATE_SINGLE_EMATERIALSTOCKS_SINGLE,
    CLEAR_SINGLE_EMATERIALSTOCKS_SINGLE
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_EMATERIALSTOCKS:
            return {
                ...state,
                getEmaterialstocks: action.payload
            }
        case GET_EMATERIALSTOCKS_BY_ID:
            return {
                ...state,
                getEmaterialstocksByid: action.payload
            }
        case GET_EMATERIALSTOCKS_SINGLE_BY_ID:
            return {
                ...state,
                getEmaterialsinglestocksByid: action.payload
            }
        case ADD_EMATERIALSTOCKS:
            return {
                ...state,
                addEmaterialstocks: action.payload
            }
        case CLEAR_EMATERIALSTOCKS:
            return {
                ...state,
                addEmaterialstocks: action.payload
            }
        case UPDATE_EMATERIALSTOCKS:
            return {
                ...state,
                updateEmaterialstocks: action.payload
            }
        case CLEAR_UPDATE_EMATERIALSTOCKS:
            return {
                ...state,
                updateEmaterialstocks: action.payload
            }
        case UPDATE_SINGLE_EMATERIALSTOCKS:
            return {
                ...state,
                updateSingleEmaterialstocks: action.payload
            }
        case CLEAR_SINGLE_EMATERIALSTOCKS:
            return {
                ...state,
                updateSingleEmaterialstocks: action.payload
            }
        case UPDATE_SINGLE_EMATERIALSTOCKS_SINGLE:
            return {
                ...state,
                updateSingleEmaterialSinglestocks: action.payload
            }
        case CLEAR_SINGLE_EMATERIALSTOCKS_SINGLE:
            return {
                ...state,
                updateSingleEmaterialSinglestocks: action.payload
            }
        default:
            return state;
    }
}