import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import FormField from '../../../utils/form/formfield';
import BackDrop from '../../../views/layout/backdrop/tablebackdrop';
import { useWindowSize } from '../../../../widget/windowsize';
import { update, validate, generateData, isFormValid } from '../../../utils/form/formactions';
import {
    getFormula
} from '../../../../../store/actions/formula_action';
import {
    getVendors
} from '../../../../../store/actions/vendor_action';
import {
    getGoods,
    addGoods,
    clearGoods,
    updateGoods,
    clearUpdateGoods
    // updateSingleFormula,
    // clearUpdateSingleFormula,
    // deleteSingleFormula
} from '../../../../../store/actions/goods_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const GoodsTableScreen = (props) => {
    const { getallformulas, getallvendors } = useSelector(state => ({
        getallformulas: state.formula,
        getallvendors: state.vendor
    }))
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    const size = useWindowSize();
    const isMobile = size.width <= 767.98;

    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [additionalformError, additionalformErrorHandler] = useState(false);
    const [additionalformSuccess, additionalformSuccessHandler] = useState(false);
    const [additionalerrorMessage, additionalerrorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    const [formdata, formdataHandler] = useState({
        code: {
            element: 'input',
            title: 'Code',
            value: '',
            config: {
                name: 'codeInput',
                type: 'text',
                placeholder: 'Enter code'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        name: {
            element: 'input',
            title: 'Name',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        formula: {
            element: 'select',
            title: 'Formula',
            selectedValue: '',
            value: '',
            config: {
                name: 'formulaInput',
                options: [],
                placeholder: 'Choose formula'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        vendor: {
            element: 'select',
            title: 'Vendor',
            selectedValue: '',
            value: '',
            config: {
                name: 'vendorInput',
                options: [],
                placeholder: 'Choose vendor'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        notes: {
            element: 'input',
            title: 'Notes',
            value: '',
            config: {
                name: 'notesInput',
                type: 'text',
                placeholder: 'Enter notes'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        quantity: {
            element: 'input',
            title: 'Quantity',
            value: '',
            config: {
                name: 'quantityInput',
                type: 'text',
                placeholder: 'Enter quantity'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        quantitynow: {
            element: 'input',
            title: 'Quantitynow',
            value: '',
            config: {
                name: 'quantitynowInput',
                type: 'text',
                placeholder: 'Enter quantitynow'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        modal: {
            element: 'input',
            title: 'Modal',
            value: '',
            config: {
                name: 'modalInput',
                type: 'text',
                placeholder: 'Enter modal'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        modalsatuan: {
            element: 'input',
            title: 'modalsatuan',
            value: '',
            config: {
                name: 'modalsatuanInput',
                type: 'text',
                placeholder: 'Enter modalsatuan'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        custom: {
            element: 'select',
            title: 'Name',
            selectedValue: '',
            value: '',
            config: {
                name: 'nameInput',
                options: [
                    { value: true, name: 'YES' },
                    { value: false, name: 'NO' },
                ],
                placeholder: 'Enter name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
    });
}

export default GoodsTableScreen;