import axios from 'axios';
import {
    GET_CATEGORY,
    GET_CATEGORY_BY_ID,
    ADD_CATEGORY,
    CLEAR_CATEGORY,
    UPDATE_CATEGORY,
    CLEAR_UPDATE_CATEGORY
} from '../types';
import { CATEGORY_SERVER } from '../misc';

export function getCategories() {
    const request = axios.get(`${CATEGORY_SERVER}/getcategory`)
        .then(response => response.data);

    return {
        type: GET_CATEGORY,
        payload: request
    }
}

export function getCategoriesByid(id){
    const request = axios.get(`${CATEGORY_SERVER}/getcategorybyid?id=${id}&type=single`)
    .then(response => response.data);

    return {
        type: GET_CATEGORY_BY_ID,
        payload: request
    }
}

export function addCategory(dataToSubmit) {
    const request = axios.post(`${CATEGORY_SERVER}/addcategory`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_CATEGORY,
        payload: request
    }
}

export function clearCategory() {
    return {
        type: CLEAR_CATEGORY,
        payload: ''
    }
}

export function updateCategory(dataToSubmit, id) {
    const request = axios.post(`${CATEGORY_SERVER}/updatecategory?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_CATEGORY,
        payload: request
    }
}

export function clearUpdateCategory() {
    return {
        type: CLEAR_UPDATE_CATEGORY,
        payload: ''
    }
}