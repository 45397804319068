import React from 'react';
// import { useWindowSize } from '../../../widget/windowsize';
import AdminLayout from '../layout/adminpage';

const EmailScreen = (props) => {
    // const size = useWindowSize();

    return (
        <AdminLayout>
            <div className="masterDashboard">
                <div className="row lumisoftnomatch">
                    <div className="col-md-12 col-xs-12">
                        <div className="lumisoftnomatchwrapper">
                            <h1 style={{ fontSize: '70px', marginBottom: '50px', lineHeight: '1' }}>COMING SOON!</h1>
                            <h3 className="textUppercase">Will be updated in next patch</h3>
                            <p className="lead">Please visit other area for now.</p>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default EmailScreen;