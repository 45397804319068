import React from 'react';
import {
    FaBars
} from 'react-icons/fa';

const DrawerToggle = (props) => (
    <button className="toggle_button" onClick={props.click}>
        <FaBars />
    </button>
);

export default DrawerToggle;