import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    useParams
    // Link 
} from 'react-router-dom';
import Carousel from './carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useWindowSize } from '../../widget/windowsize';
import {
    // getGallery, 
    getGalleryByid
} from '../../../store/actions/gallery_action';
import { getEGallery } from '../../../store/actions/egallery_action';

// import Map from "../../widget/google/newmap";

import GoogleMapReact from 'google-map-react';
// import Map from "../../widget/google/map";
// import Marker from "../../widget/google/marker";
// import TransitLayer from "../../widget/google/transitlayer";
import { FaRoad } from 'react-icons/fa';

const PropertyPage = (props) => {
    const dispatch = useDispatch();
    const size = useWindowSize();
    const params = useParams();

    const isMobile = size.width <= 767.98;
    const isTablet = size.width >= 767.99 && size.width <= 835.98;

    const [selected, selectedHandler] = useState({});
    // const [allgallery, allgalleryHandler] = useState([]);
    const [allegallery, allegalleryHandler] = useState([]);
    const [loading, loadingHandler] = useState(false);

    const [photoIndex, photoIndexHandler] = useState(0);
    const [isOpen, isOpenHandler] = useState(false);


    // GOOGLE MAPS
    const [placeIndex, setPlaceIndex] = useState(0);
    const [bound, setBound] = useState({});
    const [transitLayerEnabled, setTransitLayerEnabled] = useState(false);
    const [places, placeHolder] = useState([]);
    const [defaultProps, defaultPropsHandler] = useState();
    // GOOGLE MAPS

    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();

        const getAllData = async () => {
            try {
                loadingHandler(true);
                const id = await params.id;
                let alldatas = await dispatch(getGalleryByid(id), { signal: abortController.signal });
                // let gallery = await dispatch(getGallery(), { signal: abortController.signal });
                let egallery = await dispatch(getEGallery(), { signal: abortController.signal });
                // let allgalleries = await gallery.payload.galleries;
                let allegalleries = await egallery.payload.galleries;
                let selectedone = alldatas.payload.docs[0];
                if (mounted && selectedone) {
                    selectedHandler(selectedone);
                    // allgalleryHandler(allgalleries);
                    allegalleryHandler(allegalleries);
                    placeHolder(
                        [
                            {
                                id: selectedone._id,
                                title: selectedone.name,
                                lat: parseFloat(selectedone.latitude),
                                lng: parseFloat(selectedone.longitude)
                            }
                        ]
                    )
                    defaultPropsHandler(
                        {
                            center: {
                                lat: parseFloat(selectedone.latitude),
                                lng: parseFloat(selectedone.longitude)
                            },
                            zoom: 16
                        }
                    )
                    loadingHandler(false);
                }

            } catch (error) {

            }
        }
        getAllData();
        return () => {
            mounted = false;
            abortController.abort();
        }
    }, [dispatch, params.id])
    // console.log(places, "<<<<<<<<<plcasesees")
    const openImageLightbox = (index) => {
        isOpenHandler(true);
        photoIndexHandler(index);
    }

    const closeImageLightbox = () => {
        isOpenHandler(false);
    }

    const testalert = () => {
        // https://www.google.com/maps/search/?api=1&query=-6.122025,106.600164
    }


    // const [link2, link2Handler] = useState(
    //     {
    //         coords: { lat: 42, lng: 42 }, // required: latitude & longitude at which to display the marker
    //         title: `Life, the Universe and Everything`, // optional
    //         url: `https://wikipedia.org/wiki/Life,_the_Universe_and_Everything`, // optional
    //       }
    // )

    // function addMarkers(map, links) {
    //     console.log(map, "<,,,,,,,mpa")
    //     console.log(links, "<,,,,,,,mplinksa")
    //     links.forEach((link, index) => {
    //         const marker = new window.google.maps.Marker({
    //             map,
    //             position: link.coords,
    //             label: `${index + 1}`,
    //             title: link.title,
    //         })
    //         marker.addListener(`click`, () => {
    //             window.location.href = link.url
    //         })
    //     })
    // }
    // const [mapProps, mapPropsHandler] = useState(
    //     {
    //         options: { center: { lat: 20, lng: 40 }, zoom: 4 },
    //         onMount: addMarkers,
    //         onMountProps: link2,
    //     }
    // )

    // const MemoMap = useCallback(<Map {...mapProps} />, []) 

    const getInfoWindowString = (place) => `
        <div>
            <div style="font-size: 16px;">
                ${place.title}
            </div>
        </div>
    `

    const handleApiLoaded = (map, maps) => {
        const markers = [];
        const infowindows = [];

        places && places.length > 0 && places.forEach((place) => {
            markers.push(new maps.Marker({
                position: {
                    lat: place.lat,
                    lng: place.lng,
                },
                map,
            }));

            infowindows.push(new maps.InfoWindow({
                content: getInfoWindowString(place),
            }));
        });

        markers && markers.length > 0 && markers.forEach((marker, i) => {
            marker.addListener('click', () => {
                infowindows[i].open(map, marker);
            });
        });
    };

    return (
        <section id="gallerypage" style={{ marginBottom: 0, transform: "none", paddingTop: isMobile ? 120 : 150 }}>
            <div className="contentwrap" style={{ transform: "none" }}>
                <div className="section" style={{ marginBottom: isMobile ? '30px' : '60px', paddingBottom: isMobile ? '0px' : '0px', marginTop: isMobile ? '0px' : '0px', paddingTop: isMobile ? '0px' : '0px' }}>
                    <div className="container">
                        <div
                            className="headingblock center"
                            style={{
                                maxWidth: `${size.height - ((size.height * 25) / 100)}px`
                            }}
                        >
                            <h2>{selected.name}</h2>
                            <span>{selected.location}</span>
                        </div>
                    </div>
                </div>
                {
                    isMobile ?
                        <div className="section" style={{ marginBottom: isMobile ? '0px' : '0px', paddingBottom: isMobile ? '0px' : '0px', marginTop: isMobile ? '0px' : '0px', paddingTop: isMobile ? '0px' : '0px' }}>
                            <Carousel selected={selected} />
                        </div>
                        :
                        isTablet ?
                            <div className="section" style={{ marginBottom: isMobile ? '0px' : '0px', paddingBottom: isMobile ? '0px' : '0px', marginTop: isMobile ? '0px' : '0px', paddingTop: isMobile ? '0px' : '0px' }}>
                                <Carousel selected={selected} />
                            </div>
                            :
                            <div className="section" style={{ marginBottom: isMobile ? '0px' : '0px', paddingBottom: isMobile ? '0px' : '0px', marginTop: isMobile ? '0px' : '0px', paddingTop: isMobile ? '0px' : '0px' }}>

                                {
                                    selected.images && selected.images.length > 0 ?
                                        selected.images.map((item, index) => (
                                            <figure key={index}>
                                                <div
                                                    className="sectioninside"
                                                    onClick={() => openImageLightbox(index)}
                                                    style=
                                                    {{
                                                        height: '100%',
                                                        // background: `rgba(234, 234, 234, 1) url('/assets/images/rumah.jpg')`,
                                                        background: `url(${item.url})`,
                                                        backgroundPosition: 'center center',
                                                        backgroundRepeat: 'no-repeat',
                                                        overflow: 'hidden',
                                                        backgroundSize: 'cover'
                                                    }}></div>
                                            </figure>
                                        ))
                                        : null
                                }

                            </div>
                }
                <div className="section" style={{ marginBottom: isMobile ? '60px' : '0px', paddingBottom: isMobile ? '0px' : '60px', marginTop: isMobile ? '0px' : '30px' }}>
                    <div className="container">
                        <div className="row">
                            <div
                                className="lumi-md-8 lumi-xs-12"
                                style={{ padding: 0, marginBottom: isMobile ? 60 : 0 }}
                            >
                                <div
                                    className="headingblock"
                                    style={{ paddingLeft: 15 }}
                                >
                                    <h3>Details</h3>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Pondation (Pondasi)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.pondation}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Structure (Struktur)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.structure}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Wall (dinding)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.wall}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Floor (Lantai)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.floor}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Roof (Atap)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.roof}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Jamb (Kusen)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.jamb}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Ceiling (Plafon)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.plafond}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Electricity (Listrik)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.electricity}
                                        </div>
                                    </div>
                                </div>
                                <div className="lumi-md-6 lumi-xs-12">
                                    <div className="detailsitem">
                                        <div className="detailheader">
                                            Sanitary (Sanitasi)
                                    </div>
                                        <div className="detailvalue">
                                            {selected.sanitation}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="lumi-md-4 lumi-xs-12"
                                style={{ padding: 0, margin: 0 }}
                            >
                                <div
                                    className="headingblock"
                                    style={{ paddingLeft: 15 }}
                                >
                                    <h3>Floor Plans</h3>
                                </div>
                                <div className="lumi-md-12 lumi-xs-12">
                                    {
                                        allegallery && allegallery.length > 0 ?
                                            allegallery.map((item, index) => {
                                                if (item.name.toString().toUpperCase() === selected.name.toString().toUpperCase()) {
                                                    return (
                                                        <div className="denahrumahwrapper" key={index}>
                                                            <div
                                                                className="denahrumah"
                                                                style=
                                                                {{
                                                                    height: "100%",
                                                                    // background: `rgba(234, 234, 234, 1) url('/assets/images/rumah.jpg')`,
                                                                    background: `url(${item.images[0].url})`,
                                                                    backgroundRepeat: 'no-repeat',
                                                                    overflow: 'hidden',
                                                                    backgroundSize: 'contain'
                                                                }}></div>
                                                        </div>
                                                    )
                                                }
                                            })
                                            : null
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="section" style={{ marginBottom: isMobile ? '60px' : '0px', paddingBottom: isMobile ? '0px' : '60px', marginTop: isMobile ? '0px' : '30px' }}>
                    <div className="container">
                        <div
                            className="headingblock"
                        >
                            <h3>Locations</h3>
                        </div>
                        <div className="row">
                            <div className="lumi-md-12 lumi-xs-12">
                                <div style={{ width: '100%', height: 410 }}>
                                    {/* <Map
                                        zoom={10}
                                        center={{ lat: places && places.length > 0 && places[placeIndex] && places[placeIndex].lat, lng: places && places.length > 0 && places[placeIndex] && places[placeIndex].lng }}
                                        events={{ onBoundsChangerd: arg => setBound(arg) }}
                                    >
                                        <TransitLayer enabled={transitLayerEnabled} />
                                        {places.map((m, index) => (
                                            <Marker
                                                key={m.id}
                                                active={placeIndex === index}
                                                title={"Location: " + m.title}
                                                position={{ lat: m.lat, lng: m.lng }}
                                                events={{
                                                    // onClick: () => window.alert(`marker ${index} clicked`)
                                                    onClick: () => testalert()
                                                }}
                                            />
                                        ))}
                                    </Map> */}
                                    {/* {MemoMap}  */}
                                    {
                                        places && places.length > 0 ?
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: "AIzaSyAPACoqABNOS9E09RgqPs1gW5QkQKcNFUU" }}
                                                defaultCenter={defaultProps && defaultProps.center}
                                                defaultZoom={defaultProps && defaultProps.zoom}
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                            />

                                            :
                                            <div className="tableloading">
                                                <div className="lds-ripple"><div></div><div></div></div>
                                            </div>
                                    }
                                </div>
                                {
                                    places && places.length > 0 ?
                                        places.map((m2, index) => {
                                            if (placeIndex === index) {
                                                return (
                                                    <a
                                                        rel="noopener noreferrer"
                                                        href={`https://www.google.com/maps/search/?api=1&query=${m2.lat},${m2.lng}`}
                                                        target="_blank"
                                                        style={{ paddingTop: '30px', cursor: 'pointer' }}
                                                        key={index}
                                                    >
                                                        <button
                                                            className="directionbutton"
                                                        >
                                                            <div className="buttontext">
                                                                Click here for direction
                                                    </div>

                                                            <div className="buttonicon">
                                                                <FaRoad
                                                                    size={28}
                                                                />
                                                            </div>
                                                        </button>
                                                    </a>
                                                )
                                            }
                                        }
                                        )
                                        : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={selected.images[photoIndex].url}
                        nextSrc={selected.images[(photoIndex + 1) % selected.images.length].url}
                        prevSrc={selected.images[(photoIndex + selected.images.length - 1) % selected.images.length].url}
                        imageTitle={photoIndex + 1 + "/" + selected.images.length}
                        onCloseRequest={() => closeImageLightbox()}
                        onMovePrevRequest={() =>
                            photoIndexHandler((photoIndex + selected.images.length - 1) % selected.images.length)
                        }
                        onMoveNextRequest={() =>
                            photoIndexHandler((photoIndex + 1) % selected.images.length)
                        }
                    />
                )}
            </div>
        </section>
    );
};

export default PropertyPage;