import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import FormField from '../../../utils/form/formfield';
import BackDrop from '../../../views/layout/backdrop/tablebackdrop';
import { useWindowSize } from '../../../../widget/windowsize';
import { update, validate, generateData, isFormValid } from '../../../utils/form/formactions';
import {
    getEmaterialstocks
} from '../../../../../store/actions/ematerialstocks_action';
import {
    getFormula,
    addFormula,
    clearFormula,
    updateFormula,
    clearUpdateFormula
    // updateSingleFormula,
    // clearUpdateSingleFormula,
    // deleteSingleFormula
} from '../../../../../store/actions/formula_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome,
    faSignInAlt,
    faListUl,
    faCartArrowDown,
    faUser,
    faBell,
    faHeart,
    faSignOutAlt,
    faCogs,
    faUsers,
    faMoneyBillWave,
    faShippingFast,
    faEnvelopeOpenText,
    faTachometerAlt,
    faChessQueen,
    faShoppingCart,
    faExchangeAlt,
    faMapPin,
    faClock,
    faMapMarkedAlt,
    faDollyFlatbed,
    faFont,
    faBarcode,
    faChevronLeft,
    faAlignLeft,
    faEnvelopeOpen,
    faPhone,
    faCode,
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const EFormulaTableScreen = (props) => {
    const useOutsideAlerter = (ref) => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                searchmodeHandler(false)
            }
        }

        useEffect(() => {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const { getallematerialstocks } = useSelector(state => ({
        getallematerialstocks: state.ematerialstocks
    }))
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    const size = useWindowSize();
    const isMobile = size.width <= 767.98
    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [additionalformError, additionalformErrorHandler] = useState(false);
    const [additionalformSuccess, additionalformSuccessHandler] = useState(false);
    const [additionalerrorMessage, additionalerrorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');
    const [formdata, formdataHandler] = useState({
        name: {
            element: 'input',
            title: 'Name',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        formula: {
            element: 'input',
            title: 'Formula',
            value: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////PLAYER///////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    const [formulaform, formulaformHandler] = useState({
        materialid: {
            element: 'select',
            title: 'Add Material',
            selectedValue: '',
            value: '',
            config: {
                name: 'materialInput',
                options: [],
                placeholder: 'Choose material'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        quantity: {
            element: 'input',
            title: 'Quantity',
            value: "",
            config: {
                name: 'quantityInput',
                type: 'text',
                placeholder: 'Quantity'
            },
            validation: {
                required: true,
                number: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////EDIT/////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    const [formulaformedit, formulaformeditHandler] = useState({
        materialid: {
            element: 'select',
            title: 'Material',
            selectedValue: '',
            value: '',
            config: {
                name: 'materialInput',
                options: [],
                placeholder: 'Choose material'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        quantity: {
            element: 'input',
            title: 'Quantity',
            value: "",
            config: {
                name: 'quantityInput',
                type: 'text',
                placeholder: 'Quantity'
            },
            validation: {
                required: true,
                number: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const [editdatainarray, editdatainarrayHandler] = useState(false);
    const [whichdataedit, whichdataeditinarrayHandler] = useState(0);
    const [whichidinarray, whichidinarrayHandler] = useState("");

    const [allematerial, allematerialHandler] = useState([]);

    const [ematerialsearchresult, ematerialsearchresultHandler] = useState([]);

    const [dontblur, dontblurHandler] = useState(false);
    const [searchmode, searchmodeHandler] = useState(false);
    const [searchMaterial, searchMaterialHandler] = useState("");

    useEffect(() => {
        dispatch(getEmaterialstocks());
    }, [dispatch])

    useEffect(() => {
        if (editformdatastatus && dataselected) {
            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            for (let key in formdata) {
                const newElement = {
                    ...temporaryFormdata[key]
                }
                if(newElement.element === 'select'){
                    newElement.selectedValue = dataselected[key].name;
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                } 
                if(newElement.element === 'input') {
                    newElement.value = dataselected[key];
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
            }
            formdataHandler(temporaryFormdata);
        }
    }, [editformdatastatus, dataselected])

    useEffect(() => {
        if (getallematerialstocks.getEmaterialstocks && getallematerialstocks.getEmaterialstocks.success) {
            let totaldata = getallematerialstocks.getEmaterialstocks.ematerialstocks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allematerialHandler(totaldata);
            ematerialsearchresultHandler(totaldata);
        }
    }, [getallematerialstocks])

    useEffect(() => {
        if (allematerial) {
            const results = allematerial.filter(result =>
                result.name.toLowerCase().includes(searchMaterial.toLowerCase())
            );
            ematerialsearchresultHandler(results);
        }

    }, [allematerial, searchMaterial]);

    ////////////////////////////////////////////////// MATERIAL
    const searchForm = () => {
        searchmodeHandler(true)
    }
    const onMouseEnter = () => {
        dontblurHandler(true)
    }
    const onMouseLeave = () => {
        dontblurHandler(false)
    }
    const hideprofilemenu = () => {
        searchMaterialHandler("");
        searchmodeHandler(false)
    }
    const handleChange = (event) => {
        searchMaterialHandler(event.target.value);
    }
    const selectedItem = (data, whichdata) => {
        var newFormdata = formulaform;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = `${data.name.toUpperCase()}`
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        formulaformHandler(temporaryFormdata);
        searchMaterialHandler("");
        formErrorHandler(false);
        searchmodeHandler(false);
        dontblurHandler(false);
    }
    ////////////////////////////////////////////////// MATERIAL

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        var newFormulaFormdata = formulaformedit;
        const temporaryFormulaFormdata = {
            ...newFormulaFormdata
        }

        const newFormulaElementMaterialid = {
            ...temporaryFormulaFormdata['materialid']
        }

        const newFormulaElementQuantity = {
            ...temporaryFormulaFormdata['quantity']
        }

        newFormulaElementMaterialid.value = "";
        newFormulaElementMaterialid.selectedValue = "";
        newFormulaElementMaterialid.valid = false;
        newFormulaElementMaterialid.touched = false;

        newFormulaElementQuantity.value = "";
        newFormulaElementQuantity.valid = false;
        newFormulaElementQuantity.touched = false;

        temporaryFormulaFormdata['materialid'] = newFormulaElementMaterialid;
        temporaryFormulaFormdata['quantity'] = newFormulaElementQuantity;

        formulaformeditHandler(temporaryFormulaFormdata);
        editdatainarrayHandler(false);
        whichdataeditinarrayHandler(0);
        whichidinarrayHandler("");
        additionalformErrorHandler(false);
        additionalerrorMessageHandler('');
    }

    const showLinks = (type, whichdata, whichfunction) => {
        let list = [];
        if (type && type.length > 0) {
            type.forEach((item) => {
                list.push(item)
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={() => whichfunction(item, whichdata)}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {/* - {item.name.replace(/^\w/, c => c.toUpperCase())} */}
                        - {item.name.toUpperCase()}
                    </span>
                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    const showLinksMaterial = (type, whichdata, whichfunction) => {
        let list = [];
        if (type && type.length > 0) {
            type.forEach((item) => {
                if (item.material && item.material.length > 0) {
                    list.push(item)
                }
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={() => whichfunction(item, whichdata)}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {/* - {item.name.replace(/^\w/, c => c.toUpperCase())} */}
                        - {item.name.toUpperCase()}
                    </span>
                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'vendor');
        formErrorHandler(false)
        formdataHandler(newFormdata)
    }

    const updateFormulaForm = (element) => {
        const newFormdata = update(element, formulaform, 'formulaform');
        formErrorHandler(false)
        formulaformHandler(newFormdata)
    }

    const additionalUpdateForm = (element) => {
        const newFormdata = update(element, formulaformedit, 'formulaformedit');
        additionalformErrorHandler(false)
        formulaformeditHandler(newFormdata)
    }

    const goBackToTable = () => {
        props.history.push('/admin/formula');
    }

    const fetchingnewData = async (id) => {
        let selectedproduct = await dispatch(getFormula());
        let final = selectedproduct.payload.eformulas.find(site => site._id === id);
        props.history.push({
            pathname: `/admin/formula/editdata/${id}`,
            state: {
                dataselected: final,
                editformdata: true
            }
        })
    }

    const submitData = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formdata, 'formula');
        let formIsValid = isFormValid(formdata, 'formula');

        if (formIsValid) {
            dispatch(addFormula(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearFormula());
                    formSuccessHandler(true);
                    props.history.push('/admin/formula');
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'formulaedit');
        let formIsValid2 = isFormValid(formdata, 'formulaedit');
        let dataselectedid = dataselected._id;

        const totaldataToSubmit = {
            ...dataToSubmit2
        }
        if (formIsValid2) {
            dispatch(updateFormula(totaldataToSubmit, dataselectedid)).then(response => {
                if (response.payload.success) {
                    dispatch(clearUpdateFormula());
                    formSuccessHandler(true);
                    setTimeout(() => {
                        fetchingnewData(dataselectedid);
                    }, 1000);
                } else {
                    formErrorHandler(true);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const AddMaterial = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formulaform, 'formula');
        let formIsValid = isFormValid(formulaform, 'formula');

        if (formIsValid) {

            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            const newElement = {
                ...temporaryFormdata['formula']
            }
            const exist = newElement.value.some(data => data.materialid === dataToSubmit.materialid);
            if (exist) {
                formErrorHandler(true);
                props.loadingtableHandler(false);
                errorMessageHandler('MATERIAL EXIST, PLEASE CHOOSE OTHER!');
            } else {
                newElement.value.push(dataToSubmit);
                let validData = validate(newElement, newFormdata);
                newElement.valid = validData[0];
                newElement.validationMessage = validData[1];
                temporaryFormdata['formula'] = newElement;
                formdataHandler(temporaryFormdata);
                var newFormulaFormdata = formulaform;
                const temporaryFormulaFormdata = {
                    ...newFormulaFormdata
                }

                const newFormulaElementMaterialid = {
                    ...temporaryFormulaFormdata['materialid']
                }

                const newFormulaElementQuantity = {
                    ...temporaryFormulaFormdata['quantity']
                }

                newFormulaElementMaterialid.value = "";
                newFormulaElementMaterialid.selectedValue = "";
                newFormulaElementMaterialid.valid = false;
                newFormulaElementMaterialid.touched = false;

                newFormulaElementQuantity.value = "";
                newFormulaElementQuantity.valid = false;
                newFormulaElementQuantity.touched = false;

                temporaryFormulaFormdata['materialid'] = newFormulaElementMaterialid;
                temporaryFormulaFormdata['quantity'] = newFormulaElementQuantity;

                formulaformHandler(temporaryFormulaFormdata);
                searchMaterialHandler("");
                formErrorHandler(false);
                searchmodeHandler(false);
                dontblurHandler(false);
                props.loadingtableHandler(false);
            }
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const editDataInArray = (item, index) => {
        editdatainarrayHandler(true);
        whichdataeditinarrayHandler(index);
        whichidinarrayHandler(item.materialid);
        formErrorHandler(false);
        additionalformErrorHandler(false);
        var newFormulaFormdata = formulaformedit;
        const temporaryFormulaFormdata = {
            ...newFormulaFormdata
        }

        const newFormulaElementMaterialid = {
            ...temporaryFormulaFormdata['materialid']
        }

        const newFormulaElementQuantity = {
            ...temporaryFormulaFormdata['quantity']
        }

        newFormulaElementMaterialid.value = item.materialid;
        newFormulaElementMaterialid.selectedValue = item.name;
        newFormulaElementMaterialid.valid = true;
        newFormulaElementMaterialid.touched = true;

        newFormulaElementQuantity.value = item.quantity;
        newFormulaElementQuantity.valid = true;
        newFormulaElementQuantity.touched = true;

        temporaryFormulaFormdata['materialid'] = newFormulaElementMaterialid;
        temporaryFormulaFormdata['quantity'] = newFormulaElementQuantity;

        formulaformeditHandler(temporaryFormulaFormdata);
    }

    const submitEditDataInArray = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formulaformedit, 'formulaedit');
        let formIsValid = isFormValid(formulaformedit, 'formulaedit');

        if (formIsValid) {
            var newFormulaFormdata = formdata;
            const temporaryFormulaFormdata = {
                ...newFormulaFormdata
            }
            const newFormulaElementMaterialid = {
                ...temporaryFormulaFormdata['formula']
            }

            let datachange = 0;

            for (let key1 in dataToSubmit) {
                if (newFormulaElementMaterialid.value[whichdataedit][key1] !== dataToSubmit[key1]) {
                    datachange = datachange + 1;
                }

                if (Array.isArray(dataToSubmit[key1])) {
                    for (let key2 in dataToSubmit[key1]) {

                    }
                }
            }

            if (datachange > 0) {
                newFormulaElementMaterialid.value[whichdataedit] = dataToSubmit;
                newFormulaElementMaterialid.valid = true;
                newFormulaElementMaterialid.touched = true;

                temporaryFormulaFormdata['formula'] = newFormulaElementMaterialid;
                formdataHandler(temporaryFormulaFormdata);
                whichdataeditinarrayHandler(0);
                whichidinarrayHandler("");
                backdropClickHandler();
                props.loadingtableHandler(false);
            } else {
                additionalformErrorHandler(true);
                props.loadingtableHandler(false);
                additionalerrorMessageHandler('PLEASE CHANGE DATA!');
            }


            // dispatch(updateSingleFormula(dataToSubmit, dataselectedid, whichdataedit)).then(response => {
            //     if (response.payload.success) {
            //         dispatch(clearUpdateSingleFormula());
            //         formSuccessHandler(true);
            //         fetchingnewData(dataselectedid);
            //     } else {
            //         formErrorHandler(true);
            //         props.loadingtableHandler(false);
            //         errorMessageHandler(response.payload.message);
            //     }
            // })
        } else {
            additionalformErrorHandler(true);
            props.loadingtableHandler(false);
            additionalerrorMessageHandler('PLEASE CHANGE DATA!');
        }
    }

    const deleteDataInArray = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        var newFormulaFormdata = formdata;
        const temporaryFormulaFormdata = {
            ...newFormulaFormdata
        }
        const newFormulaElementMaterialid = {
            ...temporaryFormulaFormdata['formula']
        }

        var results = [];
        console.log(newFormulaElementMaterialid.value, "<<< beginning newFormulaElementMaterialid.value");
        var results = newFormulaElementMaterialid.value.filter(data => (data.materialid.indexOf(whichidinarray) === -1));
        console.log(whichidinarray, "<<<<<<<<<whichid in array");
        console.log(results, "<<<< resultsresultsresults");
        newFormulaElementMaterialid.value = results;
        newFormulaElementMaterialid.valid = true;
        newFormulaElementMaterialid.touched = true;

        temporaryFormulaFormdata['formula'] = newFormulaElementMaterialid;
        formdataHandler(temporaryFormulaFormdata);
        whichdataeditinarrayHandler(0);
        whichidinarrayHandler("");
        backdropClickHandler();
        props.loadingtableHandler(false);

        // if (whichidinarray) {
        //     dispatch(deleteSingleFormula(dataselectedid, whichidinarray)).then(response => {
        //         if (response.payload.success) {
        //             formSuccessHandler(true);
        //             fetchingnewData(dataselectedid);
        //         } else {
        //             formErrorHandler(true);
        //             props.loadingtableHandler(false);
        //             errorMessageHandler(response.payload.message);
        //         }
        //     })
        // } else {
        //     formErrorHandler(true);
        //     props.loadingtableHandler(false);
        //     errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        // }
    }

    const addAdditionalData = (data) => (
        <div className="actionTitle">
            <div className="actionTitleText">{data}</div>
        </div>
    )

    return (
        <div className="cardBody formstyle">
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.name.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'name'}
                        formdata={formdata.name}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formulaform.materialid.title}</label>
                <div className={isMobile ? "col-md-7 col-xs-12 paddingRight mb30" : "col-md-7 col-xs-12 paddingRight"}>
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faSortAlphaDown}
                            className="icon agraicon"
                        />
                    </div>
                    {
                        searchmode ?
                            <div ref={wrapperRef}>
                                <div
                                    onBlur={dontblur ? null : hideprofilemenu}
                                    tabIndex={0}
                                >
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name={formulaform.materialid.title}
                                        placeholder={formulaform.materialid.title}
                                        title={formulaform.materialid.config.placeholder}
                                        value={searchMaterial}
                                        onChange={(event) => handleChange(event)}
                                        autoFocus={true}
                                    />

                                </div>
                                <ul
                                    className="dropdownmenu listgroup profilemenu"
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                >
                                    {showLinksMaterial(ematerialsearchresult, 'materialid', selectedItem)}
                                </ul>
                            </div>


                            :
                            <FormField
                                id={'materialid'}
                                formdata={formulaform.materialid}
                                options={allematerial}
                                change={searchForm}
                                myclass={`${allematerial && allematerial.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            />
                    }

                </div>
                <div className="col-md-2 col-xs-9">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faSortNumericUpAlt}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'quantity'}
                        formdata={formulaform.quantity}
                        change={(element) => updateFormulaForm(element)}
                        myclass={'form-control'}
                    />
                </div>
                <div className="col-md-1 col-xs-3">
                    <div className="categoryButtonWrapper">
                        <div
                            className="categoryButton buttonColor"
                            title=""
                            onClick={(event) => AddMaterial(event)}
                        >
                            <FontAwesomeIcon
                                icon={faPlus}
                                className="icon agraicon w18px"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                formdata.formula && formdata.formula.value && formdata.formula.value.length > 0 ?
                    <div className="row pb25">
                        <label className="col-md-2 col-xs-12 colFormLabel">Material list for formula</label>
                        <div className="col-md-10 col-xs-12" style={{
                            paddingTop: 'calc(.375rem + 1px)',
                            paddingBottom: 'calc(.375rem + 1px)',
                            paddingLeft: '15px',
                            paddingRight: '15px'
                        }}>
                            {
                                formdata.formula && formdata.formula.value.map((item, index) => {
                                    // console.log(item, "<<<<<<<item")
                                    // console.log(allematerial, "<<<<<<all e material")
                                    const selectedmaterial = allematerial.filter(result => result._id === item.materialid);
                                    // console.log(selectedmaterial, "<<<<<<selectedmaterial");
                                    let allselectedmaterial = {
                                        materialid: item.materialid,
                                        quantity: item.quantity,
                                        name: selectedmaterial[0] && selectedmaterial[0].name
                                    }
                                    return (
                                        <div
                                            key={index}
                                            className="mb10"
                                        >
                                            <div className="formBlock">
                                                <div
                                                    className="inputbutton form-control"
                                                    onClick={() => editDataInArray(allselectedmaterial, index)}
                                                    style={{ paddingLeft: 10 }}
                                                >
                                                    {`${index + 1}. ${selectedmaterial[0] && selectedmaterial[0].name} (${item.quantity} ${selectedmaterial[0] && selectedmaterial[0].material[0] && selectedmaterial[0].material[0].converter && selectedmaterial[0].material[0].converter.subunit && selectedmaterial[0].material[0].converter.subunit.shortname})`}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    : null

            }

            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                            </div>
                            : null
                }

                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    <div className="doubleButton">
                        <button
                            onClick={editformdatastatus ? (event) => submitEditData(event) : (event) => submitData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            {
                editdatainarray &&
                <BackDrop click={backdropClickHandler} />
            }
            {/******************************************************************
            **************************** EDIT DATA
            ********************************************************************/}
            <div className={editdatainarray ? "rightSideOption open" : "rightSideOption"}>
                {
                    editdatainarray ?
                        addAdditionalData("Edit data")
                        : null

                }
                {
                    editdatainarray ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{formulaformedit.materialid.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faCubes}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <div className="formBlock">
                                        <div
                                            className="inputbutton form-control"
                                        >
                                            {formulaformedit.materialid.selectedValue}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    editdatainarray ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{formulaformedit.quantity.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faCubes}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'quantity'}
                                        formdata={formulaformedit.quantity}
                                        change={(element) => additionalUpdateForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    editdatainarray ?
                        <div className="additionalformstyle">
                            <div className="row" style={{
                                padding: "0 15px"
                            }}>
                                <div className="col-md-6">
                                    <div>
                                        <button
                                            onClick={(event) => deleteDataInArray(event)}
                                            className="formbackButton formsubmitButtonShadow buttonColor"
                                            style={{ backgroundColor: '#FF0000' }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <button
                                            onClick={(event) => submitEditDataInArray(event)}
                                            className="formbackButton formsubmitButtonShadow buttonColor"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                {
                                    additionalformError ?
                                        <div className="errorAddCategory">
                                            {additionalerrorMessage}
                                        </div>
                                        : additionalformSuccess ?
                                            <div className="successAddCategory">
                                                ADD SUCCESS, PLEASE WAIT!
                                            </div>
                                            : null
                                }
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    );
};

export default EFormulaTableScreen;