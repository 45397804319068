import {
    GET_VENDOR,
    GET_VENDOR_BY_ID,
    ADD_VENDOR,
    UPDATE_VENDOR,
    CLEAR_UPDATE_VENDOR,
    CLEAR_VENDOR
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_VENDOR:
            return {
                ...state,
                getVendor: action.payload
            }
        case GET_VENDOR_BY_ID:
            return {
                ...state,
                getVendorByid: action.payload
            }
        case ADD_VENDOR:
            return {
                ...state,
                addVendor: action.payload
            }
        case CLEAR_VENDOR:
            return {
                ...state,
                addVendor: action.payload
            }
        case UPDATE_VENDOR:
            return {
                ...state,
                updateVendor: action.payload
            }
        case CLEAR_UPDATE_VENDOR:
            return {
                ...state,
                updateVendor: action.payload
            }
        default:
            return state;
    }
}