import {
    GET_FORMULA,
    GET_FORMULA_BY_ID,
    ADD_FORMULA,
    UPDATE_FORMULA,
    UPDATE_SINGLE_FORMULA,
    CLEAR_SINGLE_FORMULA,
    CLEAR_UPDATE_FORMULA,
    CLEAR_FORMULA,
    DELETE_SINGLE_FORMULA
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_FORMULA:
            return {
                ...state,
                getFormula: action.payload
            }
        case GET_FORMULA_BY_ID:
            return {
                ...state,
                getFormulaByid: action.payload
            }
        case ADD_FORMULA:
            return {
                ...state,
                addFormula: action.payload
            }
        case CLEAR_FORMULA:
            return {
                ...state,
                addFormula: action.payload
            }
        case UPDATE_FORMULA:
            return {
                ...state,
                updateFormula: action.payload
            }
        case CLEAR_UPDATE_FORMULA:
            return {
                ...state,
                updateFormula: action.payload
            }
        case UPDATE_SINGLE_FORMULA:
            return {
                ...state,
                updateSingleFormula: action.payload
            }
        case CLEAR_SINGLE_FORMULA:
            return {
                ...state,
                updateSingleFormula: action.payload
            }
        case DELETE_SINGLE_FORMULA:
            return {
                ...state,
                deleteSingleFormula: action.payload
            }
        default:
            return state;
    }
}