import React, {
    useState,
    useEffect
} from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
// import moment from 'moment';
import { useParams } from 'react-router-dom';
import FormField from '../../../utils/form/formfield';
// import FileUpload from '../../../utils/form/fileupload';
import { update, generateData, isFormValid, validate } from '../../../utils/form/formactions';
import {
    getEmaterialSinglestocksByid,
    updateEmaterialSinglestocks,
    clearUpdateEmaterialSinglestocks
} from '../../../../../store/actions/ematerialstocks_action';
import {
    getEConverter
} from '../../../../../store/actions/econverter_action';
import {
    getVendors
} from '../../../../../store/actions/vendor_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const Table2nd = (props) => {
    const { getallconverter, getallvendor } = useSelector(state => ({
        getallconverter: state.econverter,
        getallvendor: state.vendor
    }))
    const { dataselected, editformdatastatus } = props;
    const params = useParams();
    const dispatch = useDispatch();

    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    // const [additionalformError, additionalformErrorHandler] = useState(false);
    // const [additionalformSuccess, additionalformSuccessHandler] = useState(false);
    // const [additionalerrorMessage, additionalerrorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    const [allconverter, allconverterHandler] = useState([]);
    const [allvendor, allvendorHandler] = useState([]);

    // const [addnewMaterial, addnewMaterialHandler] = useState(false);

    const [dontblurconverter, dontblurconverterHandler] = useState(false);
    const [dontblurvendor, dontblurvendorHandler] = useState(false);

    const [searchmodeconverter, searchmodeconverterHandler] = useState(false);
    const [searchmodevendor, searchmodevendorHandler] = useState(false);

    const [searchconverter, searchconverterHandler] = useState("");
    const [searchvendor, searchvendorHandler] = useState("");

    const [searchresultconverter, searchresultconverterHandler] = useState([]);
    const [searchresultvendor, searchresultvendorHandler] = useState([]);

    const [formdata, formdataHandler] = useState({
        quantity: {
            element: 'input',
            title: 'Quantity',
            value: '',
            config: {
                name: 'quantityInput',
                type: 'text',
                placeholder: 'Enter quantity'
            },
            validation: {
                required: true,
                number: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        quantityconverted: {
            element: 'input',
            title: 'Total quantity',
            value: '',
            config: {
                name: 'quantityconvertedInput',
                type: 'text',
                placeholder: 'Total Quantity'
            },
            validation: {
                required: false,
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        modal: {
            element: 'input',
            title: 'Modal',
            value: '',
            config: {
                name: 'modalInput',
                type: 'text',
                placeholder: 'Enter modal'
            },
            validation: {
                required: false
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        modaleach: {
            element: 'input',
            title: 'Modal Each',
            value: '',
            config: {
                name: 'modaleachInput',
                type: 'text',
                placeholder: 'Modal Each'
            },
            validation: {
                required: false,
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        converter: {
            element: 'select',
            title: 'Converter',
            selectedValue: '',
            selectedQuantity:'',
            value: '',
            config: {
                name: 'converterInput',
                options: [],
                placeholder: 'Choose converter'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        vendor: {
            element: 'select',
            title: 'Vendor',
            selectedValue: '',
            value: '',
            config: {
                name: 'vendorInput',
                options: [],
                placeholder: 'Choose vendor'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const [imageToUpload, imageToUploadHandler] = useState({
        images: {
            value: []
        }
    });
    const [imageToDelete, imageToDeleteHandler] = useState({
        images: {
            value: []
        }
    });

    useEffect(() => {
        dispatch(getEConverter());
        dispatch(getVendors());
    }, [dispatch])

    useEffect(() => {
        if (getallconverter.getEConverter && getallconverter.getEConverter.success) {
            let totalconverter = getallconverter.getEConverter.econverters.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allconverterHandler(totalconverter);
            searchresultconverterHandler(totalconverter);
        }
        if (getallvendor.getVendor && getallvendor.getVendor.success) {
            let totalconverter = getallvendor.getVendor.vendors.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allvendorHandler(totalconverter);
            searchresultvendorHandler(totalconverter);
        }
    }, [getallconverter, getallvendor])

    useEffect(() => {
        if (editformdatastatus && dataselected) {
            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            for (let key in formdata) {
                const newElement = {
                    ...temporaryFormdata[key]
                }
                if(newElement.element === 'select' && newElement.title === 'Converter'){
                    newElement.selectedValue = dataselected[key].name.replace(/^\w/, c => c.toUpperCase());
                    newElement.selectedQuantity = dataselected[key].quantity 
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                } 
                if(newElement.element === 'select' && newElement.title === 'Vendor'){
                    newElement.selectedValue = dataselected[key].name.replace(/^\w/, c => c.toUpperCase());
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                } 
                if(newElement.element === 'input') {
                    newElement.value = dataselected[key];
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
            }
            formdataHandler(temporaryFormdata);
        }
    }, [editformdatastatus, dataselected])

    useEffect(() => {
        if (allconverter) {
            const results = allconverter.filter(result =>
                result.name.toLowerCase().includes(searchconverter.toLowerCase())
            );
            searchresultconverterHandler(results);
        }

    }, [allconverter, searchconverter]);

    useEffect(() => {
        if (allvendor) {
            const results = allvendor.filter(result =>
                result.name.toLowerCase().includes(searchvendor.toLowerCase())
            );
            searchresultvendorHandler(results);
        }

    }, [allvendor, searchvendor]);

    useEffect(() => {
        if (formdata.quantity.value && formdata.converter.selectedQuantity) {
            const newFormdata = {
                ...formdata
            }
            const newElement = {
                ...newFormdata['quantityconverted']
            }
            newElement.value = formdata.quantity.value * formdata.converter.selectedQuantity;
            newFormdata['quantityconverted'] = newElement;
            formdataHandler(newFormdata);
        }
    }, [formdata.quantity.value, formdata.converter.selectedQuantity])

    useEffect(() => {
        if (formdata.modal.value && formdata.quantityconverted.value) {
            const newFormdata = {
                ...formdata
            }
            const newElement = {
                ...newFormdata['modaleach']
            }
            newElement.value = (formdata.modal.value / formdata.quantityconverted.value).toFixed(2);
            newFormdata['modaleach'] = newElement;
            formdataHandler(newFormdata);
        }
    }, [formdata.modal.value, formdata.quantityconverted.value])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'material');
        formErrorHandler(false)
        formdataHandler(newFormdata)
    }

    ////////////////////////////////////////////////// CONVERTER
    const searchFormConverter = () => {
        searchmodeconverterHandler(true)
    }
    const onMouseEnterConverter = () => {
        dontblurconverterHandler(true)
    }
    const onMouseLeaveConverter = () => {
        dontblurconverterHandler(false)
    }
    const hideprofilemenuConverter = () => {
        searchconverterHandler("");
        searchmodeconverterHandler(false)
    }
    const handleChangeConverter = (event) => {
        searchconverterHandler(event.target.value);
    }
    const selectedItemConverter = (data, whichdata) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = data.name.replace(/^\w/, c => c.toUpperCase());
        newElement.selectedQuantity = data.quantity;
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        formdataHandler(temporaryFormdata);
        searchconverterHandler("");
        formErrorHandler(false);
        searchmodeconverterHandler(false);
        dontblurconverterHandler(false);
    }
    ////////////////////////////////////////////////// CONVERTER

    ////////////////////////////////////////////////// VENDOR
    const searchFormVendor = () => {
        searchmodevendorHandler(true)
    }
    const onMouseEnterVendor = () => {
        dontblurvendorHandler(true)
    }
    const onMouseLeaveVendor = () => {
        dontblurvendorHandler(false)
    }
    const hideprofilemenuVendor = () => {
        searchvendorHandler("");
        searchmodevendorHandler(false)
    }
    const handleChangeVendor = (event) => {
        searchvendorHandler(event.target.value);
    }
    const selectedItemVendor = (data, whichdata) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = data.name.replace(/^\w/, c => c.toUpperCase());
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        formdataHandler(temporaryFormdata);
        searchvendorHandler("");
        formErrorHandler(false);
        searchmodevendorHandler(false);
        dontblurvendorHandler(false);
    }
    ////////////////////////////////////////////////// VENDOR

    const showLinks = (type, whichdata, whichfunction) => {
        let list = [];

        if (type && type.length > 0) {
            type.forEach((item) => {
                list.push(item)
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={() => whichfunction(item, whichdata)}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {item.name.replace(/^\w/, c => c.toUpperCase())}
                    </span>
                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    const goBackToTable = () => {
        props.history.push(`/admin/material/editdata/${params.id}`);
    }

    const fetchingnewData = async (id) => {
        let selectedproduct = await dispatch(getEmaterialSinglestocksByid(id));
        let final = selectedproduct.payload.docs[0];
        props.history.push({
            pathname: `/admin/material/editdata/${params.id}/${params.nextid}`,
            state: {
                dataselected: final,
                editformdata: true
            }
        })
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'ematerialsingleedit');
        let formIsValid2 = isFormValid(formdata, 'ematerialsingleedit');
        let dataselectedid = dataselected._id;
        let datachange = 0;

        for (let key1 in dataToSubmit2) {
            if (key1 === "images") {
                if (imageToUpload.images.value && imageToUpload.images.value.length > 0) {

                } else {
                    if (dataselected[key1] !== dataToSubmit2[key1]) {
                        datachange = datachange + 1;
                    }
                }
            } else {
                if (key1 === "converter" || key1 === "vendor") {
                    if (dataselected[key1]._id !== dataToSubmit2[key1]) {
                        datachange = datachange + 1;
                    }
                } else {
                    if (dataselected[key1].toString() !== dataToSubmit2[key1].toString()) {
                        datachange = datachange + 1;
                    }
                }
            }
        }

        if (datachange > 0) {
            const totaldataToSubmit = {
                ...dataToSubmit2
            }
            if (formIsValid2) {
                totaldataToSubmit.imagesToRemove = imageToDelete.images.value;
                totaldataToSubmit.imagesToAdd = imageToUpload.images.value;
                dispatch(updateEmaterialSinglestocks(totaldataToSubmit, dataselectedid)).then(response => {
                    if (response.payload.success) {
                        dispatch(clearUpdateEmaterialSinglestocks());
                        formSuccessHandler(true);
                        setTimeout(() => {
                            fetchingnewData(dataselectedid);
                        }, 1000);
                        // props.history.push('/admin/vendor');
                    } else {
                        formErrorHandler(true);
                        errorMessageHandler(response.payload.message);
                    }
                })
            } else {
                formErrorHandler(true);
                errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler('CHANGE DATA BEFORE SUBMIT!');
        }
    }

    return (
        <div className="cardBody formstyle"> 
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.vendor.title}</label>
                <div className="col-md-10 col-xs-12 paddingRight">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faSortAlphaDown}
                            className="icon agraicon"
                        />
                    </div>
                    {
                        searchmodevendor ?
                            <div>
                                <div
                                    onBlur={dontblurvendor ? null : hideprofilemenuVendor}
                                    tabIndex={0}
                                >
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name={formdata.vendor.title}
                                        placeholder={formdata.vendor.title}
                                        title={formdata.vendor.config.placeholder}
                                        value={searchvendor}
                                        onChange={(event) => handleChangeVendor(event)}
                                        autoFocus={true}
                                    />

                                </div>
                                <ul
                                    className="dropdownmenu listgroup profilemenu"
                                    onMouseEnter={onMouseEnterVendor}
                                    onMouseLeave={onMouseLeaveVendor}
                                >
                                    {showLinks(searchresultvendor, 'vendor', selectedItemVendor)}
                                </ul>
                            </div>


                            :
                            <FormField
                                id={'vendor'}
                                formdata={formdata.vendor}
                                options={allvendor}
                                change={searchFormVendor}
                                myclass={`${allvendor && allvendor.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            />
                    }
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.quantity.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'quantity'}
                        formdata={formdata.quantity}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.modal.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'modal'}
                        formdata={formdata.modal}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.converter.title}</label>
                <div className="col-md-10 col-xs-12 paddingRight">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faSortAlphaDown}
                            className="icon agraicon"
                        />
                    </div>
                    {
                        searchmodeconverter ?
                            <div>
                                <div
                                    onBlur={dontblurconverter ? null : hideprofilemenuConverter}
                                    tabIndex={0}
                                >
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name={formdata.converter.title}
                                        placeholder={formdata.converter.title}
                                        title={formdata.converter.config.placeholder}
                                        value={searchconverter}
                                        onChange={(event) => handleChangeConverter(event)}
                                        autoFocus={true}
                                    />

                                </div>
                                <ul
                                    className="dropdownmenu listgroup profilemenu"
                                    onMouseEnter={onMouseEnterConverter}
                                    onMouseLeave={onMouseLeaveConverter}
                                >
                                    {showLinks(searchresultconverter, 'converter', selectedItemConverter)}
                                </ul>
                            </div>


                            :
                            <FormField
                                id={'converter'}
                                formdata={formdata.converter}
                                options={allconverter}
                                change={searchFormConverter}
                                myclass={`${allconverter && allconverter.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                            />
                    }
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.quantityconverted.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'quantityconverted'}
                        formdata={formdata.quantityconverted}
                        change={(element) => updateForm(element)}
                        myclass={'form-control disabled'}
                    />
                </div>
            </div>
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.modaleach.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'modaleach'}
                        formdata={formdata.modaleach}
                        change={(element) => updateForm(element)}
                        myclass={'form-control disabled'}
                    />
                </div>
            </div>
            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                            </div>
                            : null
                }

                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    <div className="doubleButton">
                        <button
                            onClick={(event) => submitEditData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table2nd;