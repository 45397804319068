import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import AdminLayout from '../../views/layout/adminpage';
import PageHeader from '../../../widget/pageheader';

import { useWindowSize } from '../../../widget/windowsize';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faLayerGroup, faPercentage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake)


const StockScreen = (props) => {
    const { userprops, getalldata } = useSelector(state => ({
        userprops: state.user,
        getalldata: state.portfolio
    }));
    const size = useWindowSize();
    const isMobile = size.width <= 767.98;
    const dispatch = useDispatch();
    const [loadingtable, loadingtableHandler] = useState(false);
    const [pagename] = useState('Stock List');
    const [addnew] = useState(true);
    const [refresh] = useState(true);
    const [uploadfile] = useState(false);
    const [downloadfile] = useState(false);
    const [showedit, showeditHandler] = useState(false);
    const [selected, selectedHandler] = useState({});
    const [showsettingmenu, showsettingmenuHandler] = useState(false);
    const [breadcrumb] = useState([
        {
            name: 'Dashboard',
            linkTo: '/admin/dashboard',
            public: true,
            faicons: faHome
        },
        {
            name: 'Stock',
            linkTo: `${props.location.pathname}`,
            public: true
        },
        {
            name: pagename,
            linkTo: '',
            public: true
        }
    ]);

    const clickHandler = (link) => {
        props.history.push(link);
    }

    return (
        <AdminLayout>
            <div className="masterDashboard">
                <div className="masterDashboardContainer">
                    <div className="containerfluid tableloading">
                        <div className="pagetitlebox">
                            <PageHeader
                                {...props}
                                pagename={pagename}
                                breadcrumb={breadcrumb}
                                clickHandler={clickHandler}
                            />
                        </div>
                        {
                            loadingtable ? null :
                                <div className="row pb15 mb15 catbutton">
                                    <div className={isMobile ? "col-md-3 col-xs-12 p0 mb15" : "col-md-3 col-xs-12 pl0 mb15"}>
                                        <div className="dashboardlink">
                                            <NavLink
                                                to={'/admin/vendor'}
                                                activeClassName="active"
                                                exact
                                            >
                                                <div className="bgpattern card">
                                                    <div className="cardBody cardBodyTable" style={{ paddingBottom: "1.2rem" }}>
                                                        <div className="ministate">
                                                            <h6 className="ministatetitle">Vendor</h6>
                                                            <FontAwesomeIcon
                                                                icon={faUsers}
                                                                className="icon agraicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={isMobile ? "col-md-3 col-xs-12 p0 mb15" : "col-md-3 col-xs-12 pl0 mb15"}>
                                        <div className="dashboardlink">
                                            <NavLink
                                                to={'/admin/goods'}
                                                activeClassName="active"
                                                exact
                                            >
                                                <div className="bgpattern card">
                                                    <div className="cardBody cardBodyTable" style={{ paddingBottom: "1.2rem" }}>
                                                        <div className="ministate">
                                                            <h6 className="ministatetitle">Goods</h6>
                                                            <FontAwesomeIcon
                                                                icon={faLayerGroup}
                                                                className="icon agraicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    {/* <div className={isMobile ? "col-md-3 col-xs-12 p0 mb15" : "col-md-3 col-xs-12 pl0 mb15"}>
                                        <div className="dashboardlink">
                                            <NavLink
                                                to={'/admin/goods/addnew'}
                                                activeClassName="active"
                                                exact
                                            >
                                                <div className="bgpattern card">
                                                    <div className="cardBody" style={{ paddingBottom: "1.2rem" }}>
                                                        <div className="ministate">
                                                            <h6 className="ministatetitle">Add New Goods</h6>
                                                            <FontAwesomeIcon
                                                                icon={faPercentage}
                                                                className="icon agraicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div> */}
                                </div>
                        }
                        <div className="row">
                            {/* <VendorTable {...props} />
                            <EmaterialStocksTable {...props} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default StockScreen;