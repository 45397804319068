import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import TableMedia from '../../../../widget/tablemedia';
import ThisTable2nd from './thisTable2nd';

import { useWindowSize } from '../../../../widget/windowsize';
import { getEmaterialstocksByid } from '../../../../../store/actions/ematerialstocks_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake)

const Index2nd = (props) => {
    console.log(props, "<<<<<<<<props")
    const { userprops } = useSelector(state => ({
        userprops: state.user
    }));

    const params = useParams();
    const history = useHistory();
    const size = useWindowSize();
    const isMobile = size.width <= 767.98;
    const dispatch = useDispatch();

    /////////////////////////////////////////////////////////////
    const [addnew] = useState(false);
    const [refresh] = useState(true);
    const [uploadfile] = useState(false);
    const [downloadfile] = useState(false);
    const [pagetitletable] = useState(false);
    const [gobackbutton] = useState(false);

    const [showedit, showeditHandler] = useState(false);
    const [selected, selectedHandler] = useState({});
    const [showsettingmenu, showsettingmenuHandler] = useState(false);

    const [loadingtable, loadingtableHandler] = useState(false);
    const [currentPage, currentPageHandler] = useState(1);
    const [pageSize] = useState(5);

    const [dontblur, dontblurHandler] = useState(false);

    const [tablename] = useState('Formula');
    const [tablemenu] = useState([
        {
            head: 'Vendor'
        },
        {
            head: 'Time'
        },
        {
            head: 'Total Qty'
        },
        {
            head: 'Total Modal'
        },
        {
            head: 'Modal each'
        },
    ]);

    const [alldata, alldataHandler] = useState([]);

    const [filterText, filterTextHandler] = useState("");
    const [dataCount, dataCountHandler] = useState("");
    const [mydata, mydataHandler] = useState([]);
    /////////////////////////////////////////////////////////////

    useEffect(() => {
        let mounted = true;
        const abortController = new AbortController();
        const DataselectedCheck = async () => {
            try {
                const alldata = await props.datatoshow;
                let alldatasort = alldata.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                if (mounted && alldatasort) {
                    alldataHandler(alldatasort);
                }
            } catch (error) {

            }
        }
        DataselectedCheck();
        return () => {
            mounted = false;
            abortController.abort();
        }
    }, []);

    useEffect(() => {
        if (alldata && alldata.length > 0) {
            var mydata = [];
            var resultsdata = [];
            var offset = (currentPage - 1) * pageSize;
            resultsdata = alldata.map((item, index) => <ThisTable2nd key={index} item={item} index={index} />)
            mydata = resultsdata.slice(offset, offset + pageSize);
            mydataHandler(mydata);
            dataCountHandler(resultsdata.length);
            loadingtableHandler(false);
        } else {
            setTimeout(() => {
                loadingtableHandler(false);
            }, 1000)
        }
    }, [alldata, currentPage, pageSize])

    useEffect(() => {
        var results = [];
        var resultsdata = [];
        var offset = (currentPage - 1) * pageSize;
        if (alldata) {
            results = alldata.filter(data =>
                (data.vendor.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                    || data.quantityconverted.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                    || data.modal.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1
                    || data.modaleach.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1))
            resultsdata = results.map((item, index) => <ThisTable2nd item={item} index={index} key={index} editData={editData} />)
            var semuadata = [...resultsdata];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            mydataHandler(mydatas);
        }

    }, [filterText, currentPage, alldata, pageSize])

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1)
    }

    const clickHandler = (link) => {
        props.history.push(link);
    }

    const showMenuHandler = () => {
        showsettingmenuHandler(!showsettingmenu);
    }

    const hideMenuHandler = () => {
        showsettingmenuHandler(false);
    }

    const onMouseEnter = () => {
        // console.log("onMouseEnter")
        dontblurHandler(true)
    }

    const onMouseLeave = () => {
        // console.log("onMouseLeave")
        dontblurHandler(false)
    }

    const addNewHandler = () => {
        // console.log("add new handler")
        props.history.push(`${props.location.pathname}/addnew`);
    }

    const onRefreshHandler = async () => {
        try {
            loadingtableHandler(true);
            let id = await params.id;
            let alldatas = await dispatch(getEmaterialstocksByid(id));
            let totaldata = await alldatas.payload.docs[0].material.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            var mydata = [];
            var resultsdata = [];
            var offset = (currentPage - 1) * pageSize;
            alldataHandler(totaldata);
            resultsdata = totaldata.map((item, index) => <ThisTable2nd key={index} item={item} index={index} editData={editData} />)
            mydata = resultsdata.slice(offset, offset + pageSize);
            mydataHandler(mydata);
            dataCountHandler(resultsdata.length);
            loadingtableHandler(false);
        } catch (error) {

        }
    }

    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber)
    }

    const editData = (data) => {
        // console.log(data, "<<<<<<<<<<<<data data dtat")
        showeditHandler(true);
        selectedHandler(data);
    }

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        showeditHandler(false)
        selectedHandler({});
    }

    const editDataHandler = () => {
        history.push({
            pathname: `/admin/material/editdata/${params.id}/${selected._id}`,
            state: {
                dataselected: selected
            }
        })
    }

    const deleteDataHandler = () => {
        console.log("deletedata")
    }

    const goBackToFront = () => {
        props.history.push('/admin/material');
    }

    return (
        <div className="col-md-12 col-xs-12 p0">
            <TableMedia
                pagetitletable={pagetitletable}
                gobackbutton={gobackbutton}
                addnew={addnew}
                refresh={refresh}
                uploadfile={uploadfile}
                downloadfile={downloadfile}
                showsettingmenu={showsettingmenu}
                dontblur={dontblur}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                addNewHandler={addNewHandler}
                hideMenuHandler={hideMenuHandler}
                showMenuHandler={showMenuHandler}
                onRefreshHandler={onRefreshHandler}
                userprops={userprops}
                isMobile={isMobile}
                loadingtable={loadingtable}
                filterText={filterText}
                handleChange={handleChange}
                currentPage={currentPage}
                dataCount={dataCount}
                pageSize={pageSize}
                tablename={tablename}
                tablemenu={tablemenu}
                mydata={mydata}
                pageChanged={pageChanged}
                showedit={showedit}
                editDataHandler={editDataHandler}
                deleteDataHandler={deleteDataHandler}
                backdropClickHandler={backdropClickHandler}
                goBackToFront={goBackToFront}
            />
        </div>
    );
};

export default Index2nd;