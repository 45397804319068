import axios from 'axios';
import {
    GET_ECONVERTER,
    GET_ECONVERTER_BY_ID,
    ADD_ECONVERTER,
    UPDATE_ECONVERTER,
    CLEAR_UPDATE_ECONVERTER,
    CLEAR_ECONVERTER
} from '../types';
import { ECONVERTER_SERVER } from '../misc';

export function getEConverter() {
    const request = axios.get(`${ECONVERTER_SERVER}/geteconverter`)
        .then(response => response.data);

    return {
        type: GET_ECONVERTER,
        payload: request
    }
}

export function getEConverterByid(id){
    const request = axios.get(`${ECONVERTER_SERVER}/geteconverterbyid?id=${id}&type=single`)
    .then(response => response.data);

    return {
        type: GET_ECONVERTER_BY_ID,
        payload: request
    }
}

export function addEConverter(dataToSubmit) {
    const request = axios.post(`${ECONVERTER_SERVER}/addeconverter`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_ECONVERTER,
        payload: request
    }
}

export function clearEConverter() {
    return {
        type: CLEAR_ECONVERTER,
        payload: ''
    }
}

export function updateEConverter(dataToSubmit, id) {
    const request = axios.post(`${ECONVERTER_SERVER}/updateeconverter?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_ECONVERTER,
        payload: request
    }
}

export function clearUpdateEConverter() {
    return {
        type: CLEAR_UPDATE_ECONVERTER,
        payload: ''
    }
}