import {
    GET_EUNIT,
    GET_EUNIT_BY_ID,
    ADD_EUNIT,
    UPDATE_EUNIT,
    CLEAR_UPDATE_EUNIT,
    CLEAR_EUNIT
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_EUNIT:
            return {
                ...state,
                getEunit: action.payload
            }
        case GET_EUNIT_BY_ID:
            return {
                ...state,
                getEunitByid: action.payload
            }
        case ADD_EUNIT:
            return {
                ...state,
                addEunit: action.payload
            }
        case CLEAR_EUNIT:
            return {
                ...state,
                addEunit: action.payload
            }
        case UPDATE_EUNIT:
            return {
                ...state,
                updateEunit: action.payload
            }
        case CLEAR_UPDATE_EUNIT:
            return {
                ...state,
                updateEunit: action.payload
            }
        default:
            return state;
    }
}