import React from 'react';

const ThisConverterTable = (props) => {
    return (
        <tr key={props.index} className="image" onClick={() => props.editData(props.item)}>
            <td>
                {props.item.name}
            </td>
            <td>
                {props.item.mainunit && props.item.mainunit.shortname ? `${props.item.mainunit && props.item.mainunit.name} (${props.item.mainunit && props.item.mainunit.shortname})` : null}
            </td>
            <td>
                {props.item.quantity}
            </td>
            <td>
                {props.item.subunit && props.item.subunit.shortname ? `${props.item.subunit && props.item.subunit.name} (${props.item.subunit && props.item.subunit.shortname})` :null}
            </td>
        </tr>
    );
};

export default ThisConverterTable;