import React from 'react';
import { Switch, Route } from 'react-router-dom';

import '../resources/styles.css';
import Layout from '../layout';
import Auth from './auth';

import Property from './property';
import NoMatch from '../views/nomatch';

const PublicLayout = () => {
    return (
        <Switch>
            <Route path="/property" component={Property} />
            <Route path="/" exact component={Auth(Layout, null)} />
            <Route component={NoMatch} />
        </Switch>
    );
};

export default PublicLayout;