import React, {
    useState,
    useEffect
} from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
// import moment from 'moment';
import FormField from '../../../utils/form/formfield';
import BackDrop from '../../../views/layout/backdrop/tablebackdrop';
import Index2nd from './index2nd';
// import FileUpload from '../../../utils/form/fileupload';
import { update, generateData, isFormValid, validate, convertToRupiah } from '../../../utils/form/formactions';
import {
    getEmaterialstocks,
    addEmaterialstocks,
    clearEmaterialstocks,
    updateEmaterialstocks,
    clearUpdateEmaterialstocks,
    updateSingleEmaterialstocks,
    clearSingleUpdateEmaterialstocks
} from '../../../../../store/actions/ematerialstocks_action';
import {
    getEConverter
} from '../../../../../store/actions/econverter_action';
import {
    getVendors
} from '../../../../../store/actions/vendor_action';

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome, 
    faSignInAlt, 
    faListUl, 
    faCartArrowDown, 
    faUser, 
    faBell, 
    faHeart, 
    faSignOutAlt, 
    faCogs, 
    faUsers, 
    faMoneyBillWave, 
    faShippingFast, 
    faEnvelopeOpenText, 
    faTachometerAlt, 
    faChessQueen, 
    faShoppingCart, 
    faExchangeAlt, 
    faMapPin, 
    faClock, 
    faMapMarkedAlt, 
    faDollyFlatbed, 
    faFont, 
    faBarcode, 
    faChevronLeft, 
    faAlignLeft, 
    faEnvelopeOpen, 
    faPhone, 
    faCode, 
    faMapSigns,
    faHandshake,
    faAddressCard,
    faSortAlphaDown,
    faSortNumericDown,
    faEnvelope,
    faLock,
    faShieldAlt,
    faTimes,
    faPlus,
    faCubes,
    faShareSquare,
    faPercentage,
    faWeightHanging,
    faBoxOpen,
    faCheck,
    faSortNumericUpAlt
)

const EMaterialStocksTableScreen = (props) => {
    // console.log(props, "<<<<<<<main props")
    const { getallconverter, getallvendor } = useSelector(state => ({
        getallconverter: state.econverter,
        getallvendor: state.vendor
    }))
    const { dataselected, editformdatastatus } = props;
    const dispatch = useDispatch();
    const [formError, formErrorHandler] = useState(false);
    const [formSuccess, formSuccessHandler] = useState(false);
    const [errorMessage, errorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    const [additionalformError, additionalformErrorHandler] = useState(false);
    const [additionalformSuccess, additionalformSuccessHandler] = useState(false);
    const [additionalerrorMessage, additionalerrorMessageHandler] = useState('DATA INVALID, PLEASE RECHECK!');

    const [allconverter, allconverterHandler] = useState([]);
    const [allvendor, allvendorHandler] = useState([]);

    const [addnewMaterial, addnewMaterialHandler] = useState(false);

    const [dontblurconverter, dontblurconverterHandler] = useState(false);
    const [dontblurvendor, dontblurvendorHandler] = useState(false);

    const [searchmodeconverter, searchmodeconverterHandler] = useState(false);
    const [searchmodevendor, searchmodevendorHandler] = useState(false);

    const [searchconverter, searchconverterHandler] = useState("");
    const [searchvendor, searchvendorHandler] = useState("");

    const [searchresultconverter, searchresultconverterHandler] = useState([]);
    const [searchresultvendor, searchresultvendorHandler] = useState([]);

    const [formdata, formdataHandler] = useState({
        name: {
            element: 'input',
            title: 'Name',
            value: '',
            config: {
                name: 'nameInput',
                type: 'text',
                placeholder: 'Enter name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        material: {
            element: 'input',
            title: 'Material',
            value: [],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        images: {
            element: 'input',
            title: 'Images',
            value: [],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    const [materialform, materialformHandler] = useState({
        quantity: {
            element: 'input',
            title: 'Quantity',
            value: '',
            config: {
                name: 'quantityInput',
                type: 'text',
                placeholder: 'Enter quantity'
            },
            validation: {
                required: true,
                number: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        quantityconverted: {
            element: 'input',
            title: 'Total quantity',
            value: '',
            config: {
                name: 'quantityconvertedInput',
                type: 'text',
                placeholder: 'Total Quantity'
            },
            validation: {
                required: false,
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        modal: {
            element: 'input',
            title: 'Modal',
            value: '',
            config: {
                name: 'modalInput',
                type: 'text',
                placeholder: 'Enter modal'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        modaleach: {
            element: 'input',
            title: 'Modal Each',
            value: '',
            config: {
                name: 'modaleachInput',
                type: 'text',
                placeholder: 'Modal Each'
            },
            validation: {
                required: false,
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        converter: {
            element: 'select',
            title: 'Converter',
            selectedValue: '',
            selectedQuantity: '',
            value: '',
            config: {
                name: 'converterInput',
                options: [],
                placeholder: 'Choose converter'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        vendor: {
            element: 'select',
            title: 'Vendor',
            selectedValue: '',
            value: '',
            config: {
                name: 'vendorInput',
                options: [],
                placeholder: 'Choose vendor'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const [imageToUpload, imageToUploadHandler] = useState({
        images: {
            value: []
        }
    });
    const [imageToDelete, imageToDeleteHandler] = useState({
        images: {
            value: []
        }
    });

    useEffect(() => {
        dispatch(getEConverter());
        dispatch(getVendors());
    }, [dispatch])

    useEffect(() => {
        if (getallconverter.getEConverter && getallconverter.getEConverter.success) {
            let totalconverter = getallconverter.getEConverter.econverters.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allconverterHandler(totalconverter);
            searchresultconverterHandler(totalconverter);
        }
        if (getallvendor.getVendor && getallvendor.getVendor.success) {
            let totalconverter = getallvendor.getVendor.vendors.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            allvendorHandler(totalconverter);
            searchresultvendorHandler(totalconverter);
        }
    }, [getallconverter, getallvendor])

    useEffect(() => {
        if (editformdatastatus && dataselected) {
            var newFormdata = formdata;
            const temporaryFormdata = {
                ...newFormdata
            }

            for (let key in formdata) {
                const newElement = {
                    ...temporaryFormdata[key]
                }
                if(newElement.element === 'select'){
                    newElement.selectedValue = dataselected[key].name;
                    newElement.value = dataselected[key]._id;
                    newElement.config.placeholder = dataselected[key].name;
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                } 
                if(newElement.element === 'input') {
                    newElement.value = dataselected[key];
                    newElement.valid = true;
                    newElement.touched = true;
                    temporaryFormdata[key] = newElement;
                }
            }
            formdataHandler(temporaryFormdata);
        }
    }, [editformdatastatus, dataselected])

    useEffect(() => {
        if (allconverter) {
            const results = allconverter.filter(result =>
                result.name.toLowerCase().includes(searchconverter.toLowerCase())
            );
            searchresultconverterHandler(results);
        }

    }, [allconverter, searchconverter]);

    useEffect(() => {
        if (allvendor) {
            const results = allvendor.filter(result =>
                result.name.toLowerCase().includes(searchvendor.toLowerCase())
            );
            searchresultvendorHandler(results);
        }

    }, [allvendor, searchvendor]);

    useEffect(() => {
        if (materialform.quantity.value && materialform.converter.selectedQuantity) {
            const newFormdata = {
                ...materialform
            }
            const newElement = {
                ...newFormdata['quantityconverted']
            }
            newElement.value = materialform.quantity.value * materialform.converter.selectedQuantity;
            newFormdata['quantityconverted'] = newElement;
            materialformHandler(newFormdata);
        }
    }, [materialform.quantity.value, materialform.converter.selectedQuantity])

    useEffect(() => {
        if (materialform.modal.value && materialform.quantityconverted.value) {
            const newFormdata = {
                ...materialform
            }
            const newElement = {
                ...newFormdata['modaleach']
            }
            newElement.value = (materialform.modal.value / materialform.quantityconverted.value).toFixed(2);
            newFormdata['modaleach'] = newElement;
            materialformHandler(newFormdata);
        }
    }, [materialform.modal.value, materialform.quantityconverted.value])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, 'material');
        formErrorHandler(false)
        formdataHandler(newFormdata)
    }

    const updateMaterialForm = (element) => {
        const newFormdata = update(element, materialform, 'addmaterial');
        formErrorHandler(false)
        materialformHandler(newFormdata)
    }

    ////////////////////////////////////////////////// CONVERTER
    const searchFormConverter = () => {
        searchmodeconverterHandler(true)
    }
    const onMouseEnterConverter = () => {
        dontblurconverterHandler(true)
    }
    const onMouseLeaveConverter = () => {
        dontblurconverterHandler(false)
    }
    const hideprofilemenuConverter = () => {
        searchconverterHandler("");
        searchmodeconverterHandler(false)
    }
    const handleChangeConverter = (event) => {
        searchconverterHandler(event.target.value);
    }
    const selectedItemConverter = (data, whichdata) => {
        var newFormdata = materialform;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = data.name.replace(/^\w/, c => c.toUpperCase());
        newElement.selectedQuantity = data.quantity;
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        materialformHandler(temporaryFormdata);
        searchconverterHandler("");
        formErrorHandler(false);
        searchmodeconverterHandler(false);
        dontblurconverterHandler(false);
    }
    ////////////////////////////////////////////////// CONVERTER

    ////////////////////////////////////////////////// VENDOR
    const searchFormVendor = () => {
        searchmodevendorHandler(true)
    }
    const onMouseEnterVendor = () => {
        dontblurvendorHandler(true)
    }
    const onMouseLeaveVendor = () => {
        dontblurvendorHandler(false)
    }
    const hideprofilemenuVendor = () => {
        searchvendorHandler("");
        searchmodevendorHandler(false)
    }
    const handleChangeVendor = (event) => {
        searchvendorHandler(event.target.value);
    }
    const selectedItemVendor = (data, whichdata) => {
        var newFormdata = materialform;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[whichdata]
        }

        newElement.value = data._id;
        newElement.selectedValue = data.name.replace(/^\w/, c => c.toUpperCase());
        let validData = validate(newElement, newFormdata);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
        temporaryFormdata[whichdata] = newElement;
        materialformHandler(temporaryFormdata);
        searchvendorHandler("");
        formErrorHandler(false);
        searchmodevendorHandler(false);
        dontblurvendorHandler(false);
    }
    ////////////////////////////////////////////////// VENDOR

    const showLinks = (type, whichdata, whichfunction) => {
        let list = [];

        if (type && type.length > 0) {
            type.forEach((item) => {
                list.push(item)
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={() => whichfunction(item, whichdata)}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {item.name.replace(/^\w/, c => c.toUpperCase())}
                    </span>
                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    const previewFile = (files, multifiles, uploadname) => {
        formErrorHandler(false)
        const arrOfPromises = files.map((file, i) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    file.id = i;
                    file.url = e.target.result;
                    resolve(file);
                }
                reader.readAsDataURL(file);
            });

        });

        Promise.all(arrOfPromises).then(imagesToAdd => {
            let imagesToAddSort = imagesToAdd.sort((a, b) => {
                if (b.name > a.name)
                    return -1;
                if (b.name < a.name)
                    return 1;
                return 0;
            });

            if (editformdatastatus) {
                const newimageToUpload = { ...imageToUpload }
                const elementImageToUpload = {
                    ...newimageToUpload[uploadname]
                }
                for (let zxc = 0; zxc < imagesToAddSort.length; zxc++) {
                    elementImageToUpload.value.push(imagesToAddSort[zxc])
                }
                newimageToUpload[uploadname] = elementImageToUpload
                imageToUploadHandler(newimageToUpload);
            } else {
                var newFormdata = formdata;
                const temporaryFormdata = {
                    ...newFormdata
                }

                const newElement = {
                    ...temporaryFormdata[uploadname]
                }
                if (multifiles) {
                    for (let zxc = 0; zxc < imagesToAddSort.length; zxc++) {
                        newElement.value.push(imagesToAddSort[zxc])
                    }
                } else {
                    if (imagesToAddSort.length < 2) {
                        newElement.value = imagesToAddSort
                    } else {
                        formErrorHandler(true)
                        errorMessageHandler("Please upload one image only")
                    }
                }
                temporaryFormdata[uploadname] = newElement;
                formdataHandler(temporaryFormdata);
            }

        }).catch(err => console.log('err loading images', err));
    }
    const onRemove = (item, uploadname) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (editformdatastatus) {
            var newFormdata2 = imageToDelete;
            const temporaryFormdata2 = {
                ...newFormdata2
            }

            const newElement2 = {
                ...temporaryFormdata2[uploadname]
            }
            const updatedImages = newElement.value.filter(i => i.url !== item.url);
            newElement2.value.push(item.public_id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            temporaryFormdata2[uploadname] = newElement2;
            formdataHandler(temporaryFormdata);
            imageToDeleteHandler(temporaryFormdata2);
        } else {
            const updatedImages = newElement.value.filter(i => i.id !== item.id);
            newElement.value = updatedImages;
            temporaryFormdata[uploadname] = newElement;
            formdataHandler(temporaryFormdata);
        }
    }

    const showSoonToUpdateImages = (uploadname) => {
        var newFormdata = formdata;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => onRemove(item, uploadname)}
                            >
                                <img src={item.url} alt="" />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    const showimagesWillbeRemove = (item, uploadname) => {
        var newFormdata = imageToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }

        const updatedImages = newElement.value.filter(i => i.id !== item.id);
        newElement.value = updatedImages;
        temporaryFormdata[uploadname] = newElement;
        imageToUploadHandler(temporaryFormdata);
    }

    const showimagesWillbeUpdate = (uploadname) => {
        var newFormdata = imageToUpload;
        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[uploadname]
        }
        if (newElement.value && newElement.value.length > 0) {
            return (
                <div className="showThumbnail">
                    {
                        newElement.value.map((item, i) => (
                            <div
                                key={i}
                                className="dropzoneImageWrapper"
                                onClick={() => showimagesWillbeRemove(item, uploadname)}
                            >
                                <img src={item.url} alt="" />

                                <div className="dropzoneImageInner">
                                    {
                                        item.name ? <span>Name: {item.name}</span> : null
                                    }
                                    {
                                        item.size ? <span>Size: {item.size / 1000} Kb</span> : null
                                    }
                                    {
                                        item.type ? <span>Type: {item.type}</span> : null
                                    }
                                </div>


                            </div>
                        ))
                    }
                </div>
            )

        }
    }

    const goBackToTable = () => {
        props.history.push('/admin/material');
    }

    const fetchingnewData = async (id) => {
        if (editformdatastatus) {
            let selectedproduct = await dispatch(getEmaterialstocks());
            let final = selectedproduct.payload.ematerialstocks.find(site => site._id === id);
            props.history.push({
                pathname: `/admin/material/editdata/${id}`,
                state: {
                    dataselected: final,
                    editformdata: true
                }
            })
        } else {
            props.history.push('/admin/material');
        }
    }

    const submitData = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(formdata, 'Ematerialstocks');
        let formIsValid = isFormValid(formdata, 'Ematerialstocks');

        if (formIsValid) {
            dispatch(addEmaterialstocks(dataToSubmit)).then(response => {
                if (response.payload.success) {
                    dispatch(clearEmaterialstocks());
                    formSuccessHandler(true);
                    props.history.push('/admin/material');
                } else {
                    formErrorHandler(true);
                    props.loadingtableHandler(false);
                    errorMessageHandler(response.payload.message);
                }
            })
        } else {
            formErrorHandler(true);
            props.loadingtableHandler(false);
            errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    const submitEditData = (event) => {
        event.preventDefault();

        let dataToSubmit2 = generateData(formdata, 'vendoredit');
        let formIsValid2 = isFormValid(formdata, 'vendoredit');
        let dataselectedid = dataselected._id;
        let datachange = 0;

        for (let key1 in dataToSubmit2) {
            if (key1 === "images") {
                if (imageToUpload.images.value && imageToUpload.images.value.length > 0) {

                } else {
                    if (dataselected[key1] !== dataToSubmit2[key1]) {
                        datachange = datachange + 1;
                    }
                }
            } else {
                if (dataselected[key1] !== dataToSubmit2[key1]) {
                    datachange = datachange + 1;
                }
            }
        }
        if (datachange > 0) {
            const totaldataToSubmit = {
                ...dataToSubmit2
            }
            if (formIsValid2) {
                totaldataToSubmit.imagesToRemove = imageToDelete.images.value;
                totaldataToSubmit.imagesToAdd = imageToUpload.images.value;
                dispatch(updateEmaterialstocks(totaldataToSubmit, dataselectedid)).then(response => {
                    if (response.payload.success) {
                        dispatch(clearUpdateEmaterialstocks());
                        formSuccessHandler(true);
                        setTimeout(() => {
                            fetchingnewData(dataselectedid);
                        }, 1000);
                        // props.history.push('/admin/vendor');
                    } else {
                        formErrorHandler(true);
                        errorMessageHandler(response.payload.message);
                    }
                })
            } else {
                formErrorHandler(true);
                errorMessageHandler('DATA INVALID, PLEASE RECHECK!');
            }
        } else {
            formErrorHandler(true);
            errorMessageHandler('CHANGE DATA BEFORE SUBMIT!');
        }
    }

    const AddMaterial = () => {
        addnewMaterialHandler(true);
        additionalerrorMessageHandler("");
        additionalformErrorHandler(false);
        additionalformSuccessHandler(false);
    }

    const addAdditionalData = (data) => (
        <div className="actionTitle">
            <div className="actionTitleText">{data}</div>
        </div>
    )

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        var newFormulaFormdata = materialform;
        const temporaryFormulaFormdata = {
            ...newFormulaFormdata
        }

        for (let key in temporaryFormulaFormdata) {
            if (key === 'vendor') {
                const newFormulaElementMaterialid = {
                    ...temporaryFormulaFormdata[key]
                }
                newFormulaElementMaterialid.value = "";
                newFormulaElementMaterialid.selectedValue = "";
                newFormulaElementMaterialid.valid = false;
                newFormulaElementMaterialid.touched = false;
                temporaryFormulaFormdata[key] = newFormulaElementMaterialid;
            } else if (key === 'converter') {
                const newFormulaElementMaterialid = {
                    ...temporaryFormulaFormdata[key]
                }
                newFormulaElementMaterialid.value = "";
                newFormulaElementMaterialid.selectedValue = "";
                newFormulaElementMaterialid.selectedQuantity = "";
                newFormulaElementMaterialid.valid = false;
                newFormulaElementMaterialid.touched = false;
                temporaryFormulaFormdata[key] = newFormulaElementMaterialid;
            } else {
                const newFormulaElementMaterialid = {
                    ...temporaryFormulaFormdata[key]
                }
                newFormulaElementMaterialid.value = "";
                newFormulaElementMaterialid.valid = false;
                newFormulaElementMaterialid.touched = false;
                temporaryFormulaFormdata[key] = newFormulaElementMaterialid;
            }
        }
        materialformHandler(temporaryFormulaFormdata);
        addnewMaterialHandler(false);
        additionalerrorMessageHandler("");
        additionalformErrorHandler(false);
        additionalformSuccessHandler(false);
    }

    const submitEditDataInArray = (event) => {
        event.preventDefault();
        props.loadingtableHandler(true);

        let dataToSubmit = generateData(materialform, 'materialform');
        let formIsValid = isFormValid(materialform, 'materialform');
        let dataselectedid = dataselected._id;

        if (formIsValid) {
            dispatch(updateSingleEmaterialstocks(dataToSubmit, dataselectedid)).then(response => {
                if (response.payload.success) {
                    dispatch(clearSingleUpdateEmaterialstocks());
                    additionalformSuccessHandler(true);
                    fetchingnewData(dataselectedid);
                    // props.history.push('/admin/vendor');
                } else {
                    additionalformErrorHandler(true);
                    props.loadingtableHandler(false);
                    additionalerrorMessageHandler(response.payload.message);
                }
            })
        } else {
            additionalformErrorHandler(true);
            props.loadingtableHandler(false);
            additionalerrorMessageHandler('DATA INVALID, PLEASE RECHECK!');
        }
    }

    return (
        <div className="cardBody formstyle">
            <div className="row pb25">
                <label className="col-md-2 col-xs-12 colFormLabel">{formdata.name.title}</label>
                <div className="col-md-10 col-xs-12">
                    <div className="iconPosition">
                        <FontAwesomeIcon
                            icon={faFont}
                            className="icon agraicon"
                        />
                    </div>
                    <FormField
                        id={'name'}
                        formdata={formdata.name}
                        change={(element) => updateForm(element)}
                        myclass={'form-control'}
                    />
                </div>
            </div>
            {
                editformdatastatus ?
                    <div className="row pb25">
                        <label className="col-md-2 col-xs-9 colFormLabel">{formdata.material.title}</label>
                        <div className="col-md-10 col-xs-3">
                            <div
                                className="categoryButtonWrapper"
                                style={{ left: '15px', right: 'unset' }}
                            >
                                <div
                                    className="categoryButton buttonColor"
                                    title=""
                                    onClick={() => AddMaterial()}
                                >
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="icon agraicon w18px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                editformdatastatus && dataselected && dataselected.material ?
                    <div className="row pb25">
                        <Index2nd 
                            {...props}
                            datatoshow={dataselected.material}
                        />
                    </div>
                    // <div className="row pb25">
                    //     <label className="col-md-2 col-xs-9 colFormLabel">Material Lists</label>
                    //     <div className="col-md-10 col-xs-3">
                    //         {
                    //             formdata.material && formdata.material.value.map((item, index) => (
                    //                 <div className="formBlock" key={index}>
                    //                     <input
                    //                         className="form-control"
                    //                         name="materialLists"
                    //                         type="text"
                    //                         value={`${moment(item.createdAt).format('DD MMM YYYY')} : ${item.quantity} ${item.converter.mainunit.shortname} (${item.quantityconverted} ${item.converter.subunit.shortname}), Rp.${convertToRupiah(item.modal)} (Rp.${convertToRupiah(item.modaleach)} / each)`}
                    //                         disabled
                    //                     />

                    //                 </div>
                    //             ))
                    //         }
                    //     </div>
                    // </div>
                    : null
            }
            {/* <div className="row pb25">
                <label className="col-md-12 col-xs-12 colFormLabel">{formdata.images.title}</label>
                <div className="col-md-12 col-xs-12">
                    <FileUpload
                        id={'fileupload'}
                        reset={formSuccess}
                        myclass={'form-control'}
                        onFilesAlreadyAdded={previewFile}
                        multifiles={false}
                        uploadname={'images'}
                    />
                    {
                        showSoonToUpdateImages('images')
                    }
                    {
                        editformdatastatus ? showimagesWillbeUpdate('images') : null
                    }
                </div>
            </div> */}
            <div className="row pb25">
                {
                    formError ?
                        <div className="errorSubmit">
                            {errorMessage}
                        </div>
                        : formSuccess ?
                            <div className="successSubmit">
                                PROCESSING, PLEASE WAIT!
                            </div>
                            : null
                }

                <div className="formSubmitButtonWithBack">
                    <div
                        className="formbackButton formsubmitButtonShadow buttonColor"
                        title=""
                        onClick={() => goBackToTable()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="icon agraicon w18px"
                        />
                    </div>
                    <div className="doubleButton">
                        <button
                            onClick={editformdatastatus ? (event) => submitEditData(event) : (event) => submitData(event)}
                            className="formsubmitButton formsubmitButtonShadow buttonColor"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            {
                addnewMaterial &&
                <BackDrop click={backdropClickHandler} />
            }
            <div className={addnewMaterial ? "rightSideOption open" : "rightSideOption"}>
                {
                    addnewMaterial ?
                        addAdditionalData("Add New Material")
                        : null

                }
                {
                    addnewMaterial ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{materialform.vendor.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faSortAlphaDown}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    {
                                        searchmodevendor ?
                                            <div>
                                                <div
                                                    onBlur={dontblurvendor ? null : hideprofilemenuVendor}
                                                    tabIndex={0}
                                                >
                                                    <input
                                                        autoCapitalize="none"
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        className="tableSearch"
                                                        type="text"
                                                        name={materialform.vendor.title}
                                                        placeholder={materialform.vendor.title}
                                                        title={materialform.vendor.config.placeholder}
                                                        value={searchvendor}
                                                        onChange={(event) => handleChangeVendor(event)}
                                                        autoFocus={true}
                                                    />

                                                </div>
                                                <ul
                                                    className="dropdownmenu listgroup profilemenu"
                                                    onMouseEnter={onMouseEnterVendor}
                                                    onMouseLeave={onMouseLeaveVendor}
                                                >
                                                    {showLinks(searchresultvendor, 'vendor', selectedItemVendor)}
                                                </ul>
                                            </div>


                                            :
                                            <FormField
                                                id={'vendor'}
                                                formdata={materialform.vendor}
                                                options={allvendor}
                                                change={searchFormVendor}
                                                myclass={`${allvendor && allvendor.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addnewMaterial ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{materialform.quantity.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faCubes}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'quantity'}
                                        formdata={materialform.quantity}
                                        change={(element) => updateMaterialForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addnewMaterial ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{materialform.modal.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faCubes}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'modal'}
                                        formdata={materialform.modal}
                                        change={(element) => updateMaterialForm(element)}
                                        myclass={'form-control'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addnewMaterial ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{materialform.converter.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faSortAlphaDown}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    {
                                        searchmodeconverter ?
                                            <div>
                                                <div
                                                    onBlur={dontblurconverter ? null : hideprofilemenuConverter}
                                                    tabIndex={0}
                                                >
                                                    <input
                                                        autoCapitalize="none"
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        className="tableSearch"
                                                        type="text"
                                                        name={materialform.converter.title}
                                                        placeholder={materialform.converter.title}
                                                        title={materialform.converter.config.placeholder}
                                                        value={searchconverter}
                                                        onChange={(event) => handleChangeConverter(event)}
                                                        autoFocus={true}
                                                    />

                                                </div>
                                                <ul
                                                    className="dropdownmenu listgroup profilemenu"
                                                    onMouseEnter={onMouseEnterConverter}
                                                    onMouseLeave={onMouseLeaveConverter}
                                                >
                                                    {showLinks(searchresultconverter, 'converter', selectedItemConverter)}
                                                </ul>
                                            </div>


                                            :
                                            <FormField
                                                id={'converter'}
                                                formdata={materialform.converter}
                                                options={allconverter}
                                                change={searchFormConverter}
                                                myclass={`${allconverter && allconverter.length > 0 ? "inputbutton form-control" : "inputbutton form-control disabled"}`}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addnewMaterial ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{materialform.quantityconverted.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faCubes}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'quantityconverted'}
                                        formdata={materialform.quantityconverted}
                                        myclass={'form-control disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addnewMaterial ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px 25px 15px"
                            }}>
                                <label
                                    className="col-md-12 col-xs-12 colFormLabel"
                                    style={{
                                        paddingBottom: "5px"
                                    }}
                                >{materialform.modaleach.title}</label>
                                <div className="col-md-12 col-xs-12">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faCubes}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <FormField
                                        id={'modaleach'}
                                        formdata={materialform.modaleach}
                                        myclass={'form-control disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    addnewMaterial ?
                        <div className="additionalformstyle pb20">
                            <div className="row" style={{
                                padding: "0 15px"
                            }}>
                                {
                                    formdata.material && formdata.material.length > 0 ?
                                        <div className="col-md-6">
                                            <div>
                                                <button
                                                    // onClick={(event) => deleteDataInArray(event)}
                                                    className="formbackButton formsubmitButtonShadow buttonColor"
                                                    style={{ backgroundColor: '#FF0000' }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    additionalformError ?
                                        <div className="errorAddCategory">
                                            {additionalerrorMessage}
                                        </div>
                                        : additionalformSuccess ?
                                            <div className="successAddCategory">
                                                ADD SUCCESS, PLEASE WAIT!
                                            </div>
                                            : null
                                }
                                <div className="col-md-6">
                                    <div>
                                        <button
                                            onClick={(event) => submitEditDataInArray(event)}
                                            className="formbackButton formsubmitButtonShadow buttonColor"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    );
};

export default EMaterialStocksTableScreen;