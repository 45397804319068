import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loading } from '../../store/actions/loading_action';
import { getGallery } from '../../store/actions/gallery_action';
import { getWebsite } from '../../store/actions/website_action';
import Loadingscreen from '../views/loadingscreen';

export default function (ComposedClass, reload, adminRoute) {
    const AuthenticationClientCheck = (props) => {
        const dispatch = useDispatch();
        const [loadingwebsite, loadingwebsiteHandler] = useState(false);
        const [allgallery, allgalleryHandler] = useState([]);
        const [allwebsite, allwebsiteHandler] = useState([]);
        useEffect(() => {
            let mounted = true;
            const abortController = new AbortController();

            async function authCheck() {
                try {
                    loadingwebsiteHandler(dispatch(loading(true)));
                    let alldatas = await dispatch(getGallery(), { signal: abortController.signal });
                    let totaldata = alldatas.payload.galleries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                    let allwebsites = await dispatch(getWebsite(), { signal: abortController.signal });
                    let totalwebsite = allwebsites.payload.websites.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                    if (mounted) {
                        allgalleryHandler(totaldata);
                        allwebsiteHandler(totalwebsite);
                        loadingwebsiteHandler(dispatch(loading(false)));
                    }
                } catch (error) {

                }

            }
            authCheck();
        }, [dispatch, props.history])
        // console.log(loadingwebsite, "<<<<<<loading")
        if (loadingwebsite.status) {
            return (
                <Loadingscreen />
            )
        } else {
            return (
                <ComposedClass {...props} allgallery={allgallery} allwebsite={allwebsite} />
            );
        }
    }
    return AuthenticationClientCheck;
}

