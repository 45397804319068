import React from 'react';

import BackDrop from '../lumisoft/views/layout/backdrop/tablebackdrop';
import Pagination from './pagination';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faSearch, faEdit, faTimesCircle, faLockOpen, faPlus, faCog, faRedoAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import {
    // faEnvelope, 
    // faListAlt, 
    faAddressCard
} from '@fortawesome/free-regular-svg-icons';

library.add(faHome, faSignInAlt, faListUl, faCartArrowDown, faUser, faBell, faHeart, faSignOutAlt, faCogs, faUsers, faMoneyBillWave, faShippingFast, faEnvelopeOpenText, faTachometerAlt, faChessQueen, faShoppingCart, faExchangeAlt, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faSearch, faEdit, faTimesCircle, faLockOpen, faAddressCard, faMapPin, faClock, faMapMarkedAlt, faDollyFlatbed, faHandshake)


const TableMedia = (props) => {
    return (
        <div className="col-md-12 pl0 pt0 pr0 mb50">
            {
                props.loadingtable ? null : props.pagetitletable ? <h4 className="pageTitleTable">{props.tablename} Table</h4> : null
            }
            {
                props.loadingtable ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="lds-ripple"><div></div><div></div></div>
                    </div>
                    :
                    props.mydata && props.mydata.length > 0 ?
                        <div className="card withpagination">
                            <div className="cardTitle">
                                <div className="searchContainer">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name="Search"
                                        placeholder="Search"
                                        title="Type in a name"
                                        value={props.filterText}
                                        onChange={(event) => props.handleChange(event)}
                                    />
                                </div>
                                <div className="pagetitlebutton">
                                    {
                                        props.refresh ?
                                            <div
                                                className="pagetitlethebutton waveseffect mr20"
                                                onClick={() => props.onRefreshHandler()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faRedoAlt}
                                                    className="icon breadcrumbicon"
                                                />
                                                <span className="pagetitlebuttontext">Refresh</span>
                                            </div>
                                            : null
                                    }
                                    {
                                        props.addnew ?
                                            <div
                                                className="pagetitlethebutton waveseffect mr20"
                                                onClick={() => props.addNewHandler()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="icon breadcrumbicon"
                                                />
                                                <span className="pagetitlebuttontext">Add New</span>
                                            </div>
                                            : null
                                    }
                                    {
                                        props.uploadfile || props.downloadfile ?
                                            <div className={props.isMobile ? "pagetitledropdown" : "pagetitledropdown mr20"}>
                                                <div
                                                    className="pagetitlethebutton waveseffect"
                                                    onClick={() => props.showMenuHandler()}
                                                    onBlur={props.dontblur ? null : props.hideMenuHandler}
                                                    tabIndex={0}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCog}
                                                        className="icon breadcrumbicon"
                                                    />
                                                    <span className="pagetitlebuttontext">Settings</span>
                                                </div>
                                                <div
                                                    className={props.showsettingmenu ? "dropdownsettingmenu show" : "dropdownsettingmenu"}
                                                    onMouseEnter={props.onMouseEnter}
                                                    onMouseLeave={props.onMouseLeave}
                                                >
                                                    {
                                                        props.uploadfile ?
                                                            <div className="dropdownitem">Upload file</div>
                                                            : null
                                                    }
                                                    {
                                                        props.downloadfile ?
                                                            <div className="dropdownitem">Download Excel</div>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="pageNumber">{props.currentPage}/{parseInt((props.dataCount / props.pageSize) + ((props.dataCount % props.pageSize) > 0 ? 1 : 0))} Pages</div>
                            </div>
                            <div className="cardBody">
                                <div className="tableResponsiveSm">
                                    <table className="table" id={props.tablename}>
                                        <thead>
                                            <tr>
                                                {
                                                    props.tablemenu.map((item, index) => (
                                                        <th key={index}>{item.head}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                props.mydata
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav className="mt30">
                                    <Pagination Size={props.dataCount} pageLimit={props.pageSize} onPageChanged={props.pageChanged} currentPage={props.currentPage} width={props.isMobile} />
                                </nav>
                                {
                                    props.gobackbutton ?
                                        <div className="row">
                                            <div style={props.isMobile ? {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                position: 'absolute',
                                                bottom: '-50px',
                                                right: '-20px'
                                            } : {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'absolute',
                                                    bottom: '20px',
                                                    right: '0px'
                                                }}>
                                                <div
                                                    className="formbackButton formsubmitButtonShadow buttonColor"
                                                    style={{ padding: '0 12px 0 10px' }}
                                                    title=""
                                                    onClick={() => props.goBackToFront()}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faChevronLeft}
                                                        className="icon agraicon w18px"
                                                    /> Back
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        :
                        <div className="card withpagination">
                            <div className="cardTitle">
                                <div className="searchContainer">
                                    <div className="iconPosition">
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className="icon agraicon"
                                        />
                                    </div>
                                    <input
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="tableSearch"
                                        type="text"
                                        name="Search"
                                        placeholder="Search"
                                        title="Type in a name"
                                        value={props.filterText}
                                        onChange={(event) => props.handleChange(event)}
                                    />
                                </div>
                                <div className="pagetitlebutton">
                                    {
                                        props.refresh ?
                                            <div
                                                className="pagetitlethebutton waveseffect mr20"
                                                onClick={() => props.onRefreshHandler()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faRedoAlt}
                                                    className="icon breadcrumbicon"
                                                />
                                                <span className="pagetitlebuttontext">Refresh</span>
                                            </div>
                                            : null
                                    }
                                    {
                                        props.addnew ?
                                            <div
                                                className="pagetitlethebutton waveseffect mr20"
                                                onClick={() => props.addNewHandler()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="icon breadcrumbicon"
                                                />
                                                <span className="pagetitlebuttontext">Add New</span>
                                            </div>
                                            : null
                                    }
                                    {
                                        props.uploadfile || props.downloadfile ?
                                            <div className={props.isMobile ? "pagetitledropdown" : "pagetitledropdown mr20"}>
                                                <div
                                                    className="pagetitlethebutton waveseffect"
                                                    onClick={() => props.showMenuHandler()}
                                                    onBlur={props.dontblur ? null : props.hideMenuHandler}
                                                    tabIndex={0}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCog}
                                                        className="icon breadcrumbicon"
                                                    />
                                                    <span className="pagetitlebuttontext">Settings</span>
                                                </div>
                                                <div
                                                    className={props.showsettingmenu ? "dropdownsettingmenu show" : "dropdownsettingmenu"}
                                                    onMouseEnter={props.onMouseEnter}
                                                    onMouseLeave={props.onMouseLeave}
                                                >
                                                    {
                                                        props.uploadfile ?
                                                            <div className="dropdownitem">Upload file</div>
                                                            : null
                                                    }
                                                    {
                                                        props.downloadfile ?
                                                            <div className="dropdownitem">Download Excel</div>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="pageNumber">0/0 Pages</div>
                            </div>
                            <div className="cardBody">
                                <div className="tableResponsiveSm">
                                    <table className="table" id={props.tablename}>
                                        <thead>
                                            <tr>
                                                <th>NO DATA</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>NO DATA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav className="mt30">
                                    <Pagination Size={1} pageLimit={props.pageSize} onPageChanged={props.pageChanged} currentPage={1} width={props.isMobile} />
                                </nav>
                                {
                                    props.gobackbutton ?
                                        <div className="row">
                                            <div style={props.isMobile ? {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                position: 'absolute',
                                                bottom: '-50px',
                                                right: '-20px'
                                            } : {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'absolute',
                                                    bottom: '20px',
                                                    right: '0px'
                                                }}>
                                                <div
                                                    className="formbackButton formsubmitButtonShadow buttonColor"
                                                    style={{ padding: '0 12px 0 10px' }}
                                                    title=""
                                                    onClick={() => props.goBackToFront()}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faChevronLeft}
                                                        className="icon agraicon w18px"
                                                    /> Back
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
            }
            <div className={props.showedit ? "rightSideOption open" : "rightSideOption"}>
                {
                    props.showedit ?
                        <div className="actionTitle">
                            <div className="actionTitleText">Action options</div>
                        </div>
                        : null

                }
                {
                    props.showedit ?
                        <div className="rightSideButtonWrapper">
                            <div
                                className="rightSideButton mr20"
                                onClick={() => props.editDataHandler()}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    className="icon breadcrumbicon"
                                />
                                <span className="rightSideButtonText">Edit</span>
                            </div>
                            {/* <div
                                className="rightSideButton"
                                onClick={() => props.changePasswordHandler()}
                            >
                                <FontAwesomeIcon
                                    icon={faLockOpen}
                                    className="icon breadcrumbicon"
                                />
                                <span className="rightSideButtonText">Password</span>
                            </div> */}
                        </div>
                        : null
                }
                {/* {
                    props.showedit && props.userprops.userData.masteradmin > 0 ?
                        <div className="rightSideButtonWrapper">
                            <div
                                className="rightSideButton mr20"
                                onClick={() => props.editDataHandler()}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    className="icon breadcrumbicon"
                                />
                                <span className="rightSideButtonText">Edit</span>
                            </div>
                        </div>
                        : null
                } */}
            </div>
            {
                props.showedit &&
                <BackDrop click={props.backdropClickHandler} />
            }
        </div>
    );
};

export default TableMedia;