import axios from 'axios';
import {
    GET_EUNIT,
    GET_EUNIT_BY_ID,
    ADD_EUNIT,
    UPDATE_EUNIT,
    CLEAR_UPDATE_EUNIT,
    CLEAR_EUNIT
} from '../types';
import { EUNIT_SERVER } from '../misc';

export function getEUnit() {
    const request = axios.get(`${EUNIT_SERVER}/geteunit`)
        .then(response => response.data);

    return {
        type: GET_EUNIT,
        payload: request
    }
}

export function getEUnitByid(id){
    const request = axios.get(`${EUNIT_SERVER}/geteunitbyid?id=${id}&type=single`)
    .then(response => response.data);

    return {
        type: GET_EUNIT_BY_ID,
        payload: request
    }
}

export function addEUnit(dataToSubmit) {
    const request = axios.post(`${EUNIT_SERVER}/addeunit`, dataToSubmit)
        .then(response => response.data);

    return {
        type: ADD_EUNIT,
        payload: request
    }
}

export function clearEUnit() {
    return {
        type: CLEAR_EUNIT,
        payload: ''
    }
}

export function updateEUnit(dataToSubmit, id) {
    const request = axios.post(`${EUNIT_SERVER}/updateeunit?id=${id}`, dataToSubmit)
        .then(response => response.data);

    return {
        type: UPDATE_EUNIT,
        payload: request
    }
}

export function clearUpdateEUnit() {
    return {
        type: CLEAR_UPDATE_EUNIT,
        payload: ''
    }
}