import {
    GET_ECONVERTER,
    GET_ECONVERTER_BY_ID,
    ADD_ECONVERTER,
    UPDATE_ECONVERTER,
    CLEAR_UPDATE_ECONVERTER,
    CLEAR_ECONVERTER
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_ECONVERTER:
            return {
                ...state,
                getEConverter: action.payload
            }
        case GET_ECONVERTER_BY_ID:
            return {
                ...state,
                getEConverterByid: action.payload
            }
        case ADD_ECONVERTER:
            return {
                ...state,
                addEConverter: action.payload
            }
        case CLEAR_ECONVERTER:
            return {
                ...state,
                addEConverter: action.payload
            }
        case UPDATE_ECONVERTER:
            return {
                ...state,
                updateEConverter: action.payload
            }
        case CLEAR_UPDATE_ECONVERTER:
            return {
                ...state,
                updateEConverter: action.payload
            }
        default:
            return state;
    }
}