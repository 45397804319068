import React, { useState, useEffect, useCallback } from 'react';
import {
    useSelector
} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { pagelist, pagelinkto } from './link';
import DrawerToggle from './drawertoggle';

const HeaderSection = (props) => {
    const { clientprops, allwebsite } = useSelector(state => ({
        clientprops: state.client,
        allwebsite: state.website.getWebsite && state.website.getWebsite.websites
    }));
    const [headerShow, headerShowHandler] = useState(false);
    const scrollToElement = (element) => {
        scroller.scrollTo(element, {
            duration: 1000,
            delay: 100,
            offset: -90,
            smooth: true
        });
    }

    const userLink = (item, i) => (
        <li
            key={i}
        >
            <button
                onClick={() => scrollToElement(item.linkTo)}
            >
                <div className="itemContent">
                    <div className="itemInner">
                        <span className="title">
                            {item.name}
                        </span>
                    </div>
                </div>
            </button>
        </li>
    )

    const userLinkTo = (item, i) => (
        <li key={i}>
            <NavLink
                to={item.linkTo}
                activeClassName="active"
                exact
            >
                <div className="itemContent">
                    <div className="itemInner">
                        <span className="title">
                            {item.name}
                        </span>
                    </div>
                </div>
            </NavLink>
        </li>
    )

    const showLinks = (type) => {
        let list = [];
        type.forEach((item) => {
            if (item.public === true) {
                list.push(item)
            }
        })

        return list.map((item, i) => {
            return userLink(item, i)
        })
    }

    const showLinkTo = (type) => {
        let linkto = [];
        if (clientprops && clientprops.clientData) {
            type.forEach((item) => {
                if (!clientprops.clientData.isAuth) {
                    if (item.public === true) {
                        linkto.push(item)
                    }
                } else {
                    if (item.public === false) {
                        linkto.push(item)
                    }
                }
            })
        }

        return linkto.map((item, i) => {
            return userLinkTo(item, i)
        })
    }

    const handleScroll = useCallback(() => {
        let nowscrolling = window.scrollY;
        let isScrolling = nowscrolling > 10;
        if (isScrolling) {
            headerShowHandler(true)
        } else {
            headerShowHandler(false)
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll])

    return (
        <header className={headerShow ? "frontendwebsite topBar white" : "frontendwebsite topBar transparent"}>
            <div className="container">
                <div style={{
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div className="topBarLeft">
                        <div className={headerShow ? "toggleButton" : "toggleButton white"}>
                            <DrawerToggle click={props.drawerClickHandler} />
                        </div>
                        <div className="logo">
                            <a href="/">
                                <img
                                    className="logo_img mobileHidden"
                                    src={
                                        headerShow ?
                                            allwebsite && allwebsite[0].logo && allwebsite[0].logo.length > 0 ?
                                                allwebsite && allwebsite[0].logo[0].url
                                                : window.location.origin + "/assets/images/logo.png"
                                            : allwebsite && allwebsite[0].logo && allwebsite[0].logo.length > 0 ?
                                                allwebsite && allwebsite[0].logo[0].url
                                                : window.location.origin + "/assets/images/logo.png"
                                    }
                                    alt=""
                                    width="200"
                                />
                                <img
                                    className="logo_img pcHidden"
                                    src={
                                        headerShow ?
                                            allwebsite && allwebsite[0].logo && allwebsite[0].logo.length > 0 ?
                                                allwebsite && allwebsite[0].logo[0].url
                                                : window.location.origin + "/assets/images/logo.png"
                                            : allwebsite && allwebsite[0].logo && allwebsite[0].logo.length > 0 ?
                                                allwebsite && allwebsite[0].logo[0].url
                                                : window.location.origin + "/assets/images/logo.png"
                                    }
                                    alt=""
                                    width="200"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="topBarRight">
                        <div className="clear"></div>
                        <div className={headerShow ? "navigation_items" : "navigation_items white"}>
                            <ul>
                                <span className="mobileDisplayNone">{showLinks(pagelist)}</span>
                                <span className="mobileDisplayNone">{showLinkTo(pagelinkto)}</span>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );

}

export default HeaderSection;