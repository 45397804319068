import {
    GET_ESTOCKS,
    ADD_ESTOCKS,
    UPDATE_ESTOCKS,
    CLEAR_UPDATE_ESTOCKS,
    CLEAR_ESTOCKS
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_ESTOCKS:
            return {
                ...state,
                getEStocks: action.payload
            }
        case ADD_ESTOCKS:
            return {
                ...state,
                addEStocks: action.payload
            }
        case CLEAR_ESTOCKS:
            return {
                ...state,
                addEStocks: action.payload
            }
        case UPDATE_ESTOCKS:
            return {
                ...state,
                updateEStocks: action.payload
            }
        case CLEAR_UPDATE_ESTOCKS:
            return {
                ...state,
                updateEStocks: action.payload
            }
        default:
            return state;
    }
}