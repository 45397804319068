import React from 'react';
import {
    FaHome,
    FaListUl,
    FaPhone,
    FaLock, FaBuilding
} from 'react-icons/fa';

export let pagelist = [
    {
        name: 'Home',
        linkTo: 'home',
        public: true,
        faicons: <FaHome />
    },
    {
        name: 'About',
        linkTo: 'about',
        public: true,
        faicons: <FaListUl />
    },
    {
        name: 'Property',
        linkTo: 'property',
        public: true,
        faicons: <FaBuilding />
    },
    {
        name: 'Contact',
        linkTo: 'contact',
        public: true,
        faicons: <FaPhone />
    }
]

export let propertylist = [
    {
        name: 'Home',
        linkTo: '/',
        public: true,
        faicons: <FaHome />
    },
    {
        name: 'Taman Mulya Angkasa',
        linkTo: '/property/5f7ee30e7de6ad15d0774d90',
        public: true,
        faicons: <FaHome />
    },
    {
        name: 'Pesona Lebak Wangi 2',
        linkTo: '/property/5f7ee1417de6ad15d0774d8e',
        public: true,
        faicons: <FaHome />
    },
    {
        name: 'Rajeg Hill Residence',
        linkTo: '/property/5f7ed0b7eec97232a47ed55a',
        public: true,
        faicons: <FaHome />
    }
]

export let pagelinkto = [
    {
        name: 'Admin',
        linkTo: '/auth',
        public: true,
        faicons: <FaLock />
    }
]