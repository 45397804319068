import {
    PORTOPEN
} from '../types';

const initialState = {
    portopen: false
}

export default function LoadingState(state = initialState, action) {
    switch (action.type) {
        case PORTOPEN:
            return {
                portopen: action.status
            }
        default:
            return state;
    }
}