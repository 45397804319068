import {
    GET_CATEGORY,
    GET_CATEGORY_BY_ID,
    ADD_CATEGORY,
    CLEAR_CATEGORY,
    UPDATE_CATEGORY,
    CLEAR_UPDATE_CATEGORY
} from '../types';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_CATEGORY:
            return {
                ...state,
                getCategory: action.payload
            }
        case GET_CATEGORY_BY_ID:
            return {
                ...state,
                getCategoryByid: action.payload
            }
        case ADD_CATEGORY:
            return {
                ...state,
                addCategory: action.payload
            }
        case CLEAR_CATEGORY:
            return {
                ...state,
                addCategory: action.payload
            }
        case UPDATE_CATEGORY:
            return {
                ...state,
                updateCategory: action.payload
            }
        case CLEAR_UPDATE_CATEGORY:
            return {
                ...state,
                updateCategory: action.payload
            }
        default:
            return state;
    }
}